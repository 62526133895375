import React, { useState, useRef, useEffect } from "react";
import api from "../Api.js";
import CustomTextField from "../control/CustomTextField.js";
import { Grid, Modal, Button } from "@material-ui/core";
import { Select, MenuItem } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ImageUploader from "../control/ImageUploader.js";
import CrudController from "../control/CrudController";
import CustomMessageBox from "../control/CustomMessageBox";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import dayjs from "dayjs";
import { capitalizeWords } from "../utils.js";
import Card from "../print/Card.jsx"



function Register() {
  //#region Variable
  const [id, setId] = useState(null);

  const [clinicNo, setClinicNo] = useState("");
  const [valueTitle, setValueTitle] = useState("");
  const [valueAge, setValueAge] = useState("");
  const [valueGender, setValueGender] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [contactno, setContactNo] = useState("");
  const [nic, setNic] = useState("");
  const [picture, setPicture] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [birthday, setBirthDay] = useState(null);
  const imageUploaderRef = useRef(null);
  const [databaseImage, setDatabaseImage] = useState(null);

  const [functionality, setFunctionality] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertDescription, setAlertDescription] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [isMessageBoxOpen, setMessageBoxOpen] = useState(false);
  const years = Array.from({ length: 120 }, (_, index) => index + 1); // Create an array from 1 to 120
  const [buttonStates] = useState({
    search: true,
    edit: true,
    save: true,
    clear: true,
    close: false,
    history: false,
    scan:true,
    print:true,
  });
  const [clearValues, setClearValues] = useState(false);

  const [showGeneratedNumberModal, setShowGeneratedNumberModal] =
    useState(false);
  const [generatedNumber, setGeneratedNumber] = useState("");

  const [, setCreateDt] = useState("");
  const [createBy, setCreateBy] = useState("");
  const [, setUpdateDt] = useState("");
  const [, setUpdateBy] = useState("");
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);

  //#endregion Variable

  //#region Main Function

  const clear = async (isMsgShow) => {
    if (isMsgShow === true) {
      setClinicNo("");
      setId(null);
      nextClinicNo();
    }
    setAddress1("");
    setAddress2("");
    setAddress3("");
    setBirthDay(null);
    setCity("");
    setContactNo("");
    setFirstName("");
    setLastName("");
    setMiddleName("");
    setNic("");
    setPostalCode("");
    setValueAge("");
    setValueGender("");
    setValueTitle("");
    setPicture(null);
    setImageData(null);
    setDatabaseImage(null);
    clearImageInUploader();
    if (isMsgShow === true) handleClearValues();
    if (isMsgShow === true)
      triggerAlertOn("Clear", "Successfully cleaned!", "success");
  };

  const isNicAlreadyAvailable = async(nic) => {
    var response;
    const nextval = 1;
    response= await api.get(`/patient/byonlynic/${nic}/${nextval}`);
    const dbData = response.data.data;
    if(dbData==="true") {
      triggerAlertOn("Save", "The provided NIC is already associated with another patient in the system.", "error");
      return true;
    }
    return false;
  }

  const isValidClinicNoOverId = async(clinicno,id) => {
    var response;
    console.log('clinicno ', clinicno);
    console.log('id ', id);
    response= await api.get(`/patient/checkclinicnooverid/${id}/${clinicno}`);
    const isValid = response.data.data;
    return isValid;
  }

  const save = async () => {
    try {
      //console.log('nic ' , nic);
      if((nic!=null && nic!=='' &&  nic!==' '))
      { 
        if (await isNicAlreadyAvailable(nic))   return;
      }

      let imageUrl = undefined;
      if (imageData != null) {
        const fileExtenstion = extractImageExtension(imageData);
        imageUrl = await uploadImageToServer(
          imageData,
          fileExtenstion,
          clinicNo
        );
      }

      const response = await api.post(`/patient/save`, {
        clinic_no: clinicNo,
        reg_date: new Date(),
        title: valueTitle,
        f_name: firstName,
        m_name: middleName,
        l_name: lastName,
        age: valueAge,
        gender: valueGender,
        bday: birthday ? birthday.format(process.env.REACT_APP_DATE_FORMAT) : null,
        address_1: address1,
        address_2: address2,
        address_3: address3,
        city: city,
        postal_code: postalCode,
        contact_no: contactno,
        nic: nic === "" ? undefined : nic,
        profile_pic: imageUrl,
        com_code: sessionStorage.getItem("usercom"),
        create_by: createBy,
        create_dt: new Date(),
      });

      if (response.data.success) {
        const generatedNumberFromAPI = response.data.data.clinic_no; // Assuming clinic_no is the value you want
        setGeneratedNumber(generatedNumberFromAPI);
        setShowGeneratedNumberModal(true);
        clear(true);
        triggerAlertOn("Save", "Successfully saved!", "success");
      } else {
        triggerAlertOn(
          "Save",
          `There is an error: ${response.data.message}`,
          "error"
        );
      }
    } catch (error) {
      console.error(error);
      triggerAlertOn(
        "Save",
        `There is an error occurred while saving the record. The error is ${error}`,
        "error"
      );
    }
  };

  const edit = async () => {
    try {

      if (id!==undefined && id!=null && !isValidClinicNoOverId(clinicNo,id)) {
        triggerAlertOn(
          "Edit",
          `There is a mismatch with the given clinic no with the system generated id. Please contact administrator.`,
          "error"
        );
        return;
     }
     
      let imageUrl = null;
      //console.log("sessionStorage.getItem('userid') " + sessionStorage.getItem('userid'));

      if (imageData == null && picture == null) {
        imageUrl = null;
      } else if (
        imageData != null &&
        !imageData.includes(process.env.REACT_APP_PATIENT_PROFILE)
      ) {
        const fileExtenstion = extractImageExtension(imageData);
        imageUrl = await uploadImageToServer(
          imageData,
          fileExtenstion,
          clinicNo
        );
      } else if (imageData.includes(process.env.REACT_APP_PATIENT_PROFILE)) {
        imageUrl = databaseImage;
      }
      //console.log("Edit");
      api
        .put(`/patient/edit`, {
          id: id,
          clinic_no: clinicNo,
          title: valueTitle,
          f_name: firstName,
          m_name: middleName,
          l_name: lastName,
          age: valueAge,
          gender: valueGender,
          bday: birthday ? birthday.format(process.env.REACT_APP_DATE_FORMAT) : null,
          address_1: address1,
          address_2: address2,
          address_3: address3,
          city: city,
          postal_code: postalCode,
          contact_no: contactno,
          nic: nic === "" ? undefined : nic,
          profile_pic: imageUrl == null ? undefined : imageUrl,
          update_dt: new Date(),
          update_by: sessionStorage.getItem("userid"),
        })
        .then(() => {
          clear(true);
          triggerAlertOn("Edit", "Successfully edited!", "success");
        })
        .catch((error) => {
          console.error(error);
          triggerAlertOn(
            "Edit",
            "There is an error occured while edit the record. The error is " +
              error,
            "error"
          );
        });
    } catch (error) {
      console.error(error);
      triggerAlertOn(
        "Edit",
        "There is an error occured while edit the record. The error is " +
          error,
        "error"
      );
    }
  };

  const handleSave = () => {
    if (id != null) {
      triggerAlertOn(
        "Save",
        "Process terminated. You cannot save this again, try edit! ",
        "error"
      );
      return;
    }

    if (validatePage()){ handleButtonClick("Save");}
    else {
      if (firstName==null || firstName==='' ) {
        triggerAlertOn(
          "Save",
          "Process terminated. Please enter first name. ",
          "error"
        );
        return;
      }

      if (lastName==null  || lastName==='') {
        triggerAlertOn(
          "Save",
          "Process terminated. Please enter last name. ",
          "error"
        );
        return;
      }

      if (valueTitle==null || valueTitle==='') {
        triggerAlertOn(
          "Save",
          "Process terminated. Please enter title. ",
          "error"
        );
        return;
      } 
      
      if (valueGender==null  || valueGender==='' ) {
        triggerAlertOn(
          "Save",
          "Process terminated. Please enter gender. ",
          "error"
        );
        return;
      }       

      if (valueAge==null || valueAge==='' ) {
        triggerAlertOn(
          "Save",
          "Process terminated. Please enter age. ",
          "error"
        );
        return;
      }      

      if (birthday==null || birthday==='' ) {
        triggerAlertOn(
          "Save",
          "Process terminated. Please enter birthday. ",
          "error"
        );
        return;
      }      
      
      if (contactno==null  || contactno==='' ) {
        triggerAlertOn(
          "Save",
          "Process terminated. Please enter contact no. ",
          "error"
        );
        return;
      }      

      if (city==null  || city==='' ) {
        triggerAlertOn(
          "Save",
          "Process terminated. Please enter city. ",
          "error"
        );
        return;
      }

      if ((nic==null  || nic==='') && parseInt(valueAge) > 18 ) {
        triggerAlertOn(
          "Save",
          "Process terminated. Please enter the NIC for someone older than 18.",
          "error"
        );
        return;
      }
   
    }
  };

  const handleClear = (isNoMsg) => {
    if (isNoMsg === true) {
      clear(false);
    } else {
      handleButtonClick("Clear");
    }
  };

  const handleEdit = () => {
    if (id === null) {
      triggerAlertOn(
        "Edit",
        "Process terminated. This is a new record!, click the save button",
        "error"
      );
      return;
    }
    handleButtonClick("Edit");
  };

  const handleSearch = async () => {
    try {
      const nextval = 1;
      var response;
      response = await api.get(`/patient/byclinicno/${clinicNo}/${nextval}`);
      const data = response.data.data;
      // eslint-disable-next-line eqeqeq
      const clinicData = data.find((item) => item.clinic_no == clinicNo);
      if (clinicData) {
        setId(clinicData.id);
        setFirstName(clinicData.f_name);
        setMiddleName(clinicData.m_name);
        setLastName(clinicData.l_name);
        setValueTitle(clinicData.title);
        setValueGender(clinicData.gender);
        setValueAge(clinicData.age);
        setBirthDay(dayjs(clinicData.bday));
        setContactNo(clinicData.contact_no);
        setAddress1(clinicData.address_1);
        setAddress2(clinicData.address_2);
        setAddress3(clinicData.address_3);
        setCity(clinicData.city);
        setPostalCode(clinicData.postal_code);
        clinicData.nic == null ? setNic(null) : setNic(clinicData.nic);
        setPicture(null);
        clinicData.profile_pic != null
          ? setDatabaseImage(clinicData.profile_pic)
          : setDatabaseImage(null);
        clinicData.profile_pic != null
          ? setDBImager(
              process.env.REACT_APP_PATIENT_PROFILE +
                "/" +
                clinicData.profile_pic.replace("\\", "/")
            )
          : setDBImager(null);
      } else {
        //triggerAlertOn("Search", `There is no data to be found.`, "warning");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    // Handle search logic
  };

  const handleHistory = () => {
    // Handle search logic
  };

  const uploadImageToServer = async (image, fileType, folderName) => {
    try {
      const formData = new FormData();
      formData.append("folderName", folderName);
      formData.append(
        "image",
        dataURItoBlob(image, fileType),
        `image.${fileType}`
      );

      const response = await api.post(`/profileimages`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        const result = response.data;
        //console.log("Image uploaded successfully. Image URL:", result.imageUrl);
        return result.imageUrl;
      } else {
        console.error("Failed to upload image:", response.statusText);
        // Handle the error, throw an exception, or return an appropriate value
      }
    } catch (error) {
      console.error("Error uploading image:", error.message);
      // Handle the error, throw an exception, or return an appropriate value
    }
  };

  // Convert data URI to Blob
  function dataURItoBlob(dataURI, imageType) {
    const mimeType = `image/${imageType}`;
    const byteString = atob(dataURI.split(",")[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeType });
  }

  const validatePage = () => {
    // Check required fields
    if (
      !firstName ||
      !lastName ||
      !valueTitle ||
      !valueGender ||
      !valueAge ||
      !birthday ||
      !contactno ||
      !city
    ) {
      return false;
    }

    // Check additional conditions
    if (parseInt(valueAge) > 18 && !nic) {
      return false;
    }

    // All checks passed, return true
    return true;
  };

  const closeModal = () => {
    setShowGeneratedNumberModal(false);
  };

  const handleBirthDay = (date) => {
    setBirthDay(dayjs(date));
  };

  const handleClinicNoChange = (newClinicNo) => {
    setClinicNo(newClinicNo);
  };

  //#endregion Main Function

  //#region Image Related
  const extractImageExtension = (image) => {
    if (image == null) return "jpg";
    const firstSplit = image.split(";");
    const secondSplit = firstSplit[0].split(":");
    const thirdSplit = secondSplit[1].split("/");
    const result = thirdSplit[1];
    return result;
  };

  const clearImageInUploader = () => {
    // Call the clearImage function in ImageUploader
    if (imageUploaderRef.current) {
      imageUploaderRef.current.clearImage();
    }
  };

  const setDBImager = (image) => {
    if (imageUploaderRef.current) {
      imageUploaderRef.current.setDbImage(image);
    }
  };

  const handleImageChange = (image) => {
    setImageData(image);
  };

  const nextClinicNo = async () => {
    //console.log('nextClinicNo : ');

    try {
      var response;
      //console.log('com code ' + sessionStorage.getItem('usercom'));
      response = await api.get(
        `/patient/nextclinicno/${sessionStorage.getItem("usercom")}`
      );
      setClinicNo(response.data.clinic_no);
    } catch (error) {
      console.error(error);
    }
  };

  //#endregion Image Related

  //#region outside controller compartments

  const handleButtonClick = (buttonId) => {
    if (buttonId === "Save") {
      setMessage("Do you really want to save this Patient?");
    } else if (buttonId === "Edit") {
      setMessage("Do you really want to edit this Patient?");
    } else if (buttonId === "Clear") {
      setMessage("Do you really want to clear this Patient?");
    }
    setFunctionality(buttonId);
    setMessageBoxOpen(true);
  };

  const handleMessageBoxClose = (isPrimaryButtonClicked) => {
    if (isPrimaryButtonClicked && functionality === "Save") {
      save();
    }
    if (isPrimaryButtonClicked && functionality === "Clear") {
      clear(true);
    }

    if (isPrimaryButtonClicked && functionality === "Edit") {
      edit();
    }

    // Set isMessageBoxOpen to false to hide the message box
    setMessageBoxOpen(false);
  };

  const triggerAlertOn = async (title, description, severity) => {
    setAlertTitle(title);
    setAlertDescription(description);
    setAlertSeverity(severity);
    setShowAlert(true);

    if (severity !== "error") {
      setTimeout(() => {
        closeAlert();
      }, 2000); // auto-close after 2 seconds for non-error alerts
    }
  };

  const closeAlert = () => {
    setAlertTitle("");
    setAlertDescription("");
    setAlertSeverity("");
    setShowAlert(false);
  };

  useEffect(() => {
    setCreateDt(new Date());
    setCreateBy(sessionStorage.getItem("userid"));
    setUpdateDt(new Date());
    setUpdateBy(sessionStorage.getItem("userid"));
    nextClinicNo();
  }, []);

  const handleClearValues = () => {
    // Set clearValues to true to trigger the clearing in CrudController
    setClearValues(true);
  };

  // Callback function to reset clearValues in CrudController
  const handleClearComplete = () => {
    setClearValues(false);
  };

  //#endregion outside controller compartments

  const handleDataFetchStatus = (status) => {
    if (status === false) {
      //triggerAlertOn("Search", `This is an invalid clinic no. Please check the number and try again.`, "error"); setClinicNo("");
    }
  };

  const onAdvanceSearchSelection =(clinicno) =>{
    setClinicNo(clinicno);
    handleSearch();
  }

  const handlePrint = () => {
    setIsPrintModalOpen(true)
   };


  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        marginTop: "16px",
        marginLeft: "16px",
      }}
    >
      {" "}
      <Grid container spacing={2}>
        {/* 1 Row */}
        <CrudController
          onSave={handleSave}
          onClear={handleClear}
          onEdit={handleEdit}
          onSearch={handleSearch}
          onClose={handleClose}
          onHistory={handleHistory}
          clinicNo={clinicNo}
          onClinicNoChange={handleClinicNoChange}
          buttonStates={buttonStates}
          isNameVisible={false}
          clearValues={clearValues}
          onClearComplete={handleClearComplete}
          onDataFetchStatus={handleDataFetchStatus}
          isDoSearch={true}
          isClinicNoVisible={true}
          onRowDoubleClick={onAdvanceSearchSelection}
          isQRVisibale={true}
          onPrint={handlePrint}
        />

        {/* 2 Row */}
        <Grid container spacing={2}>
          {/* First Column */}
          <Grid item xs={4}>
            <Paper elevation={0} style={{ padding: 5, textAlign: "left" }}>
              <CustomTextField
                label="First Name"
                type="text"
                id="firstname"
                width="500px"
                value={firstName}
                onChange={(e) => setFirstName(capitalizeWords(e.target.value))}
              />
            </Paper>

            <Paper elevation={0} style={{ padding: 5, textAlign: "left" }}>
              <CustomTextField
                label="Middle Name"
                type="text"
                id="middlename"
                width="500px"
                value={middleName}
                onChange={(e) => setMiddleName(capitalizeWords(e.target.value))}
              />
            </Paper>

            <Paper elevation={0} style={{ padding: 5, textAlign: "left" }}>
              <CustomTextField
                label="Last Name"
                type="text"
                id="lastname"
                width="500px"
                value={lastName}
                onChange={(e) => setLastName(capitalizeWords(e.target.value))}
              />
            </Paper>

            <Paper elevation={0} style={{ padding: 10, textAlign: "left" }}>
              <Grid container spacing={10}>
                {/* cell 1 */}
                <Grid item xs={4}>
                  <Paper
                    elevation={0}
                    style={{ padding: 5, textAlign: "left" }}
                  >
                    <InputLabel id="title-label">Title</InputLabel>
                    <Select
                      value={valueTitle}
                      onChange={(e) => setValueTitle(e.target.value)}
                      labelId="title-label"
                      displayEmpty
                      style={{ width: "110px" }}
                    >
                      <MenuItem value="" disabled>
                        {" "}
                        --select--{" "}
                      </MenuItem>
                      <MenuItem value={"Mr."}>Mr.</MenuItem>
                      <MenuItem value={"Mrs."}>Mrs.</MenuItem>
                      <MenuItem value={"Ms."}>Ms.</MenuItem>
                      <MenuItem value={"Master."}>Master.</MenuItem>
                      <MenuItem value={"Rev."}>Rev.</MenuItem>
                      <MenuItem value={"professor."}>Professor.</MenuItem>
                      <MenuItem value={"Doctor."}>Doctor.</MenuItem>
                      <MenuItem value={"NotSpecify."}>NotSpecify.</MenuItem>
                    </Select>
                  </Paper>
                </Grid>
                {/* cell 2 */}
                <Grid item xs={4}>
                  <Paper
                    elevation={0}
                    style={{ padding: 5, textAlign: "left", paddingLeft: 18 }}
                  >
                    <InputLabel id="gender-label">Gender</InputLabel>
                    <Select
                      value={valueGender}
                      onChange={(e) => setValueGender(e.target.value)}
                      displayEmpty
                      labelId="gender-label"
                      style={{ width: "125px" }}
                    >
                      <MenuItem value="" disabled>
                        {" "}
                        --select--{" "}
                      </MenuItem>
                      <MenuItem value={"Male"}>Male</MenuItem>
                      <MenuItem value={"Female"}>Female</MenuItem>
                      <MenuItem value={"Lesbian"}>Lesbian</MenuItem>
                      <MenuItem value={"Gay"}>Gay</MenuItem>
                      <MenuItem value={"Trans Gender"}>Trans Gender</MenuItem>
                      <MenuItem value={"Bi Sexual"}>Bi Sexual</MenuItem>
                      <MenuItem value={"Multi Gender"}>Multi Gender</MenuItem>
                      <MenuItem value={"Not specify"}>Not specify</MenuItem>
                    </Select>
                  </Paper>
                </Grid>
                {/* cell 3 */}
                <Grid item xs={4}>
                  <Paper
                    elevation={0}
                    style={{ padding: 5, textAlign: "left", paddingLeft: 44 }}
                  >
                    <InputLabel id="age-label">Age</InputLabel>
                    <Select
                      value={valueAge}
                      displayEmpty
                      labelId="age-label"
                      onChange={(e) => setValueAge(e.target.value)}
                      style={{ width: "120px" }}
                    >
                      <MenuItem value="" disabled>
                        {" "}
                        --select--{" "}
                      </MenuItem>
                      {years.map((year) => (
                        <MenuItem
                          key={`${year} Year${year > 1 ? "s" : ""}`}
                          value={`${year} Year${year > 1 ? "s" : ""}`}
                        >{`${year} Year${year > 1 ? "s" : ""}`}</MenuItem>
                      ))}
                    </Select>
                  </Paper>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/* Second Column */}
          <Grid item xs={8}>
            <Paper elevation={0} style={{ textAlign: "center" }}>
              <ImageUploader
                ref={imageUploaderRef}
                id="profileImage"
                onImageChange={handleImageChange}
              />
            </Paper>
          </Grid>
        </Grid>

        {/* 3 Row */}
        <Grid container spacing={0}>
          {/* cell 1 */}
          <Grid item xs={3}>
            <Paper elevation={0} style={{paddingLeft:8, paddingBottom:5,  textAlign: "left" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
                <DatePicker
                  label="Birthday"
                  format={process.env.REACT_APP_DATE_FORMAT}
                  value={birthday}
                  onChange={(date) => handleBirthDay(date)}
                  id="birthday"
                />
              </LocalizationProvider>
            </Paper>
          </Grid>
          {/* cell 2 */}
          <Grid item xs={3}>
            <Paper elevation={0} style={{ paddingLeft:10,  textAlign: "left" }}>
              <CustomTextField
                label="Contact No"
                type="text"
                id="contactno"
                width="250px"
                value={contactno}
                onChange={(e) => setContactNo(e.target.value)}
              />
            </Paper>
          </Grid>
          {/* cell 3 */}
          <Grid item xs={3}>
            <Paper elevation={0} style={{ paddingLeft:32, textAlign: "left" }}>
              <CustomTextField
                label="NIC"
                type="text"
                id="nic"
                width="250px"
                value={nic}
                onChange={(e) => setNic(e.target.value)}
              />
            </Paper>
          </Grid>
        </Grid>

        {/* 4 Row */}
        <Grid item xs={12}>
          <Paper elevation={0} style={{ padding: 0, textAlign: "left" }}>
            <CustomTextField
              label="Address 1"
              type="text"
              id="address1"
              width="774px"
              value={address1}
              onChange={(e) => setAddress1(capitalizeWords(e.target.value))}
            />
          </Paper>
        </Grid>

        {/* 5 Row */}
        <Grid item xs={12}>
          <Paper elevation={0} style={{ padding: 0, textAlign: "left" }}>
            <CustomTextField
              label="Address 2"
              type="text"
              id="address2"
              width="774px"
              value={address2}
              onChange={(e) => setAddress2(capitalizeWords(e.target.value))}
            />
          </Paper>
        </Grid>

        {/* 6 Row */}
        <Grid item xs={12}>
          <Paper elevation={0} style={{ padding: 0, textAlign: "left" }}>
            <CustomTextField
              label="Address 3"
              type="text"
              id="address3"
              width="774px"
              value={address3}
              onChange={(e) => setAddress3(capitalizeWords(e.target.value))}
            />
          </Paper>
        </Grid>
        {/* 7 Row */}
        <Grid container >
          {/* cell 1 */}
          <Grid item xs={3}>
            <Paper elevation={0} style={{ paddingLeft: 8,paddingTop:5, textAlign: "left" }}>
              <CustomTextField
                label="City"
                type="text"
                id="city"
                width="220px"
                value={city}
                onChange={(e) => setCity(capitalizeWords(e.target.value))}
              />
            </Paper>
          </Grid>

          {/* cell 2 */}
          <Grid item xs={3}>
            <Paper elevation={0} style={{  paddingTop:5, textAlign: "left" }}>
              <CustomTextField
                label="Postal Code"
                type="text"
                id="postalcode"
                width="200px"
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
              />
            </Paper>
          </Grid>
        </Grid>

      </Grid>
      <CustomMessageBox
        open={isMessageBoxOpen}
        onClose={handleMessageBoxClose}
        title="Patient Registration"
        message={message}
        primaryButtonCaption="OK"
        secondaryButtonCaption="Cancel"
        showPrimaryButton
        showSecondaryButton
      />
      {showAlert && (
        <div
          style={{
            position: "fixed",
            top: "155px",
            left: "610px",
            width: 890,
            height: 150,
            transform: "translate(-50%, -50%)",
            zIndex: 1000,
            cursor: alertSeverity === "error" ? "pointer" : "auto", // Show pointer cursor for error alerts
          }}
            onClick={alertSeverity === "error" ? closeAlert : undefined}           
        >
          <Alert severity={alertSeverity} onClose={alertSeverity === "error" ? closeAlert : undefined}>
            <AlertTitle style={{ fontSize: "20px" }}>{alertTitle}</AlertTitle>
            <strong style={{ fontSize: "20px" }}>{alertDescription}</strong>
          </Alert>
        </div>
      )}

      {/* Modal to display generated number */}
      <Modal
        open={showGeneratedNumberModal}
        onClose={closeModal}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "rgb(256, 256, 200)", // Light gray shade
            border: "2px solid #000",
            borderRadius: "8px",
            padding: "20px",
            textAlign: "center",
            boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.75)", // Shadow
            transform: "translateZ(10px)", // Depth effect
          }}
        >
          <h2>Patient's Clinical Number</h2>
          <h1 style={{ fontSize: "100px", color: "red" }}>{generatedNumber}</h1>
          <Button variant="contained" color="primary" onClick={closeModal}>
            Close
          </Button>
        </div>
      </Modal>

      <Modal open={isPrintModalOpen} onClose={()=>setIsPrintModalOpen(false)}>
          <div>
              <button onClick={()=>setIsPrintModalOpen(false)}>Close</button>
              <Card />
          </div>
      </Modal>
    </div>
  );
}

export default Register;
