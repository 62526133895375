import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Avatar,
  makeStyles,
  Modal,
} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import UserProfile from "../user/UserPassWordReset";
import logout from "../login/Logout";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  column: {
    height: "100%",
    padding: theme.spacing(1),
  },
  expandableColumn: {
    minWidth: "150px",
  },
  loginContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  loginInput: {
    marginBottom: theme.spacing(2),
  },
  toggleButton: {
    width: "100%",
  },
  fab: {
    position: "fixed",
    top: theme.spacing(9),
    left: theme.spacing(6),
  },
  userDetails: {
    display: "flex",
    alignItems: "center", // Align items vertically
    marginRight: theme.spacing(2),
  },
  profileIcon: {
    display: "flex",
    alignItems: "center",
  },
}));

function MasterTopBar(props) {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [isUserProfileOpen, setUserProfileOpen] = useState(false);

  const handleMenuOpen = (event) => {
    setMenuOpen(true);
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
    setUserProfileOpen(true);
  };

  const handleUserProfileClose = () => {
    setUserProfileOpen(false);
  };

  const handleLogout = () => {
    logout().then(() => {
      window.location.href = "/"; // Redirect to login page
    });
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed">
        <Toolbar>
          <Typography variant="h6" className={classes.title} >
            ORO Data+ Salted Web Edition &nbsp; <sub style={{ fontSize: '0.55em' }}>(Version 6.0.0.0)</sub>
          </Typography>
          {props.isAuthenticated && (
            <div className={classes.userDetails}>
              <Typography variant="body1">
                Welcome, {props.userName}!
              </Typography>
              <div className={classes.profileIcon}>
                <IconButton
                  color="inherit"
                  onClick={handleMenuOpen}
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                >
                  <Avatar src={sessionStorage.getItem("profilepic")}>
                    <AccountCircle />
                  </Avatar>
                  <ArrowDropDown />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={menuAnchor}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={menuOpen}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={handleMenuClose}>Change Password</MenuItem>
                </Menu>
              </div>
            </div>
          )}
          <Button color="inherit" onClick={(e) => handleLogout()}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>

      {/* UserProfile rendered as a modal */}
      <Modal
        open={isUserProfileOpen}
        onClose={handleUserProfileClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "550px",
            padding: "20px",
            backgroundColor: "white",
          }}
        >
          <UserProfile onClose={handleUserProfileClose} />
        </div>
      </Modal>
    </div>
  );
}

export default MasterTopBar;
