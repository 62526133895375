import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import FBQuadrant from "../../control/FourButtonQuadrant";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { pink } from "@mui/material/colors";
import CustomCheckBoxTextField from "../../control/CustomCheckBoxTextField";
import Tooth from "../../control/Tooth.js";
import AdvanceTooth from "../../control/AdvanceTooth.js";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CrudController from "../../control/CrudController";
import CustomMessageBox from "../../control/CustomMessageBox";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import api from "../../Api.js";

//#region Styling Section
const useStyles = makeStyles(() => ({
  advtooth: {
    position: "relative",
    marginTop: "1px",
    display: "flex",
    flexDirection: "row",
  },
}));
//#endregion Styling Section

function Complain() {
  //#region Variable Section

  const [resetKey, setResetKey] = useState(0);
  // State to control clearing values in CrudController
  const [clearValues, setClearValues] = useState(false);

  const classes = useStyles();
  const [id, setId] = useState(null);
  const [clinicNo, setClinicNo] = useState("");
  const [buttonStates] = useState({
    search: true,
    edit: true,
    save: true,
    clear: true,
    close: false,
    history: false,
  });
  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertDescription, setAlertDescription] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [isMessageBoxOpen, setMessageBoxOpen] = useState(false);
  const [functionality, setFunctionality] = useState(null);

  const [quadrantPain, setQuadrantPain] = useState("0000");
  const [quadrantCracked, setQuadrantCracked] = useState("0000");
  const [quadrantDiscolored, setQuadrantDiscolored] = useState("0000");
  const [needCleaning, setNeedCleaning] = useState(false);
  const [quadrantSwelling, setQuadrantSwelling] = useState("0000");
  const [quadrantGumBleeding, setQuadrantGumBleeding] = useState("0000");
  const [malformedTeeth, setMalformedTeeth] = useState(false);
  const [artificialTeeth, setArtificialTeeth] = useState(false);
  const [quadrantShakingTeeth, setQuadrantShakingTeeth] = useState("0000");
  const [quadrantAssault, setQuadrantAssault] = useState("0000");
  const [jutting, setJutting] = useState(false);
  const [existingArtificial, setExistingArtificial] = useState(false);
  const [quadrantDecayedTooth, setQuadrantDecayedTooth] = useState("0000");
  const [quadrantDamageAccident, setQuadrantDamageAccident] = useState("0000");
  const [smell, setSmell] = useState(false);
  const [nonHealing, setNonHealing] = useState(false);
  const [complainOther, setComplainOther] = useState(null);

  const [medicNothingSignificant, setMedicNothingSignificant] = useState(false);
  const [medicBleedingDisorders, setMedicBleedingDisorders] = useState(false);
  const [medicAnesthetic, setMedicAnesthetic] = useState(false);
  const [medicAsthma, setMedicAsthma] = useState(false);
  const [medicCardiac, setMedicCardiac] = useState(false);
  const [medicDiabetes, setMedicDiabetes] = useState(false);
  const [medicEndocrine, setMedicEndocrine] = useState(false);
  const [medicEpilepsy, setMedicEpilepsy] = useState(false);
  const [medicKidney, setMedicKidney] = useState(false);
  const [medicHypertension, setMedicHypertension] = useState(false);
  const [medicHepatitis, setMedicHepatitis] = useState(false);
  const [medicSkeletal, setMedicSkeletal] = useState(false);
  const [medicRheumatic, setMedicRheumatic] = useState(false);
  const [medicGastric, setMedicGastric] = useState(false);
  const [medicGastricGenaral, setMedicGastricGenaral] = useState(false);
  const [medicGastricDrug, setMedicGastricDrug] = useState(false);
  const [medicAllergies, setMedicAllergies] = useState(null);
  const [medicHistoryOfSx, setMedicHistoryOfSx] = useState(null);
  const [medicRegularDrugs, setMedicRegularDrugs] = useState(null);
  const [medicOther, setMedicOther] = useState(null);

  const [dentNothingSignificant, setDentNothingSignificant] = useState(false);
  const [dentBleeding, setDentBleeding] = useState(false);
  const [dentFailedFillings, setDentFailedFillings] = useState(false);
  const [dentFailedRCT, setDentFailedRCT] = useState(false);
  const [dentPainAfterFilling, setDentPainAfterFilling] = useState(false);
  const [dentFailedProsthesis, setDentFailedProsthesis] = useState(false);
  const [dentFailedOrthoRx, setDentFailedOrthoRx] = useState(false);
  const [dentExtraction, setDentExtraction] = useState(false);
  const [dentOther, setDentOther] = useState(null);

  const examTeethInitCommon = process.env.REACT_APP_NORMAL_TOOTH_SETTING;
  const examAdvaceTeethInitCommon = process.env.REACT_APP_ADVANCE_TOOTH_SETTING;

  const [examOther, setExamOther] = useState(null);
  const [examTeethChangePresent, setExamTeethChangePresent] =
    useState(examTeethInitCommon);
  const [examTeethPresentExpanded, setExamTeethPresentExpanded] =
    useState(false);
  const [examTeethChangePainOnPercussion, setExamTeethChangePainOnPercussion] =
    useState(examTeethInitCommon);
  const [
    examTeethPainOnPercussionExpanded,
    setExamTeethPainOnPercussionExpanded,
  ] = useState(false);
  const [examTeethChangeSwelling, setExamTeethChangeSwelling] =
    useState(examTeethInitCommon);
  const [examTeethSwellingExpanded, setExamTeethSwellingExpanded] =
    useState(false);
  const [examTeethChangeFracturedTeeth, setExamTeethChangeFracturedTeeth] =
    useState(examTeethInitCommon);
  const [examTeethFracturedTeethExpanded, setExamTeethFracturedTeethExpanded] =
    useState(false);
  const [examAdvaceTeethChangeCaries, setExamAdvaceTeethChangeCaries] =
    useState(examAdvaceTeethInitCommon);
  const [examAdvaceTeethCariesExpanded, setExamAdvaceTeethCariesExpanded] =
    useState(false);
  const [examTeethChangeAbscess, setExamTeethChangeAbscess] =
    useState(examTeethInitCommon);
  const [examTeethAbscessExpanded, setExamTeethAbscessExpanded] =
    useState(false);
  const [examTeethChangeMobile, setExamTeethChangeMobile] =
    useState(examTeethInitCommon);
  const [examTeethMobileExpanded, setExamTeethMobileExpanded] = useState(false);
  const [examRatingGingivitis, setExamRatingGingivitis] = useState(0);
  const [examRatingPeriodontitis, setExamRatingPeriodontitis] = useState(0);
  const [examRatingCalculus, setExamRatingCalculus] = useState(0);
  const [examRatingStains, setExamRatingStains] = useState(0);
  const [examRatingMalocclusion, setExamRatingMalocclusion] = useState(0);
  const [examRatingProsthetics, setExamRatingProsthetics] = useState(0);

  const [createBy, setCreateBy] = useState("");
  const [updateBy, setUpdateBy] = useState("");

  //#endregion Variable Section

  //#region Main Event Handlers

  const clear = async (isMsgShow) => {
    // eslint-disable-next-line eqeqeq
    if (isMsgShow == true) {
      setClinicNo("");
    }

    setId(null);
    setQuadrantPain("0000");
    setQuadrantCracked("0000");
    setQuadrantDiscolored("0000");
    setNeedCleaning(false);
    setQuadrantSwelling("0000");
    setQuadrantGumBleeding("0000");
    setMalformedTeeth(false);
    setArtificialTeeth(false);
    setQuadrantShakingTeeth("0000");
    setQuadrantAssault("0000");
    setJutting(false);
    setExistingArtificial(false);
    setQuadrantDecayedTooth("0000");
    setQuadrantDamageAccident("0000");
    setSmell(false);
    setNonHealing(false);
    setComplainOther(null);

    setMedicNothingSignificant("");
    setMedicBleedingDisorders(false);
    setMedicAnesthetic(false);
    setMedicAsthma(false);
    setMedicCardiac(false);
    setMedicDiabetes(false);
    setMedicEndocrine(false);
    setMedicEpilepsy(false);
    setMedicKidney(false);
    setMedicHypertension(false);
    setMedicHepatitis(false);
    setMedicSkeletal(false);
    setMedicRheumatic(false);
    setMedicGastric(false);
    setMedicGastricGenaral(false);
    setMedicGastricDrug(false);
    setMedicAllergies(null);
    setMedicHistoryOfSx(null);
    setMedicRegularDrugs(null);
    setMedicOther(null);

    setDentNothingSignificant(false);
    setDentBleeding(false);
    setDentFailedFillings(false);
    setDentFailedRCT(false);
    setDentPainAfterFilling(false);
    setDentFailedProsthesis(false);
    setDentFailedOrthoRx(false);
    setDentExtraction(false);
    setDentOther(null);

    setExamOther(null);
    setExamTeethChangePresent(examTeethInitCommon);
    setExamTeethChangePainOnPercussion(examTeethInitCommon);
    setExamTeethChangeSwelling(examTeethInitCommon);
    setExamTeethChangeFracturedTeeth(examTeethInitCommon);
    setExamAdvaceTeethChangeCaries(examAdvaceTeethInitCommon);
    setExamTeethChangeAbscess(examTeethInitCommon);
    setExamTeethChangeMobile(examTeethInitCommon);
    setExamRatingGingivitis(0);
    setExamRatingPeriodontitis(0);
    setExamRatingCalculus(0);
    setExamRatingStains(0);
    setExamRatingMalocclusion(0);
    setExamRatingProsthetics(0);

    setExamTeethPresentExpanded(false);
    setExamTeethPainOnPercussionExpanded(false);
    setExamTeethSwellingExpanded(false);
    setExamTeethFracturedTeethExpanded(false);
    setExamAdvaceTeethCariesExpanded(false);
    setExamTeethAbscessExpanded(false);
    setExamTeethMobileExpanded(false);

    resetAButton();
    if (isMsgShow === true) handleClearValues();
    if (isMsgShow === true)
      triggerAlertOn("Clear", "Successfully cleaned!", "success");
  };

  const save = async () => {
    try {
      api
        .post(`/compliants/save`, {
          clinic_no: clinicNo,
          visit_date: new Date(),
          com_pain: quadrantPain,
          com_swelling: quadrantSwelling,
          com_shaking: quadrantShakingTeeth,
          com_decayed: quadrantDecayedTooth,
          com_cracked: quadrantCracked,
          com_gum: quadrantGumBleeding,
          com_assault: quadrantAssault,
          com_accident: quadrantDamageAccident,
          com_discolor: quadrantDiscolored,
          com_malformed: malformedTeeth,
          com_jutting: jutting,
          com_smell: smell,
          com_artificial: artificialTeeth,
          com_probartificial: existingArtificial,
          com_nonhealing: nonHealing,
          com_cleaning: needCleaning,
          com_other: complainOther,
          med_nothing: medicNothingSignificant,
          med_cardiac: medicCardiac,
          med_kidney: medicKidney,
          med_skeletal: medicSkeletal,
          med_bleeding: medicBleedingDisorders,
          med_diabetes: medicDiabetes,
          med_hypertension: medicHypertension,
          med_rheumatic: medicRheumatic,
          med_anesthetic: medicAnesthetic,
          med_endocrine: medicEndocrine,
          med_hepatitis: medicHepatitis,
          med_gastric: medicGastric,
          med_gastric_general: medicGastricGenaral,
          med_gastric_drug: medicGastricDrug,
          med_asthma: medicAsthma,
          med_epilepsy: medicEpilepsy,
          med_allergies: medicAllergies,
          med_historyof: medicHistoryOfSx,
          med_onregular: medicRegularDrugs,
          med_other: medicOther,
          den_nothing: dentNothingSignificant,
          den_ffilling: dentFailedFillings,
          den_painafter: dentPainAfterFilling,
          den_forthorx: dentFailedOrthoRx,
          den_bleeding: dentBleeding,
          den_frct: dentFailedRCT,
          den_fprosthesis: dentFailedProsthesis,
          den_extraction: dentExtraction,
          den_other: dentOther,
          on_other: examOther,
          on_gingivitis: examRatingGingivitis,
          on_stains: examRatingStains,
          on_periodontitis: examRatingPeriodontitis,
          on_malocclusion: examRatingMalocclusion,
          on_calculus: examRatingCalculus,
          on_wear: examRatingProsthetics,
          on_present:
            examTeethChangePresent === examTeethInitCommon
              ? null
              : examTeethChangePresent,
          on_pain:
            examTeethChangePainOnPercussion === examTeethInitCommon
              ? null
              : examTeethChangePainOnPercussion,
          on_swelling:
            examTeethChangeSwelling === examTeethInitCommon
              ? null
              : examTeethChangeSwelling,
          on_fractured:
            examTeethChangeFracturedTeeth === examTeethInitCommon
              ? null
              : examTeethChangeFracturedTeeth,
          on_caries:
            examAdvaceTeethChangeCaries === examAdvaceTeethInitCommon
              ? null
              : examAdvaceTeethChangeCaries,
          on_abscess:
            examTeethChangeAbscess === examTeethInitCommon
              ? null
              : examTeethChangeAbscess,
          on_mobile:
            examTeethChangeMobile === examTeethInitCommon
              ? null
              : examTeethChangeMobile,
          com_code: sessionStorage.getItem("usercom"),
          create_dt: new Date(),
          create_by: createBy,
        })
        .then(() => {
          clear(true);
          triggerAlertOn("Save", "Successfully saved!", "success");
        })
        .catch((error) => {
          if (error.response.status === 400) {
            triggerAlertOn("Save", error.response.data.error, "error");
          } else {
            triggerAlertOn(
              "Save",
              "There is an error occured while save the record. The error is " +
                error,
              "error"
            );
          }
        });
    } catch (error) {
      console.error(error);
      triggerAlertOn(
        "Save",
        "There is an error occured while save the record. The error is " +
          error,
        "error"
      );
    }
  };

  const edit = async () => {
    try {
      api
        .put(`/compliants/edit`, {
          id: id,
          clinic_no: clinicNo,
          com_pain: quadrantPain,
          com_swelling: quadrantSwelling,
          com_shaking: quadrantShakingTeeth,
          com_decayed: quadrantDecayedTooth,
          com_cracked: quadrantCracked,
          com_gum: quadrantGumBleeding,
          com_assault: quadrantAssault,
          com_accident: quadrantDamageAccident,
          com_discolor: quadrantDiscolored,
          com_malformed: malformedTeeth,
          com_jutting: jutting,
          com_smell: smell,
          com_artificial: artificialTeeth,
          com_probartificial: existingArtificial,
          com_nonhealing: nonHealing,
          com_cleaning: needCleaning,
          com_other: complainOther,
          med_nothing: medicNothingSignificant,
          med_cardiac: medicCardiac,
          med_kidney: medicKidney,
          med_skeletal: medicSkeletal,
          med_bleeding: medicBleedingDisorders,
          med_diabetes: medicDiabetes,
          med_hypertension: medicHypertension,
          med_rheumatic: medicRheumatic,
          med_anesthetic: medicAnesthetic,
          med_endocrine: medicEndocrine,
          med_hepatitis: medicHepatitis,
          med_gastric: medicGastric,
          med_gastric_general: medicGastricGenaral,
          med_gastric_drug: medicGastricDrug,
          med_asthma: medicAsthma,
          med_epilepsy: medicEpilepsy,
          med_allergies: medicAllergies,
          med_historyof: medicHistoryOfSx,
          med_onregular: medicRegularDrugs,
          med_other: medicOther,
          den_nothing: dentNothingSignificant,
          den_ffilling: dentFailedFillings,
          den_painafter: dentPainAfterFilling,
          den_forthorx: dentFailedOrthoRx,
          den_bleeding: dentBleeding,
          den_frct: dentFailedRCT,
          den_fprosthesis: dentFailedProsthesis,
          den_extraction: dentExtraction,
          den_other: dentOther,
          on_other: examOther,
          on_gingivitis: examRatingGingivitis,
          on_stains: examRatingStains,
          on_periodontitis: examRatingPeriodontitis,
          on_malocclusion: examRatingMalocclusion,
          on_calculus: examRatingCalculus,
          on_wear: examRatingProsthetics,
          on_present:
            examTeethChangePresent === examTeethInitCommon
              ? null
              : examTeethChangePresent,
          on_pain:
            examTeethChangePainOnPercussion === examTeethInitCommon
              ? null
              : examTeethChangePainOnPercussion,
          on_swelling:
            examTeethChangeSwelling === examTeethInitCommon
              ? null
              : examTeethChangeSwelling,
          on_fractured:
            examTeethChangeFracturedTeeth === examTeethInitCommon
              ? null
              : examTeethChangeFracturedTeeth,
          on_caries:
            examAdvaceTeethChangeCaries === examAdvaceTeethInitCommon
              ? null
              : examAdvaceTeethChangeCaries,
          on_abscess:
            examTeethChangeAbscess === examTeethInitCommon
              ? null
              : examTeethChangeAbscess,
          on_mobile:
            examTeethChangeMobile === examTeethInitCommon
              ? null
              : examTeethChangeMobile,
          update_dt: new Date(),
          update_by: updateBy,
        })
        .then(() => {
          clear(true);
          triggerAlertOn("Edit", "Successfully edited!", "success");
        })
        .catch((error) => {
          console.error(error);
          triggerAlertOn(
            "Edit",
            "There is an error occured while edit the record. The error is " +
              error,
            "error"
          );
        });
    } catch (error) {
      console.error(error);
      triggerAlertOn(
        "Edit",
        "There is an error occured while edit the record. The error is " +
          error,
        "error"
      );
    }
  };

  const validatePage = () => {
    // Check required fields
    if (!clinicNo) {
      return false;
    }

    // All checks passed, return true
    return true;
  };

  const handleSave = async () => {
    if (id != null) {
      triggerAlertOn(
        "Save",
        "Process terminated. You cannot save this again, try edit! ",
        "error"
      );
      return;
    }

    if (validatePage()) {
      handleButtonClick("Save");
    } else {
      triggerAlertOn(
        "Save",
        "Process terminated. Please check all the required fields! ",
        "error"
      );
    }
  };

  const handleClear = (isNoMsg) => {
    if (isNoMsg === true) {
      clear(false);
    } else {
      handleButtonClick("Clear");
    }
  };

  const handleEdit = () => {
    if (id == null) {
      triggerAlertOn(
        "Edit",
        "Process terminated. Seems this is a new record!",
        "error"
      );
      return;
    }
    handleButtonClick("Edit");
  };

  const handleSearch = async () => {
    if (clinicNo === "" || clinicNo === "undefined" || clinicNo === null)
      return;
    try {
      //console.log("clinicNo complains " + clinicNo);
      const response = await api.get(`/compliants/all/${clinicNo}`);
      const data = (await response).data.data;
      // eslint-disable-next-line eqeqeq
      const clinicData = data.find((item) => item.clinic_no == clinicNo);

      clear(false);
      if (clinicData) {
        setId(clinicData.id);
        //setClinicNo(clinicData.clinic_no);

        setQuadrantPain(clinicData.com_pain);
        setQuadrantCracked(clinicData.com_shaking);
        setQuadrantDiscolored(clinicData.com_discolor);
        setNeedCleaning(clinicData.com_cleaning);
        setQuadrantSwelling(clinicData.com_swelling);
        setQuadrantGumBleeding(clinicData.com_gum);
        // eslint-disable-next-line eqeqeq
        setMalformedTeeth(clinicData.com_malformed == 1 ? true : false);
        setArtificialTeeth(clinicData.com_artificial);
        setQuadrantShakingTeeth(clinicData.com_shaking);
        setQuadrantAssault(clinicData.com_assault);
        setJutting(clinicData.com_jutting);
        setExistingArtificial(clinicData.com_probartificial);
        setQuadrantDecayedTooth(clinicData.com_decayed);
        setQuadrantDamageAccident(clinicData.com_accident);
        setSmell(clinicData.com_smell);
        setNonHealing(clinicData.com_nonhealing);
        setComplainOther(clinicData.com_other);

        setMedicNothingSignificant(clinicData.med_nothing);
        setMedicBleedingDisorders(clinicData.med_bleeding);
        setMedicAnesthetic(clinicData.med_anesthetic);
        setMedicAsthma(clinicData.med_asthma);
        setMedicCardiac(clinicData.med_cardiac);
        setMedicDiabetes(clinicData.med_diabetes);
        setMedicEndocrine(clinicData.med_endocrine);
        setMedicEpilepsy(clinicData.med_epilepsy);
        setMedicKidney(clinicData.med_kidney);
        setMedicHypertension(clinicData.med_hypertension);
        setMedicHepatitis(clinicData.med_hepatitis);
        setMedicSkeletal(clinicData.med_skeletal);
        setMedicRheumatic(clinicData.med_rheumatic);
        setMedicGastric(clinicData.med_gastric);
        setMedicGastricGenaral(clinicData.med_gastric_general);
        setMedicGastricDrug(clinicData.med_gastric_drug);
        setMedicAllergies(clinicData.med_allergies);
        setMedicHistoryOfSx(clinicData.med_historyof);
        setMedicRegularDrugs(clinicData.med_onregular);
        setMedicOther(clinicData.med_other);

        setDentNothingSignificant(clinicData.den_other);
        setDentBleeding(clinicData.den_bleeding);
        setDentFailedFillings(clinicData.den_ffilling);
        setDentFailedRCT(clinicData.den_frct);
        setDentPainAfterFilling(clinicData.den_painafter);
        setDentFailedProsthesis(clinicData.den_fprosthesis);
        setDentFailedOrthoRx(clinicData.den_forthorx);
        setDentExtraction(clinicData.den_extraction);
        setDentOther(clinicData.den_other);

        setExamOther(clinicData.on_other);
        setExamTeethChangePresent(
          clinicData.on_present == null
            ? examTeethInitCommon
            : clinicData.on_present
        );
        setExamTeethChangePainOnPercussion(
          clinicData.on_pain == null ? examTeethInitCommon : clinicData.on_pain
        );
        setExamTeethChangeSwelling(
          clinicData.on_swelling == null
            ? examTeethInitCommon
            : clinicData.on_swelling
        );
        setExamTeethChangeFracturedTeeth(
          clinicData.on_fractured == null
            ? examTeethInitCommon
            : clinicData.on_fractured
        );
        setExamAdvaceTeethChangeCaries(
          clinicData.on_caries == null
            ? examTeethInitCommon
            : clinicData.on_caries
        );
        setExamTeethChangeAbscess(
          clinicData.on_abscess == null
            ? examTeethInitCommon
            : clinicData.on_abscess
        );
        setExamTeethChangeMobile(
          clinicData.on_mobile == null
            ? examTeethInitCommon
            : clinicData.on_mobile
        );
        setExamRatingGingivitis(clinicData.on_gingivitis);
        setExamRatingPeriodontitis(clinicData.on_periodontitis);
        setExamRatingCalculus(clinicData.on_calculus);
        setExamRatingStains(clinicData.on_stains);
        setExamRatingMalocclusion(clinicData.on_malocclusion);
        setExamRatingProsthetics(clinicData.on_wear);

        clinicData.on_present == null
          ? setExamTeethPresentExpanded(false)
          : setExamTeethPresentExpanded(true);
        clinicData.on_pain == null
          ? setExamTeethPainOnPercussionExpanded(false)
          : setExamTeethPainOnPercussionExpanded(true);
        clinicData.on_swelling == null
          ? setExamTeethSwellingExpanded(false)
          : setExamTeethSwellingExpanded(true);
        clinicData.on_fractured == null
          ? setExamTeethFracturedTeethExpanded(false)
          : setExamTeethFracturedTeethExpanded(true);
        clinicData.on_caries == null
          ? setExamAdvaceTeethCariesExpanded(false)
          : setExamAdvaceTeethCariesExpanded(true);
        clinicData.on_abscess == null
          ? setExamTeethAbscessExpanded(false)
          : setExamTeethAbscessExpanded(true);
        clinicData.on_mobile == null
          ? setExamTeethMobileExpanded(false)
          : setExamTeethMobileExpanded(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    // Handle search logic
  };

  const handleHistory = () => {
    // Handle search logic
  };

  //#endregion Main Event Handlers

  //#region Other Events

  const clickQuadrantPain = (result) => {
    setQuadrantPain(result);
  };

  const clickQuadrantCracked = (result) => {
    setQuadrantCracked(result);
  };

  const clickQuadrantDiscolored = (result) => {
    setQuadrantDiscolored(result);
  };

  const clickNeedCleaning = (result) => {
    setNeedCleaning(result.target.checked);
  };

  const clickQuadrantSwelling = (result) => {
    setQuadrantSwelling(result);
  };

  const clickQuadrantGumBleeding = (result) => {
    setQuadrantGumBleeding(result);
  };

  const clickMalformedTeeth = (result) => {
    setMalformedTeeth(result.target.checked);
  };

  const clickArtificialTeeth = (result) => {
    setArtificialTeeth(result.target.checked);
  };

  const clickQuadrantShakingTeeth = (result) => {
    setQuadrantShakingTeeth(result);
  };

  const clickQuadrantAssault = (result) => {
    setQuadrantAssault(result);
  };

  const clickJutting = (result) => {
    setJutting(result.target.checked);
  };

  const clickExistingArtificial = (result) => {
    setExistingArtificial(result.target.checked);
  };

  const clickQuadrantDecayedTooth = (result) => {
    setQuadrantDecayedTooth(result);
  };

  const clickQuadrantDamageAccident = (result) => {
    setQuadrantDamageAccident(result);
  };

  const clickSmell = (result) => {
    setSmell(result.target.checked);
  };

  const clickNonHealing = (result) => {
    setNonHealing(result.target.checked);
  };

  const clickComplainOther = (result) => {
    setComplainOther(result.target.value);
  };

  const clickMedicNothingSignificant = (result) => {
    setMedicNothingSignificant(result.target.checked);
  };

  const clickMedicBleedingDisorders = (result) => {
    setMedicBleedingDisorders(result.target.checked);
  };

  const clickMedicAnesthetic = (result) => {
    setMedicAnesthetic(result.target.checked);
  };

  const clickMedicAsthma = (result) => {
    setMedicAsthma(result.target.checked);
  };

  const clickMedicCardiac = (result) => {
    setMedicCardiac(result.target.checked);
  };

  const clickMedicDiabetes = (result) => {
    setMedicDiabetes(result.target.checked);
  };

  const clickMedicEndocrine = (result) => {
    setMedicEndocrine(result.target.checked);
  };

  const clickMedicEpilepsy = (result) => {
    setMedicEpilepsy(result.target.checked);
  };

  const clickMedicKidney = (result) => {
    setMedicKidney(result.target.checked);
  };

  const clickMedicHypertension = (result) => {
    setMedicHypertension(result.target.checked);
  };

  const clickMedicHepatitis = (result) => {
    setMedicHepatitis(result.target.checked);
  };

  const clickMedicSkeletal = (result) => {
    setMedicSkeletal(result.target.checked);
  };

  const clickMedicRheumatic = (result) => {
    setMedicRheumatic(result.target.checked);
  };

  const clickMedicGastric = (result) => {
    setMedicGastric(result.target.checked);
  };

  const clickMedicGastricGenaral = (result) => {
    setMedicGastricGenaral(result.target.checked);
  };

  const clickMedicGastricDrug = (result) => {
    setMedicGastricDrug(result.target.checked);
  };

  const clickMedicAllergies = (result) => {
    setMedicAllergies(result.target.value);
  };

  const clickMedicHistoryOfSx = (result) => {
    setMedicHistoryOfSx(result.target.value);
  };

  const clickMedicRegularDrugs = (result) => {
    setMedicRegularDrugs(result.target.value);
  };

  const clickMedicOther = (result) => {
    setMedicOther(result.target.value);
  };

  const clickDentNothingSignificant = (result) => {
    setDentNothingSignificant(result.target.checked);
  };

  const clickDentBleeding = (result) => {
    setDentBleeding(result.target.checked);
  };

  const clickDentFailedFillings = (result) => {
    setDentFailedFillings(result.target.checked);
  };

  const clickDentFailedRCT = (result) => {
    setDentFailedRCT(result.target.checked);
  };

  const clickDentPainAfterFilling = (result) => {
    setDentPainAfterFilling(result.target.checked);
  };

  const clickDentFailedProsthesis = (result) => {
    setDentFailedProsthesis(result.target.checked);
  };

  const clickDentFailedOrthoRx = (result) => {
    setDentFailedOrthoRx(result.target.checked);
  };

  const clickDentExtraction = (result) => {
    setDentExtraction(result.target.checked);
  };

  const clickDentOther = (result) => {
    setDentOther(result.target.value);
  };

  const clickExamOther = (result) => {
    setExamOther(result.target.value);
  };

  const handleExamTeethInitPresent = (myDictionary) => {
    setExamTeethChangePresent(convertDictionaryToString(myDictionary));
  };

  const handleExamTeethInitPresentToggle = () => {
    setExamTeethPresentExpanded((prevExpanded) => !prevExpanded);
  };

  const handleExamTeethInitPainOnPercussion = (myDictionary) => {
    setExamTeethChangePainOnPercussion(convertDictionaryToString(myDictionary));
  };

  const handleExamTeethInitPainOnPercussionToggle = () => {
    setExamTeethPainOnPercussionExpanded((prevExpanded) => !prevExpanded);
  };

  const handleExamTeethInitSwelling = (myDictionary) => {
    setExamTeethChangeSwelling(convertDictionaryToString(myDictionary));
  };

  const handleExamTeethInitSwellingToggle = () => {
    setExamTeethSwellingExpanded((prevExpanded) => !prevExpanded);
  };

  const handleExamTeethInitFracturedTeeth = (myDictionary) => {
    setExamTeethChangeFracturedTeeth(convertDictionaryToString(myDictionary));
  };

  const handleExamTeethInitFracturedTeethToggle = (myDictionary) => {
    setExamTeethFracturedTeethExpanded((prevExpanded) => !prevExpanded);
  };

  const handleExamTeethInitAbscess = (myDictionary) => {
    setExamTeethChangeAbscess(convertDictionaryToString(myDictionary));
  };

  const handleExamTeethInitAbscessToggle = () => {
    setExamTeethAbscessExpanded((prevExpanded) => !prevExpanded);
  };

  const handleExamTeethInitMobile = (myDictionary) => {
    setExamTeethChangeMobile(convertDictionaryToString(myDictionary));
  };

  const handleExamTeethInitMobileToggle = () => {
    setExamTeethMobileExpanded((prevExpanded) => !prevExpanded);
  };

  const handleExamTeethInitCariesToggle = () => {
    setExamAdvaceTeethCariesExpanded((prevExpanded) => !prevExpanded);
  };

  const handleExamRatingGingivitis = (event, newValue) => {
    setExamRatingGingivitis(newValue);
  };

  const handleExamRatingPeriodontitis = (event, newValue) => {
    setExamRatingPeriodontitis(newValue);
  };

  const handleExamRatingCalculus = (event, newValue) => {
    setExamRatingCalculus(newValue);
  };

  const handleExamRatingStains = (event, newValue) => {
    setExamRatingStains(newValue);
  };

  const handleExamRatingMalocclusion = (event, newValue) => {
    setExamRatingMalocclusion(newValue);
  };

  const handleExamRatingProsthetics = (event, newValue) => {
    setExamRatingProsthetics(newValue);
  };

  const handleClinicNoChange = (newClinicNo) => {
    setClinicNo(newClinicNo);
  };

  //#endregion Other Events

  //#region outside controller compartments

  const convertDictionaryToString = (dictionary) => {
    return Object.entries(dictionary)
      .map(([key, value]) => `${key}.${value}`)
      .join(",");
  };

  const handleButtonClick = (buttonId) => {
    if (buttonId === "Save") {
      setMessage("Do you really want to save this complains?");
    } else if (buttonId === "Edit") {
      setMessage("Do you really want to edit this complains?");
    } else if (buttonId === "Clear") {
      setMessage("Do you really want to clear this complains?");
    }
    setFunctionality(buttonId);
    setMessageBoxOpen(true);
  };

  const handleMessageBoxClose = (isPrimaryButtonClicked) => {
    if (isPrimaryButtonClicked && functionality === "Save") {
      save();
    }
    if (isPrimaryButtonClicked && functionality === "Clear") {
      clear(true);
    }

    if (isPrimaryButtonClicked && functionality === "Edit") {
      edit();
    }

    // Set isMessageBoxOpen to false to hide the message box
    setMessageBoxOpen(false);
  };

  const triggerAlertOn = async (title, description, severity) => {
    setAlertTitle(title);
    setAlertDescription(description);
    setAlertSeverity(severity);
    setShowAlert(true);

    setTimeout(() => {
      setAlertTitle("");
      setAlertDescription("");
      setAlertSeverity("");
      setShowAlert(false);
    }, 2000);
  };

  useEffect(() => {
    setCreateBy(sessionStorage.getItem("userid"));
    setUpdateBy(sessionStorage.getItem("userid"));
  }, []);

  const resetAButton = () => {
    // Update the key to remount component A and reset its state
    setResetKey((prevKey) => prevKey + 1);
  };

  const handleClearValues = () => {
    // Set clearValues to true to trigger the clearing in CrudController
    setClearValues(true);
  };

  // Callback function to reset clearValues in CrudController
  const handleClearComplete = () => {
    setClearValues(false);
  };

  //#endregion outside controller compartments

  const handleDataFetchStatus = (status) => {
    if (status === false) {
      triggerAlertOn(
        "Search",
        `This is an invalid clinic no. Please check the number and try again.`,
        "error"
      );
      setClinicNo("");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        marginTop: "16px",
        marginLeft: "16px",
      }}
    >
      <Grid container spacing={2}>
        {/* 1 Row */}
        <CrudController
          onSave={handleSave}
          onClear={handleClear}
          onEdit={handleEdit}
          onSearch={handleSearch}
          onClose={handleClose}
          onHistory={handleHistory}
          clinicNo={clinicNo}
          onClinicNoChange={handleClinicNoChange}
          buttonStates={buttonStates}
          isNameVisible={true}
          clearValues={clearValues}
          onClearComplete={handleClearComplete}
          onDataFetchStatus={handleDataFetchStatus}
          isDoSearch={true}
          isClinicNoVisible={true}
        />

        {/* Complains */}
        <Grid container>
          {/* r 1 */}
          <Grid container spacing={10}>
            <Grid item xs={3}>
              <Paper elevation={0} style={{ padding: 5, textAlign: "left" }}>
                <Typography variant="h6" fontWeight={550}>Complains</Typography>
              </Paper>
            </Grid>
          </Grid>

          {/* 1 Column */}
          <Grid item xs={3}>
            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <FBQuadrant
                initialString={quadrantPain}
                onButtonClick={clickQuadrantPain}
                titleName="Pain"
              />
            </Paper>

            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <FBQuadrant
                initialString={quadrantCracked}
                onButtonClick={clickQuadrantCracked}
                titleName="Cracked tooth/teeth"
              />
            </Paper>

            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <FBQuadrant
                initialString={quadrantDiscolored}
                onButtonClick={clickQuadrantDiscolored}
                titleName="Discolored tooth/teeth"
              />
            </Paper>

            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="chkCleaning"
                    checked={needCleaning}
                    onChange={clickNeedCleaning}
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                    }}
                  />
                }
                label="Need cleaning"
              />
            </Paper>
          </Grid>

          {/* 2 Column */}
          <Grid item xs={3}>
            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <FBQuadrant
                initialString={quadrantSwelling}
                onButtonClick={clickQuadrantSwelling}
                titleName="Swelling"
              />
            </Paper>

            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <FBQuadrant
                initialString={quadrantGumBleeding}
                onButtonClick={clickQuadrantGumBleeding}
                titleName="Gum bleeding"
              />
            </Paper>

            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="chkMalformedTeeth"
                    checked={malformedTeeth}
                    onChange={clickMalformedTeeth}
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                    }}
                  />
                }
                label="to correct malformed teeth"
              />
            </Paper>

            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="chkArtificialTeeth"
                    checked={artificialTeeth}
                    onChange={clickArtificialTeeth}
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                    }}
                  />
                }
                label="need artificial teeth"
              />
            </Paper>
          </Grid>

          {/* 3 Column */}
          <Grid item xs={3}>
            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <FBQuadrant
                initialString={quadrantShakingTeeth}
                onButtonClick={clickQuadrantShakingTeeth}
                titleName="Shaking teeth"
              />
            </Paper>

            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <FBQuadrant
                initialString={quadrantAssault}
                onButtonClick={clickQuadrantAssault}
                titleName="Damage due to Assault"
              />
            </Paper>

            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="chkJutting"
                    checked={jutting}
                    onChange={clickJutting}
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                    }}
                  />
                }
                label="to correct jutting out teeth"
              />
            </Paper>

            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="chkExistingArtificial"
                    checked={existingArtificial}
                    onChange={clickExistingArtificial}
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                    }}
                  />
                }
                label="problem with existing artificial teeth"
              />
            </Paper>
          </Grid>

          {/* 4 Column */}
          <Grid item xs={3}>
            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <FBQuadrant
                initialString={quadrantDecayedTooth}
                onButtonClick={clickQuadrantDecayedTooth}
                titleName="Decayed tooth/teeth"
              />
            </Paper>

            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <FBQuadrant
                initialString={quadrantDamageAccident}
                onButtonClick={clickQuadrantDamageAccident}
                titleName="Damage due to accident"
              />
            </Paper>

            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="chkSmell"
                    checked={smell}
                    onChange={clickSmell}
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                    }}
                  />
                }
                label="Smell from mouth"
              />
            </Paper>

            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="chkNonHealing"
                    checked={nonHealing}
                    onChange={clickNonHealing}
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                    }}
                  />
                }
                label="Non-Healing Wound"
              />
            </Paper>
          </Grid>

          <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
            <CustomCheckBoxTextField
              label="Other"
              id="complainOther"
              width="885px"
              height="55px"
              onChange={clickComplainOther}
              value={complainOther}
            />
          </Paper>
        </Grid>

        {/* Medical */}
        <Grid container>
          {/* r 1 */}
          <Grid container spacing={10}>
            <Grid item xs={3}>
              <Paper elevation={0} style={{ padding: 5, textAlign: "left" }}>
                <Typography variant="h6" fontWeight={550}>Medical History</Typography>
              </Paper>
            </Grid>
          </Grid>

          {/* 1 Column */}
          <Grid item xs={3}>
            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="chkMedicNothingSignificant"
                    checked={medicNothingSignificant}
                    onChange={clickMedicNothingSignificant}
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                    }}
                  />
                }
                label="Nothing Significant"
              />
            </Paper>

            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="chkBleedingDisorders"
                    checked={medicBleedingDisorders}
                    onChange={clickMedicBleedingDisorders}
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                    }}
                  />
                }
                label="Bleeding Disorders"
              />
            </Paper>

            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="chkAnesthetic"
                    checked={medicAnesthetic}
                    onChange={clickMedicAnesthetic}
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                    }}
                  />
                }
                label="Anesthetic problems"
              />
            </Paper>

            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="chkAsthma"
                    checked={medicAsthma}
                    onChange={clickMedicAsthma}
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                    }}
                  />
                }
                label="Asthma"
              />
            </Paper>
          </Grid>

          {/* 2 Column */}
          <Grid item xs={3}>
            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="chkCardiac"
                    checked={medicCardiac}
                    onChange={clickMedicCardiac}
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                    }}
                  />
                }
                label="Cardiac disorders"
              />
            </Paper>

            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="chkDiabetes"
                    checked={medicDiabetes}
                    onChange={clickMedicDiabetes}
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                    }}
                  />
                }
                label="Diabetes"
              />
            </Paper>

            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="chkEndocrine"
                    checked={medicEndocrine}
                    onChange={clickMedicEndocrine}
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                    }}
                  />
                }
                label="Endocrine disorders"
              />
            </Paper>

            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="chkEpilepsy"
                    checked={medicEpilepsy}
                    onChange={clickMedicEpilepsy}
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                    }}
                  />
                }
                label="Epilepsy"
              />
            </Paper>
          </Grid>

          {/* 3 Column */}
          <Grid item xs={3}>
            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="chkKidney"
                    checked={medicKidney}
                    onChange={clickMedicKidney}
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                    }}
                  />
                }
                label="Kidney disorders"
              />
            </Paper>

            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="chkHypertension"
                    checked={medicHypertension}
                    onChange={clickMedicHypertension}
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                    }}
                  />
                }
                label="Hypertension"
              />
            </Paper>

            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="chkHepatitis"
                    checked={medicHepatitis}
                    onChange={clickMedicHepatitis}
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                    }}
                  />
                }
                label="Hepatitis"
              />
            </Paper>
          </Grid>

          {/* 4 Column */}
          <Grid item xs={3}>
            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="chkSkeletal"
                    checked={medicSkeletal}
                    onChange={clickMedicSkeletal}
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                    }}
                  />
                }
                label="Skeletal/bone disorders"
              />
            </Paper>

            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="chkRheumatic"
                    checked={medicRheumatic}
                    onChange={clickMedicRheumatic}
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                    }}
                  />
                }
                label="Rheumatic fever/carditis/artheritis"
              />
            </Paper>

            <Paper elevation={0} style={{ paddingLeft: 1, textAlign: "left" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="chkGastric"
                    checked={medicGastric}
                    onChange={clickMedicGastric}
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                    }}
                  />
                }
                label="Gastric problems"
              />

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingLeft: "25px",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      name="chkGenaral"
                      checked={medicGastricGenaral}
                      onChange={clickMedicGastricGenaral}
                      disabled={!medicGastric}
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                      }}
                    />
                  }
                  label="General"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="chkDrugInduced"
                      checked={medicGastricDrug}
                      onChange={clickMedicGastricDrug}
                      disabled={!medicGastric}
                      sx={{
                        color: pink[800],
                        "&.Mui-checked": {
                          color: pink[600],
                        },
                      }}
                    />
                  }
                  label="Drug induced"
                />
              </div>
            </Paper>
          </Grid>

          <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
            <CustomCheckBoxTextField
              label="Allergies"
              id="Allergies"
              width="885px"
              height="55px"
              onChange={clickMedicAllergies}
              value={medicAllergies}
            />
          </Paper>

          <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
            <CustomCheckBoxTextField
              label="History of Sx"
              id="HistoryOfSx"
              width="885px"
              height="55px"
              onChange={clickMedicHistoryOfSx}
              value={medicHistoryOfSx}
            />
          </Paper>

          <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
            <CustomCheckBoxTextField
              label="On regular drugs"
              id="RegularDrugs"
              width="885px"
              height="55px"
              onChange={clickMedicRegularDrugs}
              value={medicRegularDrugs}
            />
          </Paper>

          <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
            <CustomCheckBoxTextField
              label="Other"
              id="MedicOther"
              width="885px"
              height="55px"
              onChange={clickMedicOther}
              value={medicOther}
            />
          </Paper>
        </Grid>

        {/* Dental */}
        <Grid container>
          {/* r 1 */}
          <Grid container spacing={10}>
            <Grid item xs={3}>
              <Paper elevation={0} style={{ padding: 5, textAlign: "left" }}>
                <Typography variant="h6" fontWeight={550}>Dental History</Typography>
              </Paper>
            </Grid>
          </Grid>

          {/* 1 Column */}
          <Grid item xs={3}>
            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="chkDentNothingSignificant"
                    checked={dentNothingSignificant}
                    onChange={clickDentNothingSignificant}
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                    }}
                  />
                }
                label="Nothing Significant"
              />
            </Paper>

            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="chkBleeding"
                    checked={dentBleeding}
                    onChange={clickDentBleeding}
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                    }}
                  />
                }
                label="Bleeding after Ext/Sx"
              />
            </Paper>
          </Grid>

          {/* 2 Column */}
          <Grid item xs={3}>
            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="chkFailedFillings"
                    checked={dentFailedFillings}
                    onChange={clickDentFailedFillings}
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                    }}
                  />
                }
                label="Failed fillings"
              />
            </Paper>

            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="chkFailedRCT"
                    checked={dentFailedRCT}
                    onChange={clickDentFailedRCT}
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                    }}
                  />
                }
                label="Failed RCT"
              />
            </Paper>
          </Grid>

          {/* 3 Column */}
          <Grid item xs={3}>
            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="chkPainAfterFilling"
                    checked={dentPainAfterFilling}
                    onChange={clickDentPainAfterFilling}
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                    }}
                  />
                }
                label="Pain after filling"
              />
            </Paper>

            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="chkFailedProsthesis"
                    checked={dentFailedProsthesis}
                    onChange={clickDentFailedProsthesis}
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                    }}
                  />
                }
                label="Failed Prosthesis"
              />
            </Paper>
          </Grid>

          {/* 4 Column */}
          <Grid item xs={3}>
            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="chkFailedOrthoRx"
                    checked={dentFailedOrthoRx}
                    onChange={clickDentFailedOrthoRx}
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                    }}
                  />
                }
                label="Failed Ortho Rx"
              />
            </Paper>

            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="chkExtraction"
                    checked={dentExtraction}
                    onChange={clickDentExtraction}
                    sx={{
                      color: pink[800],
                      "&.Mui-checked": {
                        color: pink[600],
                      },
                    }}
                  />
                }
                label="Extraction with complications"
              />
            </Paper>
          </Grid>

          <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
            <CustomCheckBoxTextField
              label="Other"
              id="dentOther"
              width="885px"
              height="55px"
              onChange={clickDentOther}
              value={dentOther}
            />
          </Paper>
        </Grid>
        {/* -------------------------------------------------------------------- */}

        {/* On Examination */}
        <Grid container>
          {/* r 1 */}
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <Paper elevation={0} style={{ padding: 5, textAlign: "left" }}>
                <Typography variant="h6" fontWeight={550}>On Examination</Typography>
              </Paper>
            </Grid>
          </Grid>

          {/* 1 Column */}
          <Grid item xs={8}>


            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <Accordion
                expanded={examTeethPresentExpanded}
                onChange={handleExamTeethInitPresentToggle}
              >
                {/* AccordionSummary contains the header of the Accordion */}
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  {" "}
                  {/* sx={{ backgroundColor: '#2196f3', color: 'white' }} */}
                  <Typography variant="h8" fontWeight={550}>Teeth Abscent</Typography>
                </AccordionSummary>

                {/* AccordionDetails contains the content that will be revealed when the Accordion is expanded */}
                <AccordionDetails>
                  <Tooth
                    initialButtonString={examTeethChangePresent}
                    key={resetKey}
                    onClickPatternChange={handleExamTeethInitPresent}
                  />
                </AccordionDetails>
              </Accordion>
            </Paper>

            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <Accordion
                expanded={examTeethPainOnPercussionExpanded}
                onChange={handleExamTeethInitPainOnPercussionToggle}
              >
                {/* AccordionSummary contains the header of the Accordion */}
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h8" fontWeight={550}>Pain on percussion</Typography>
                </AccordionSummary>

                {/* AccordionDetails contains the content that will be revealed when the Accordion is expanded */}
                <AccordionDetails>
                  <Tooth
                    initialButtonString={examTeethChangePainOnPercussion}
                    key={resetKey}
                    onClickPatternChange={handleExamTeethInitPainOnPercussion}
                  />
                </AccordionDetails>
              </Accordion>
            </Paper>

            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <Accordion
                expanded={examTeethSwellingExpanded}
                onChange={handleExamTeethInitSwellingToggle}
              >
                {/* AccordionSummary contains the header of the Accordion */}
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h8" fontWeight={550}>Swelling</Typography>
                </AccordionSummary>

                {/* AccordionDetails contains the content that will be revealed when the Accordion is expanded */}
                <AccordionDetails>
                  <Tooth
                    initialButtonString={examTeethChangeSwelling}
                    key={resetKey}
                    onClickPatternChange={handleExamTeethInitSwelling}
                  />
                </AccordionDetails>
              </Accordion>
            </Paper>

            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <Accordion
                expanded={examTeethFracturedTeethExpanded}
                onChange={handleExamTeethInitFracturedTeethToggle}
              >
                {/* AccordionSummary contains the header of the Accordion */}
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h8" fontWeight={550}>Fractured teeth</Typography>
                </AccordionSummary>

                {/* AccordionDetails contains the content that will be revealed when the Accordion is expanded */}
                <AccordionDetails>
                  <Tooth
                    initialButtonString={examTeethChangeFracturedTeeth}
                    key={resetKey}
                    onClickPatternChange={handleExamTeethInitFracturedTeeth}
                  />
                </AccordionDetails>
              </Accordion>
            </Paper>

            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <Accordion
                expanded={examAdvaceTeethCariesExpanded}
                onChange={handleExamTeethInitCariesToggle}
              >
                {/* AccordionSummary contains the header of the Accordion */}
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h8" fontWeight={550}>Caries</Typography>
                </AccordionSummary>

                {/* AccordionDetails contains the content that will be revealed when the Accordion is expanded */}
                <AccordionDetails>
                  <div className={classes.advtooth}>
                    <AdvanceTooth
                      initialButtonString={examAdvaceTeethChangeCaries}
                      key={resetKey}
                      ClickSelectedDictionary={(value) =>
                        setExamAdvaceTeethChangeCaries(value)
                      }
                    />
                  </div>
                </AccordionDetails>
              </Accordion>
            </Paper>

            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <Accordion
                expanded={examTeethAbscessExpanded}
                onChange={handleExamTeethInitAbscessToggle}
              >
                {/* AccordionSummary contains the header of the Accordion */}
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h8" fontWeight={550}>Abscess in relation to</Typography>
                </AccordionSummary>

                {/* AccordionDetails contains the content that will be revealed when the Accordion is expanded */}
                <AccordionDetails>
                  <Tooth
                    initialButtonString={examTeethChangeAbscess}
                    key={resetKey}
                    onClickPatternChange={handleExamTeethInitAbscess}
                  />
                </AccordionDetails>
              </Accordion>
            </Paper>

            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <Accordion
                expanded={examTeethMobileExpanded}
                onChange={handleExamTeethInitMobileToggle}
              >
                {/* AccordionSummary contains the header of the Accordion */}
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h8" fontWeight={550}>Mobile teeth</Typography>
                </AccordionSummary>

                {/* AccordionDetails contains the content that will be revealed when the Accordion is expanded */}
                <AccordionDetails>
                  <Tooth
                    initialButtonString={examTeethChangeMobile}
                    key={resetKey}
                    onClickPatternChange={handleExamTeethInitMobile}
                  />
                </AccordionDetails>
              </Accordion>
            </Paper>

            <Paper elevation={0} style={{ padding: 1, textAlign: "left", paddingTop:10 }}>
              <CustomCheckBoxTextField
                label="Other (specify)"
                id="examOther"
                width="985px"
                height="55px"
                onChange={clickExamOther}
                value={examOther}
              />
            </Paper>            
          </Grid>

          {/* 2 Column */}
          <Grid item xs={2} style={{ paddingLeft: 30, textAlign: "left" }}>
            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <Box component="fieldset" borderColor="transparent">
                <Typography component="legend">Gingivitis</Typography>
                <Rating
                  name="Gingivitis"
                  size="large"
                  value={examRatingGingivitis}
                  onChange={handleExamRatingGingivitis}
                  icon={
                    <AddIcon
                      sx={{
                        fontSize: 35,
                        color: "#f50057",
                        boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                      }}
                    />
                  }
                  emptyIcon={
                    <AddIcon
                      sx={{
                        fontSize: 30,
                        color: "gray",
                        boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                      }}
                    />
                  }
                ></Rating>
              </Box>
            </Paper>

            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <Box component="fieldset" borderColor="transparent">
                <Typography component="legend">Periodontitis</Typography>
                <Rating
                  name="Periodontitis"
                  defaultValue={0}
                  size="large"
                  value={examRatingPeriodontitis}
                  onChange={handleExamRatingPeriodontitis}
                  icon={
                    <AddIcon
                      sx={{
                        fontSize: 35,
                        color: "#f50057",
                        boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                      }}
                    />
                  }
                  emptyIcon={
                    <AddIcon
                      sx={{
                        fontSize: 30,
                        color: "gray",
                        boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                      }}
                    />
                  }
                ></Rating>
              </Box>
            </Paper>

            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <Box component="fieldset" borderColor="transparent">
                <Typography component="legend">Calculus</Typography>
                <Rating
                  name="Calculus"
                  defaultValue={0}
                  size="large"
                  value={examRatingCalculus}
                  onChange={handleExamRatingCalculus}
                  icon={
                    <AddIcon
                      sx={{
                        fontSize: 35,
                        color: "#f50057",
                        boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                      }}
                    />
                  }
                  emptyIcon={
                    <AddIcon
                      sx={{
                        fontSize: 30,
                        color: "gray",
                        boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                      }}
                    />
                  }
                ></Rating>
              </Box>
            </Paper>
          </Grid>

          {/* 3 Column */}
          <Grid item xs={2} style={{ paddingLeft: 70, textAlign: "left" }}>
            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <Box component="fieldset" borderColor="transparent">
                <Typography component="legend">Stains</Typography>
                <Rating
                  name="Stains"
                  defaultValue={0}
                  size="large"
                  value={examRatingStains}
                  onChange={handleExamRatingStains}
                  icon={
                    <AddIcon
                      sx={{
                        fontSize: 35,
                        color: "#f50057",
                        boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                      }}
                    />
                  }
                  emptyIcon={
                    <AddIcon
                      sx={{
                        fontSize: 30,
                        color: "gray",
                        boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                      }}
                    />
                  }
                ></Rating>
              </Box>
            </Paper>

            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <Box component="fieldset" borderColor="transparent">
                <Typography component="legend">Malocclusion</Typography>
                <Rating
                  name="Malocclusion"
                  defaultValue={0}
                  size="large"
                  value={examRatingMalocclusion}
                  onChange={handleExamRatingMalocclusion}
                  icon={
                    <AddIcon
                      sx={{
                        fontSize: 35,
                        color: "#f50057",
                        boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                      }}
                    />
                  }
                  emptyIcon={
                    <AddIcon
                      sx={{
                        fontSize: 30,
                        color: "gray",
                        boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                      }}
                    />
                  }
                ></Rating>
              </Box>
            </Paper>

            <Paper elevation={0} style={{ padding: 1, textAlign: "left" }}>
              <Box component="fieldset" borderColor="transparent">
                <Typography component="legend">Wear prosthetics</Typography>
                <Rating
                  name="prosthetics"
                  defaultValue={0}
                  size="large"
                  value={examRatingProsthetics}
                  onChange={handleExamRatingProsthetics}
                  icon={
                    <AddIcon
                      sx={{
                        fontSize: 35,
                        color: "#f50057",
                        boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                      }}
                    />
                  }
                  emptyIcon={
                    <AddIcon
                      sx={{
                        fontSize: 30,
                        color: "gray",
                        boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                      }}
                    />
                  }
                ></Rating>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <CustomMessageBox
        open={isMessageBoxOpen}
        onClose={handleMessageBoxClose}
        title="Patient Registration"
        message={message}
        primaryButtonCaption="OK"
        secondaryButtonCaption="Cancel"
        showPrimaryButton
        showSecondaryButton
      />
      {showAlert && (
        <div
          style={{
            position: "fixed",
            top: "155px",
            left: "610px",
            width: 890,
            height: 150,
            transform: "translate(-50%, -50%)",
            zIndex: 1000,
          }}
        >
          <Alert severity={alertSeverity}>
            <AlertTitle style={{ fontSize: "20px" }}>{alertTitle}</AlertTitle>
            <strong style={{ fontSize: "20px" }}>{alertDescription}</strong>
          </Alert>
        </div>
      )}
    </div>
  );
}

export default Complain;
