import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  useCallback,
} from "react";
import api from "../../Api.js";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Tooth from "../../control/Tooth.js";
import { Grid, Typography } from "@material-ui/core";
import { Select, MenuItem } from "@material-ui/core";
import { blue, blueGrey } from "@material-ui/core/colors";
import CrudController from "../../control/CrudController";
import Paper from "@material-ui/core/Paper";
import InputLabel from "@material-ui/core/InputLabel";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CustomCheckBoxTextField from "../../control/CustomCheckBoxTextField.js";
import dayjs from "dayjs";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import CustomMessageBox from "../../control/CustomMessageBox";
import DynamicSnackBox from "../../control/DynamicPopupSnackBar";

const AdvProsthesis = forwardRef(
  ({ refModule, updateComponentToRender }, ref) => {
    //#region variable section
    useImperativeHandle(ref, () => ({
      handleSearch,
      handleClear,
      save,
      edit,
    }));

    const [resetKey, setResetKey] = useState(0);
    // State to control clearing values in CrudController
    const [clearValues, setClearValues] = useState(false);

    const [, setRefClinicNo] = useState(null);
    const [refRctNo, setRefRctNo] = useState(null);

    const [buttonStates, setButtonStates] = useState({
      search: true,
      edit: true,
      save: true,
      clear: true,
      close: false,
      history: false,
      scan: true,
    });
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");
    const [alertDescription, setAlertDescription] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("");
    const [message, setMessage] = useState("");
    const [isMessageBoxOpen, setMessageBoxOpen] = useState(false);
    const [functionality, setFunctionality] = useState(null);

    const examTeethInitCommon = process.env.REACT_APP_NORMAL_TOOTH_SETTING;
    const [id, setId] = useState(null);
    const [clinicNo, setClinicNo] = useState("");
    const [, setOtherDocNo] = useState(null);
    const [aType, setAType] = useState("");
    const [forTeeth, setForTeeth] = useState(examTeethInitCommon);
    const [abutmentsTeeth, setAbutmentsTeeth] = useState(examTeethInitCommon);
    const [ponticsTeeth, setPonticsTeeth] = useState(examTeethInitCommon);
    const [insertion_dt, setInsertionDt] = useState(null);
    const [shadesIncisal, setShadesIncisal] = useState("");
    const [middle, setMiddle] = useState("");
    const [gingival, setGingival] = useState("");
    const [generalTint, setGeneralTint] = useState("");
    const [materials, setMaterials] = useState("");
    const [chkpre_prosthesis_dt, setChkPreProsthesisDt] = useState(false);
    const [pre_prosthesis_dt, setPreProsthesisDt] = useState(null);
    const [chkfixed_dt, setChkFixedDt] = useState(false);
    const [fixed_dt, setFixedDt] = useState(null);
    const [implant_detail, setImplantDetail] = useState("");
    const [createBy, setCreateBy] = useState("");
    const [updateBy, setUpdateBy] = useState("");

    //#endregion variable secrtion

    //#region Main Functions

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const clear = useCallback(async (isMsgShow) => {
      if (isMsgShow === true) {
        setClinicNo("");
      }

      setId(null);
      setOtherDocNo("");
      setAType("");
      setForTeeth(examTeethInitCommon);
      setAbutmentsTeeth(examTeethInitCommon);
      setPonticsTeeth(examTeethInitCommon);
      setInsertionDt(null);
      setShadesIncisal("");
      setMiddle("");
      setGingival("");
      setGeneralTint("");
      setMaterials("");
      setPreProsthesisDt(null);
      setChkPreProsthesisDt(false);
      setFixedDt(null);
      setChkFixedDt(false);
      setImplantDetail("");

      resetAButton();
      if (isMsgShow === true) handleClearValues();
      if (isMsgShow === true)
        triggerAlertOn("Clear", "Successfully cleaned!", "success");
    });

    const decideOtherDocumentId = (_treatmentId) => {
      if (refModule != null) if(refModule.refTreatmentId != null){
        // if existing treatment
        return refModule.refTreatmentId;
      } else if (
        (refModule != null &&
          refModule.refTreatmentId == null &&
          _treatmentId != null) ||
        (refModule == null &&
          refModule.refTreatmentId == null &&
          _treatmentId != null)
      ) {
        // if treatment is not exist, use saved id
        return _treatmentId;
      } else {
        // if both not in a value, that is pure ortho
        return null;
      }
    };

    const save = async (_lastId) => {
      try {
        api
          .post(`/advanced_prosthesis/save`, {
            clinic_no: clinicNo,
            visit_date: new Date(),
            other_doc_no: decideOtherDocumentId(_lastId),
            app_ref_no: null,
            type: aType,
            for_teeth: forTeeth === examTeethInitCommon ? null : forTeeth,
            insertion_dt: insertion_dt,
            abutments_teeth:
              abutmentsTeeth === examTeethInitCommon ? null : abutmentsTeeth,
            pontics_teeth:
              ponticsTeeth === examTeethInitCommon ? null : ponticsTeeth,
            shades_incisal: shadesIncisal,
            middle: middle,
            gingival: gingival,
            general_tint: generalTint,
            materials: materials,
            pre_prosthesis_dt:
              chkpre_prosthesis_dt === true ? pre_prosthesis_dt : null,
            fixed_dt: chkfixed_dt === true ? fixed_dt : null,
            implant_detail: implant_detail,
            com_code: sessionStorage.getItem("usercom"),
            create_dt: new Date(),
            create_by: createBy,
          })
          .then(() => {
            clear(true);
            triggerAlertOn("Save", "Successfully saved!", "success");
          })
          .catch((error) => {
            if (error.response.status === 400) {
              triggerAlertOn("Save", error.response.data.error, "error");
            } else {
              triggerAlertOn(
                "Save",
                "There is an error occured while save the record. The error is " +
                  error,
                "error"
              );
            }
          });
      } catch (error) {
        console.error(error);
        if (error)
          triggerAlertOn(
            "Save",
            "There is an error occured while save the record. The error is " +
              error,
            "error"
          );
      }
    };

    const edit = async () => {
      try {
        api
          .put(`/advanced_prosthesis/edit`, {
            id: id,
            clinic_no: clinicNo,
            other_doc_no: refModule != null ? refModule.refTreatmentId : null,
            app_ref_no: null,
            type: aType,
            for_teeth: forTeeth === examTeethInitCommon ? null : forTeeth,
            insertion_dt: insertion_dt,
            abutments_teeth:
              abutmentsTeeth === examTeethInitCommon ? null : abutmentsTeeth,
            pontics_teeth:
              ponticsTeeth === examTeethInitCommon ? null : ponticsTeeth,
            shades_incisal: shadesIncisal,
            middle: middle,
            gingival: gingival,
            general_tint: generalTint,
            materials: materials,
            pre_prosthesis_dt:
              chkpre_prosthesis_dt === true ? pre_prosthesis_dt : null,
            fixed_dt: chkfixed_dt === true ? fixed_dt : null,
            implant_detail: implant_detail,
            com_code: sessionStorage.getItem("usercom"),
            update_dt: new Date(),
            update_by: updateBy,
          })
          .then(() => {
            clear(true);
            triggerAlertOn("Edit", "Successfully edited!", "success");
            if (refModule != null) {
              handleClose();
            }
          })
          .catch((error) => {
            console.error(error);
            triggerAlertOn(
              "Edit",
              "There is an error occured while edit the record. The error is " +
                error,
              "error"
            );
          });
      } catch (error) {
        console.error(error);
        triggerAlertOn(
          "Edit",
          "There is an error occured while edit the record. The error is " +
            error,
          "error"
        );
      }
    };

    const validatePage = () => {
      // Check required fields
      if (!clinicNo) {
        return false;
      }
      // All checks passed, return true
      return true;
    };

    const handleSearch = async (_id) => {
      try {
        //console.log("clinicNo complains " + clinicNo);
        const response = await api.get(`/advanced_prosthesis/byId/${_id}`);
        const data = (await response).data.data;
        //console.log("complains",data[0])
        const clinicData = data.find((item) => item.id === _id);
        clear(false);
        if (clinicData) {
          setId(clinicData.id);
          setClinicNo(clinicData.clinic_no);
          setOtherDocNo(clinicData.other_doc_no);
          setAType(clinicData.type);
          setForTeeth(
            clinicData.for_teeth == null
              ? examTeethInitCommon
              : clinicData.for_teeth
          );
          setInsertionDt(clinicData.insertion_dt);
          setAbutmentsTeeth(
            clinicData.abutments_teeth == null
              ? examTeethInitCommon
              : clinicData.abutments_teeth
          );
          setPonticsTeeth(
            clinicData.pontics_teeth == null
              ? examTeethInitCommon
              : clinicData.pontics_teeth
          );
          setShadesIncisal(clinicData.shades_incisal);
          setMiddle(clinicData.middle);
          setGingival(clinicData.gingival);
          setGeneralTint(clinicData.general_tint);
          setMaterials(clinicData.materials);
          setPreProsthesisDt(clinicData.pre_prosthesis_dt);
          if (clinicData.pre_prosthesis_dt == null) {
            setChkPreProsthesisDt(false);
          } else {
            setChkPreProsthesisDt(true);
          }
          setFixedDt(clinicData.fixed_dt);
          if (clinicData.fixed_dt == null) {
            setChkFixedDt(false);
          } else {
            setChkFixedDt(true);
          }
          setImplantDetail(clinicData.implant_detail);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const handleSave = () => {
      if (id != null) {
        triggerAlertOn(
          "Save",
          "Process terminated. You cannot save this again, try edit! ",
          "error"
        );
        return;
      }

      if (validatePage()) {
        handleButtonClick("Save");
      } else {
        triggerAlertOn(
          "Save",
          "Process terminated. Please check all the required fields! ",
          "error"
        );
      }
    };

    const handleEdit = () => {
      if (id == null) {
        triggerAlertOn(
          "Edit",
          "Process terminated. Seems this is a new record!",
          "error"
        );
        return;
      }
      handleButtonClick("Edit");
    };

    const handleClear = (isNoMsg) => {
      if (isNoMsg === true) {
        clear(false);
      } else {
        handleButtonClick("Clear");
      }
    };

    const handleClose = () => {
      if (refModule != null) {
        setButtonStates({
          search: true,
          edit: true,
          save: true,
          clear: true,
          close: false,
          history: true,
          scan: true,
        });
        let refObj = {
          refModule: "TRE",
          refTreatmentId: null,
          refModuleId: null,
          refClinicId: clinicNo,
        };
        setOtherDocNo(null);
        setRefClinicNo(null);
        setRefRctNo(null);
        refModule = null;
        updateComponentToRender(refObj);
      }
    };

    const handleHistory = () => {
      // eslint-disable-next-line eqeqeq
      if (clinicNo != null && clinicNo != "") {
        handleOpenModal();
      } else {
        triggerAlertOn(
          "History",
          "There are no history records to show.",
          "error"
        );
        return;
      }
    };
    //#endregion Main Functions

    //#region Other Events

    const handleClinicNoChange = (newClinicNo) => {
      setClinicNo(newClinicNo);
      setId(null);
    };

    const handleToothForType = (myDictionary) => {
      setForTeeth(convertDictionaryToString(myDictionary));
    };

    const handleToothForAbutments = (myDictionary) => {
      setAbutmentsTeeth(convertDictionaryToString(myDictionary));
    };
    const handleToothForPontics = (myDictionary) => {
      setPonticsTeeth(convertDictionaryToString(myDictionary));
    };

    const handleShadesIncisal = (event) => {
      setShadesIncisal(event.target.value);
    };

    const handleMiddle = (event) => {
      setMiddle(event.target.value);
    };

    const handleGingival = (event) => {
      setGingival(event.target.value);
    };

    const handleGeneralTint = (event) => {
      setGeneralTint(event.target.value);
    };

    const handleMaterials = (event) => {
      setMaterials(event.target.value);
    };

    const handleChkPreProsthesisDt = (event) => {
      setChkPreProsthesisDt(event.target.checked);
      setPreProsthesisDt(null);
    };

    const handleChkFixedDt = (event) => {
      setChkFixedDt(event.target.checked);
      setFixedDt(null);
    };

    const handleImplantDetail = (event) => {
      setImplantDetail(event.target.value);
    };

    const clearInsertionDate = () => {
      setInsertionDt(null);
    };

    const clearPreProsthesisDate = () => {
      setPreProsthesisDt(null);
    };

    const clearFixDate = () => {
      setFixedDt(null);
    };

    useEffect(() => {
      if (refModule != null) {
        if (refModule.refModuleId != null) {
          setButtonStates({
            search: false,
            edit: true,
            save: false,
            clear: true,
            close: true,
            history: true,
            scan: true,
          });
        } else {
          setButtonStates({
            search: false,
            edit: false,
            save: true,
            clear: true,
            close: true,
            history: false,
          });
        }
        setClinicNo(refModule.refClinicId);
        setOtherDocNo(refModule.refTreatmentId);
        setRefClinicNo(refModule.refClinicId);
        setRefRctNo(refModule.refModuleId);
      } else {
        setButtonStates({
          search: true,
          edit: true,
          save: true,
          clear: true,
          close: false,
          history: true,
          scan: true,
        });
        setOtherDocNo(null);
        setRefClinicNo(null);
        setRefRctNo(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        refModule = null;
      }
    }, [refModule]);

    useEffect(() => {
      async function getAdvByID() {
        try {
          //console.log("clinicNo complains " + clinicNo);
          const response = await api.get(
            `/advanced_prosthesis/byId/${refRctNo}`
          );
          const data = (await response).data.data;
          const clinicData = data.find((item) => item.id === refRctNo);

          if (clinicData) {
            clear(false);

            setId(clinicData.id);
            setClinicNo(clinicData.clinic_no);
            setOtherDocNo(clinicData.other_doc_no);
            setAType(clinicData.type);
            setForTeeth(
              clinicData.for_teeth == null
                ? examTeethInitCommon
                : clinicData.for_teeth
            );
            setInsertionDt(clinicData.insertion_dt);
            setAbutmentsTeeth(
              clinicData.abutments_teeth == null
                ? examTeethInitCommon
                : clinicData.abutments_teeth
            );
            setPonticsTeeth(
              clinicData.pontics_teeth == null
                ? examTeethInitCommon
                : clinicData.pontics_teeth
            );
            setShadesIncisal(clinicData.shades_incisal);
            setMiddle(clinicData.middle);
            setGingival(clinicData.gingival);
            setGeneralTint(clinicData.general_tint);
            setMaterials(clinicData.materials);
            setPreProsthesisDt(clinicData.pre_prosthesis_dt);
            if (clinicData.pre_prosthesis_dt == null) {
              setChkPreProsthesisDt(false);
            } else {
              setChkPreProsthesisDt(true);
            }
            setFixedDt(clinicData.fixed_dt);
            if (clinicData.fixed_dt == null) {
              setChkFixedDt(false);
            } else {
              setChkFixedDt(true);
            }
            setImplantDetail(clinicData.implant_detail);
          } else {
            //todo:
          }
        } catch (error) {
          console.error(error);
        }
      }

      if (refRctNo != null) {
        getAdvByID();
      }
      if (refModule != null) {
        setOtherDocNo(refModule.refTreatmentId);
      }
    }, [refRctNo]);

    //#endregion Other Events

    //#region outside controller compartments

    const convertDictionaryToString = (dictionary) => {
      return Object.entries(dictionary)
        .map(([key, value]) => `${key}.${value}`)
        .join(",");
    };

    const handleButtonClick = (buttonId) => {
      if (buttonId === "Save") {
        setMessage("Do you really want to save this advanced prosthesis?");
      } else if (buttonId === "Edit") {
        setMessage("Do you really want to edit this advanced prosthesis?");
      } else if (buttonId === "Clear") {
        setMessage("Do you really want to clear this advanced prosthesis?");
      }
      setFunctionality(buttonId);
      setMessageBoxOpen(true);
    };

    const handleMessageBoxClose = (isPrimaryButtonClicked) => {
      if (isPrimaryButtonClicked && functionality === "Save") {
        save();
      }
      if (isPrimaryButtonClicked && functionality === "Clear") {
        clear(true);
      }

      if (isPrimaryButtonClicked && functionality === "Edit") {
        edit();
      }

      // Set isMessageBoxOpen to false to hide the message box
      setMessageBoxOpen(false);
    };

    const triggerAlertOn = async (title, description, severity) => {
      setAlertTitle(title);
      setAlertDescription(description);
      setAlertSeverity(severity);
      setShowAlert(true);

      setTimeout(() => {
        setAlertTitle("");
        setAlertDescription("");
        setAlertSeverity("");
        setShowAlert(false);
      }, 2000);
    };

    useEffect(() => {
      setCreateBy(sessionStorage.getItem("userid"));
      setUpdateBy(sessionStorage.getItem("userid"));
    }, []);

    const resetAButton = () => {
      // Update the key to remount component A and reset its state
      setResetKey((prevKey) => prevKey + 1);
    };

    const handleClearValues = () => {
      // Set clearValues to true to trigger the clearing in CrudController
      setClearValues(true);
    };

    const handleClearComplete = () => {
      // Callback function to reset clearValues in CrudController
      setClearValues(false);
    };

    //#endregion outside controller compartments
    const handleDataFetchStatus = (status) => {
      if (status === false) {
        triggerAlertOn(
          "Search",
          `This is an invalid clinic no. Please check the number and try again.`,
          "error"
        );
        setClinicNo("");
      }
    };

    const searchFields = [
      { label: "Visit #", value: "visit_sequence" },
      { label: "Date", value: "visit_date" },
      { label: "id", value: "id" },
    ];

    const endpointComponent = "advanced_prosthesis";
    const endPointRouter = "visit";

    const handleRowSingleClick = (id) => {
      //console.log("selected id " + id);
      setId(id);
      setOpenModal(false); // Close the modal after selecting a row
      handleSearch(id);
    };

    const handleOpenModal = () => {
      setOpenModal(true);
    };

    const handleCloseModal = () => {
      setOpenModal(false);
    };

    const [openModal, setOpenModal] = useState(false);

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: "16px",
          marginLeft: "16px",
        }}
      >
        <Grid container spacing={2}>
          {/* 1 Row */}
          <CrudController
            onSave={handleSave}
            onClear={handleClear}
            onEdit={handleEdit}
            onSearch={handleSearch}
            onClose={handleClose}
            onHistory={handleHistory}
            clinicNo={clinicNo}
            onClinicNoChange={handleClinicNoChange}
            buttonStates={buttonStates}
            isNameVisible={true}
            clearValues={clearValues}
            onClearComplete={handleClearComplete}
            onDataFetchStatus={handleDataFetchStatus}
            isClinicNoVisible={
              refModule == null || refModule == "undefined" ? true : false
            }
          />

          {/* 2 Row */}
          <Grid container spacing={2}>
            {/* cell 1 */}
            <Grid item xs={4}>
              <Paper
                elevation={0}
                style={{ padding: 5, textAlign: "left", paddingTop: 15 }}
              >
                <InputLabel id="type-label">Type</InputLabel>
                <Select
                  value={aType}
                  onChange={(e) => setAType(e.target.value)}
                  labelId="type-label"
                  displayEmpty
                  style={{ width: "310px" }}
                >
                  <MenuItem value="" disabled>
                    {" "}
                    --select--{" "}
                  </MenuItem>
                  <MenuItem value={"Jacket Crown"}>Jacket Crown</MenuItem>
                  <MenuItem value={"Conventional Bridge"}>
                    Conventional Bridge
                  </MenuItem>
                  <MenuItem value={"Cantilever Bridge"}>
                    Cantilever Bridge
                  </MenuItem>
                  <MenuItem value={"MINI Implant Retained Crown"}>
                    MINI Implant Retained Crown
                  </MenuItem>
                  <MenuItem value={"Conventional Implant Retained Crown"}>
                    Conventional Implant Retained Crown
                  </MenuItem>
                  <MenuItem value={"MINI Implant Retained Crown"}>
                    MINI Implant Retained Crown
                  </MenuItem>
                  <MenuItem value={"MINI Implant Retained Denture"}>
                    MINI Implant Retained Denture
                  </MenuItem>
                  <MenuItem value={"Porcelain Veneering"}>
                    Porcelain Veneering
                  </MenuItem>
                </Select>
              </Paper>
            </Grid>

            {/* cell 2 */}
            <Grid item xs={3}>
              <Paper
                elevation={0}
                style={{ padding: 5, textAlign: "left", paddingTop: 15 }}
              >
                <Tooth
                  initialButtonString={forTeeth}
                  key={resetKey}
                  onClickPatternChange={handleToothForType}
                />
              </Paper>
            </Grid>
          </Grid>

          {/* 3 Row */}
          <Typography variant="h6" style={{ paddingBottom: 5 ,fontWeight:550}}>
            Crown/Bridge Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Paper
                elevation={0}
                style={{ padding: 5, textAlign: "left", paddingBottom: 10 }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
                  <DatePicker
                    label="Preparation/Implant Insertion"
                    format={process.env.REACT_APP_DATE_FORMAT}
                    style={{ width: "170px" }}
                    value={insertion_dt != null ? dayjs(insertion_dt) : null}
                    onChange={(newValue) => setInsertionDt(newValue)}
                    clearable // Enable clearable option
                    onAccept={(newValue) => setInsertionDt(newValue)}
                    onClear={clearInsertionDate}
                  />
                </LocalizationProvider>
              </Paper>
            </Grid>
          </Grid>

          {/* 4 row */}
          <Grid container spacing={1}>
            <Grid item xs={5}>
              <Paper elevation={0} style={{ padding: 5, textAlign: "left" }}>
                <InputLabel id="title-label">Abutments @</InputLabel>
                <Tooth
                  initialButtonString={abutmentsTeeth}
                  key={resetKey}
                  onClickPatternChange={handleToothForAbutments}
                />
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper
                elevation={0}
                style={{ padding: 5, paddingLeft: 140, textAlign: "left" }}
              >
                <InputLabel id="title-label">Pontics @</InputLabel>
                <Tooth
                  initialButtonString={ponticsTeeth}
                  key={resetKey}
                  onClickPatternChange={handleToothForPontics}
                />
              </Paper>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper elevation={0} style={{ padding: 10, textAlign: "left" }}>
                <Grid container spacing={10}>
                  {/* cell 1 */}
                  <Grid item xs={3}>
                    <Paper
                      elevation={0}
                      style={{ padding: 5, textAlign: "left" }}
                    >
                      <InputLabel id="Shades-label">Shades-incisal</InputLabel>
                      <Select
                        value={shadesIncisal}
                        onChange={handleShadesIncisal}
                        labelId="Shades-label"
                        displayEmpty
                        style={{ width: "210px" }}
                      >
                        <MenuItem value="" disabled>
                          {" "}
                          --select--{" "}
                        </MenuItem>
                        <MenuItem value={"A1"}>A1</MenuItem>
                        <MenuItem value={"A2"}>A2</MenuItem>
                        <MenuItem value={"A3"}>A3</MenuItem>
                        <MenuItem value={"A3.5"}>A3.5</MenuItem>
                        <MenuItem value={"A4"}>A4</MenuItem>
                        <MenuItem value={"B1"}>B1</MenuItem>
                        <MenuItem value={"B2"}>B2</MenuItem>
                        <MenuItem value={"B3"}>B3</MenuItem>
                        <MenuItem value={"B4"}>B4</MenuItem>
                        <MenuItem value={"C1"}>C1</MenuItem>
                        <MenuItem value={"C2"}>C2</MenuItem>
                        <MenuItem value={"C3"}>C3</MenuItem>
                      </Select>
                    </Paper>
                  </Grid>
                  {/* cell 2 */}
                  <Grid item xs={3}>
                    <Paper
                      elevation={0}
                      style={{ padding: 5, textAlign: "left", paddingLeft: 18 }}
                    >
                      <InputLabel id="middle-label">Middle</InputLabel>
                      <Select
                        value={middle}
                        onChange={handleMiddle}
                        labelId="middle-label"
                        displayEmpty
                        style={{ width: "210px" }}
                      >
                        <MenuItem value="" disabled>
                          {" "}
                          --select--{" "}
                        </MenuItem>
                        <MenuItem value={"A1"}>A1</MenuItem>
                        <MenuItem value={"A2"}>A2</MenuItem>
                        <MenuItem value={"A3"}>A3</MenuItem>
                        <MenuItem value={"A3.5"}>A3.5</MenuItem>
                        <MenuItem value={"A4"}>A4</MenuItem>
                        <MenuItem value={"B1"}>B1</MenuItem>
                        <MenuItem value={"B2"}>B2</MenuItem>
                        <MenuItem value={"B3"}>B3</MenuItem>
                        <MenuItem value={"B4"}>B4</MenuItem>
                        <MenuItem value={"C1"}>C1</MenuItem>
                        <MenuItem value={"C2"}>C2</MenuItem>
                        <MenuItem value={"C3"}>C3</MenuItem>
                      </Select>
                    </Paper>
                  </Grid>
                  {/* cell 3 */}
                  <Grid item xs={3}>
                    <Paper
                      elevation={0}
                      style={{ padding: 5, textAlign: "left", paddingLeft: 44 }}
                    >
                      <InputLabel id="gingival-label">Gingival</InputLabel>
                      <Select
                        displayEmpty
                        value={gingival}
                        onChange={handleGingival}
                        labelId="gingival-label"
                        style={{ width: "210px" }}
                      >
                        <MenuItem value="" disabled>
                          {" "}
                          --select--{" "}
                        </MenuItem>
                        <MenuItem value={"A1"}>A1</MenuItem>
                        <MenuItem value={"A2"}>A2</MenuItem>
                        <MenuItem value={"A3"}>A3</MenuItem>
                        <MenuItem value={"A3.5"}>A3.5</MenuItem>
                        <MenuItem value={"A4"}>A4</MenuItem>
                        <MenuItem value={"B1"}>B1</MenuItem>
                        <MenuItem value={"B2"}>B2</MenuItem>
                        <MenuItem value={"B3"}>B3</MenuItem>
                        <MenuItem value={"B4"}>B4</MenuItem>
                        <MenuItem value={"C1"}>C1</MenuItem>
                        <MenuItem value={"C2"}>C2</MenuItem>
                        <MenuItem value={"C3"}>C3</MenuItem>
                      </Select>
                    </Paper>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper elevation={0} style={{ padding: 10, textAlign: "left" }}>
                <Grid container spacing={10}>
                  {/* cell 1 */}
                  <Grid item xs={3}>
                    <Paper
                      elevation={0}
                      style={{ padding: 5, textAlign: "left" }}
                    >
                      <InputLabel id="title-label">General Tint</InputLabel>
                      <Select
                        value={generalTint}
                        onChange={handleGeneralTint}
                        labelId="title-label"
                        displayEmpty
                        style={{ width: "210px" }}
                      >
                        <MenuItem value="" disabled>
                          {" "}
                          --select--{" "}
                        </MenuItem>
                        <MenuItem value={"Whitish"}>Whitish</MenuItem>
                        <MenuItem value={"Yellowish"}>Yellowish</MenuItem>
                        <MenuItem value={"Orangish"}>Orangish</MenuItem>
                        <MenuItem value={"Brownish"}>Brownish</MenuItem>
                        <MenuItem value={"Ash"}>Ash</MenuItem>
                      </Select>
                    </Paper>
                  </Grid>

                  {/* cell 2 */}
                  <Grid item xs={3}>
                    <Paper
                      elevation={0}
                      style={{ padding: 5, textAlign: "left", paddingLeft: 18 }}
                    >
                      <InputLabel id="materials-label">Materials</InputLabel>
                      <Select
                        value={materials}
                        onChange={handleMaterials}
                        labelId="materials-label"
                        displayEmpty
                        style={{ width: "210px" }}
                      >
                        <MenuItem value="" disabled>
                          {" "}
                          --select--{" "}
                        </MenuItem>
                        <MenuItem value={"Metal-Porcilain"}>
                          Metal-Porcilain
                        </MenuItem>
                        <MenuItem value={"e-Max"}>e-Max</MenuItem>
                        <MenuItem value={"Full Metal"}>Full Metal</MenuItem>
                        <MenuItem value={"Metal-Acrylic"}>
                          Metal-Acrylic
                        </MenuItem>
                        <MenuItem value={"Zerconium-Porcelain"}>
                          Zerconium-Porcelain
                        </MenuItem>
                      </Select>
                    </Paper>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper elevation={0} style={{ padding: 10, textAlign: "left" }}>
                <Grid container spacing={10}>
                  {/* cell 1 */}
                  <Grid item xs={5}>
                    <Paper
                      elevation={0}
                      style={{ padding: 5, textAlign: "left" }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="chkpre_prosthesis_dt"
                            checked={chkpre_prosthesis_dt}
                            onChange={handleChkPreProsthesisDt}
                            sx={{
                              color: blue[800],
                              "&.Mui-checked": {
                                color: blueGrey[600],
                              },
                            }}
                          />
                        }
                        label=""
                      />
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        locale="en"
                      >
                        <DatePicker
                          label="Temporary-pre prosthesis"
                          format={process.env.REACT_APP_DATE_FORMAT}
                          style={{ width: "170px" }}
                          value={
                            pre_prosthesis_dt != null
                              ? dayjs(pre_prosthesis_dt)
                              : null
                          }
                          onChange={(newValue) => setPreProsthesisDt(newValue)}
                          clearable // Enable clearable option
                          onAccept={(newValue) => setPreProsthesisDt(newValue)}
                          onClear={clearPreProsthesisDate}
                          disabled={!chkpre_prosthesis_dt}
                        />
                      </LocalizationProvider>
                    </Paper>
                  </Grid>

                  {/* cell 2 */}
                  <Grid item xs={5}>
                    <Paper
                      elevation={0}
                      style={{ padding: 5, textAlign: "left" }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="chkfixed_dt"
                            checked={chkfixed_dt}
                            onChange={handleChkFixedDt}
                            sx={{
                              color: blue[800],
                              "&.Mui-checked": {
                                color: blueGrey[600],
                              },
                            }}
                          />
                        }
                        label=""
                      />
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        locale="en"
                      >
                        <DatePicker
                          label="Fixed Date"
                          format={process.env.REACT_APP_DATE_FORMAT}
                          style={{ width: "170px" }}
                          value={fixed_dt != null ? dayjs(fixed_dt) : null}
                          onChange={(newValue) => setFixedDt(newValue)}
                          clearable // Enable clearable option
                          onAccept={(newValue) => setFixedDt(newValue)}
                          onClear={clearFixDate}
                          disabled={!chkfixed_dt}
                        />
                      </LocalizationProvider>
                    </Paper>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper elevation={0} style={{ padding: 10, textAlign: "left" }}>
                <Grid container spacing={10}>
                  {/* cell 1 */}
                  <Grid item xs={12}>
                    <Paper
                      elevation={0}
                      style={{ padding: 5, textAlign: "left" }}
                    >
                      <CustomCheckBoxTextField
                        label="Other Details For Implants"
                        type="text"
                        id="implant_detail"
                        width="885px"
                        height="55px"
                        value={implant_detail}
                        onChange={handleImplantDetail}
                      />
                    </Paper>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <CustomMessageBox
          open={isMessageBoxOpen}
          onClose={handleMessageBoxClose}
          title="Patient Registration"
          message={message}
          primaryButtonCaption="OK"
          secondaryButtonCaption="Cancel"
          showPrimaryButton
          showSecondaryButton
        />
        <DynamicSnackBox
          endpointComponent={endpointComponent}
          endPointRouter={endPointRouter}
          columns={searchFields}
          onRowDoubleClick={handleRowSingleClick}
          handleCloseModal={handleCloseModal}
          clinicNo={clinicNo}
          openModal={openModal} // Pass modalOpen state as open prop
          showCloseButton={false}
        />
        {showAlert && (
          <div
            style={{
              position: "fixed",
              top: "155px",
              left: "610px",
              width: 890,
              height: 150,
              transform: "translate(-50%, -50%)",
              zIndex: 1000,
            }}
          >
            <Alert severity={alertSeverity}>
              <AlertTitle style={{ fontSize: "20px" }}>{alertTitle}</AlertTitle>
              <strong style={{ fontSize: "20px" }}>{alertDescription}</strong>
            </Alert>
          </div>
        )}
      </div>
    );
  }
);

export default AdvProsthesis;
