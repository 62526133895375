import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import CustomTextField from "../control/CustomTextField";
import CrudController from "../control/CrudController";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import api from "../Api.js";
import dayjs from "dayjs";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import CustomMessageBox from "../control/CustomMessageBox";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import timezone from 'dayjs/plugin/timezone'; 
import utc from 'dayjs/plugin/utc'; 

dayjs.extend(utc); // Use UTC plugin
dayjs.extend(timezone); // Use Timezone plugin

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
  backgroundColor: "black",
  color: "yellow",
  borderRadius: "10px",
};

const localizer = momentLocalizer(moment);

const CustomEventComponent = ({ event, onPresent, onCancel }) => (
  <div style={{ backgroundColor: "black", padding: "2px", margin: 0  }}>
    <div style={{ display: "flex", alignItems: "center", margin: 0 }}>
      {/* Profile Picture */}
      <div>
        <img
          src={event.profilePic}
          alt="Profile"
          style={{ width: "40px", height: "40px", color:"red", borderRadius: "10%" }}
        />
      </div>
      
      {/* Event Time */}
      <div style={{ marginLeft: "15px" }}>
        <h4 style={{ color: 'white', margin: 0 }}>{event.startTime}</h4>
      </div>
    </div>
  </div>
);


const AppointmentModal = ({ isOpen, onClose, event, onPresent, onCancel }) => {
  if (!event) {
    return null;
  }

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={style}>
        <img
          src={event.profilePic}
          alt="Profile"
          style={{ width: "350px", height: "auto" }}
        />
        <h2>{event.title}</h2>
        <p>{event.reason}</p>
        <Button
          variant="contained"
          color="primary"
          onClick={onPresent}
          style={{ marginRight: "8px" }}
        >
          Present
        </Button>
        <Button variant="contained" color="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </Box>
    </Modal>
  );
};

function Appointment() {
  const [clinicNo, setClinicNo] = useState("");
  const [events, setEvents] = useState([]); // Newly added state
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedReason, setSelectedReason] = useState("");
  const [message, setMessage] = useState("");
  const [isMessageBoxOpen, setMessageBoxOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertDescription, setAlertDescription] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [functionality, setFunctionality] = useState(null);
  const [id, setId] = useState("");
  const [clearValues, setClearValues] = useState(false);

  const handleSave = () => {
    if (validatePage()) {
      handleButtonClick("Save");
    } else {
      triggerAlertOn(
        "Save",
        "Process terminated. Please check all the required fields! ",
        "error"
      );
    }
  };

  const handleClear = (isNoMsg) => {
    if (isNoMsg === true) {
      clear(false);
    } else {
      handleButtonClick("Clear");
    }
  };

  const handleEdit = () => {
    // Handle edit logic
  };

  const handleSearch = () => {};

  const handleClose = () => {
    // Handle search logic
  };

  const handleHistory = () => {
    // Handle search logic
  };

  const handleClinicNoChange = (newClinicNo) => {
    setClinicNo(newClinicNo);
  };

  const handleButtonClick = (buttonId) => {
    if (buttonId === "Save") {
      setMessage("Do you really want to save this appointment?");
    } else if (buttonId === "Edit") {
      setMessage("Do you really want to edit this appointment?");
    } else if (buttonId === "Clear") {
      setMessage("Do you really want to clear this appointment?");
    }
    setFunctionality(buttonId);
    setMessageBoxOpen(true);
  };

  const handleMessageBoxClose = (isPrimaryButtonClicked) => {
    if (isPrimaryButtonClicked && functionality === "Save") {
      save();
    }
    if (isPrimaryButtonClicked && functionality === "Event") {
      updateEvent();
    }
    if (isPrimaryButtonClicked && functionality === "Clear") {
      clear(true);
    }
    // Set isMessageBoxOpen to false to hide the message box
    setMessageBoxOpen(false);
  };

  const save = async () => {
    try {
      api
        .post(`/appointment/save`, {
          clinic_no: clinicNo,
          app_st_time: selectedDate,
          app_ed_time: dayjs(selectedDate).add(process.env.REACT_APP_APPOINTMENT_DURATION, 'hour') ,
          reason: selectedReason,
          create_by: sessionStorage.getItem("userid"),
          create_dt: new Date(),
        })
        .then((response) => {
          clear(true);
          futureEvents();
          triggerAlertOn("Save", "Successfully saved!", "success");
        })
        .catch((error) => {
          if (error.response.status === 400) {
            triggerAlertOn("Save", error.response.data.error, "error");
          } else {
            triggerAlertOn(
              "Save",
              "There is an error occured while save the record. The error is " +
                error,
              "error"
            );
          }
        });
    } catch (error) {
      console.error(error);
      triggerAlertOn(
        "Save",
        "There is an error occured while save the record. The error is " +
          error,
        "error"
      );
    }
  };

  const updateEvent = async (status) => {
    try {
      api
        .put(`/appointment/updatestatus`, {
          id: id,
          status: status,
          update_by: sessionStorage.getItem("userid"),
          update_dt: new Date(),
        })
        .then(() => {
          clear(true);
          futureEvents();
          triggerAlertOn(
            "Appointment Update",
            "Successfully updated!",
            "success"
          );
        })
        .catch((error) => {
          console.error(error);
          triggerAlertOn(
            "Appointment Update",
            "There is an error occured while save the record. The error is " +
              error,
            "error"
          );
        });
    } catch (error) {
      console.error(error);
      triggerAlertOn(
        "Appointment Update",
        "There is an error occured while save the record. The error is " +
          error,
        "error"
      );
    }
  };

  const clear = async (isMsgShow) => {
    setSelectedReason("");
    clearSelectedDate();
    if (isMsgShow === true) {
      setClinicNo("");
      handleClearValues();
    }
    if (isMsgShow === true)
      triggerAlertOn("Clear", "Successfully cleaned!", "success");
  };

  const triggerAlertOn = async (title, description, severity) => {
    setAlertTitle(title);
    setAlertDescription(description);
    setAlertSeverity(severity);
    setShowAlert(true);

    setTimeout(() => {
      setAlertTitle("");
      setAlertDescription("");
      setAlertSeverity("");
      setShowAlert(false);
    }, 2000);
  };

  const validatePage = () => {
    // Check required fields
    if (!clinicNo || !selectedDate || !selectedReason) {
      return false;
    }
    // All checks passed, return true
    return true;
  };

  const [buttonStates] = useState({
    search: true,
    edit: false,
    save: true,
    clear: true,
    close: false,
    history: false,
    scan: true,
  });

  const futureEvents = async () => {
    try {
      const response = await api.get(`/appointment/asattoday`);
      const dbData = response.data.data;
      const formattedAppointments = dbData.map((appointment) => {
        const title = `${appointment.title} ${appointment.f_name} ${appointment.m_name} ${appointment.l_name}`;

        const startDateTime = new Date(`${appointment.app_st_time}`); 
        const endDateTime = new Date(appointment.app_ed_time); 

        console.log('appointment.app_st_time :: ',appointment.app_st_time)
        console.log('appointment.app_st_time :: ',dayjs(appointment.app_st_time).tz("Asia/Colombo"))

        return {
          id: appointment.id,
          title: title,
          start: startDateTime,
          end: endDateTime,
          startTime:dayjs(appointment.app_st_time).format('HH:mm') ,
          profilePic:
            appointment.profile_pic != null
              ? process.env.REACT_APP_PATIENT_PROFILE +
                "/" +
                appointment.profile_pic.replace("\\", "/")
              : null, // Add the image path
          reason: appointment.reason,
        };
      });
      setEvents(formattedAppointments);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  useEffect(() => {}, [isMessageBoxOpen]);

  useEffect(() => {
    // Fetch events when the component mounts
    futureEvents();
  }, []);

  const handleDateChange = (date) => {
    const formattedDate = date;
    setSelectedDate(formattedDate);
  };

  const clearSelectedDate = () => {
    setSelectedDate(null);
  };

  const handleEventClick = (event, e) => {
    // Do something when an event is clicked
    setId(event.id);
    //setMessage("Is the patient present at the moment, do you really want to update this?");
    //setMessageBoxOpen(true);
    //setFunctionality("Event");
    setModalEvent(event);
    setModalOpen(true);
  };

  const handleClearComplete = () => {
    setClearValues(false);
  };

  const handleClearValues = () => {
    // Set clearValues to true to trigger the clearing in CrudController
    setClearValues(true);
  };

  const handleDataFetchStatus = (status) => {
    if (status === false) {
      triggerAlertOn(
        "Search",
        `This is an invalid clinic no. Please check the number and try again.`,
        "error"
      );
      setClinicNo("");
    }
  };

  const handleCancel = async (event) => {
    try {
      updateEvent("-1");
      triggerAlertOn("Cancel", "Appointment successfully canceled!", "success");
      futureEvents(); // Refresh events
      handleCloseModal();
    } catch (error) {
      console.error("Error canceling appointment:", error);
      triggerAlertOn(
        "Cancel",
        "Error canceling appointment: " + error.message,
        "error"
      );
    }
  };

  const handlePresent = async (event) => {
    try {
      updateEvent("1");
      triggerAlertOn("Present", "Patient marked as present!", "success");
      futureEvents(); // Refresh events
      handleCloseModal();
    } catch (error) {
      console.error("Error marking patient as present:", error);
      triggerAlertOn(
        "Present",
        "Error marking patient as present: " + error.message,
        "error"
      );
    }
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [modalEvent, setModalEvent] = useState(null);
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        marginTop: "16px",
        marginLeft: "16px",
      }}
    >
      <Grid container spacing={2}>
        {/* 1 Row */}
        <CrudController
          onSave={handleSave}
          onClear={handleClear}
          onEdit={handleEdit}
          onSearch={handleSearch}
          onClose={handleClose}
          onHistory={handleHistory}
          clinicNo={clinicNo}
          onClinicNoChange={handleClinicNoChange}
          buttonStates={buttonStates}
          isNameVisible={true}
          clearValues={clearValues}
          onClearComplete={handleClearComplete}
          onDataFetchStatus={handleDataFetchStatus}
          isClinicNoVisible={true}
        />

        {/* 2 Row */}
        <Grid container spacing={10}>
          {/* cell 1 */}
          <Grid item xs={10}>
            <Paper elevation={0} style={{ padding: 5, textAlign: "left" }}>
              <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 600, width: "995px", marginTop: "16px" }}
                min={new Date(2024, 0, 25, 8, 0)} // Minimum time, January 25, 2024, 8:00 AM
                max={new Date(2024, 0, 25, 20, 0)} // Maximum time, January 25, 2024, 8:00 PM
                components={{
                  event: (props) => (
                    <CustomEventComponent
                      {...props}
                      onPresent={handlePresent}
                      onCancel={handleCancel}
                    />
                  ),
                }}
                onSelectEvent={(event, e) => handleEventClick(event, e)}
              />
            </Paper>
          </Grid>
        </Grid>

        {/* 3 Row */}
        <Grid container spacing={10}>
          {/* cell 1 */}
          <Grid item xs={3}>
            <Paper
              elevation={0}
              style={{ padding: 5, textAlign: "left", width: 220 }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
                <DateTimePicker
                  label="Appointment Date"
                  format={process.env.REACT_APP_DATETIME_FORMAT}
                  value={selectedDate}
                  onChange={handleDateChange}
                  clearable // Enable clearable option
                  onAccept={handleDateChange}
                  onClear={clearSelectedDate}
                  ampm={false}
                />
              </LocalizationProvider>
            </Paper>
          </Grid>

          {/* cell 3 */}
          <Grid item xs={8}>
            <Paper elevation={0} style={{ padding: 5, textAlign: "left" }}>
              <CustomTextField
                label="Reason"
                type="text"
                id="reason"
                width="540px"
                value={selectedReason}
                onChange={(e) => setSelectedReason(e.target.value)}
              />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <CustomMessageBox
        open={isMessageBoxOpen}
        onClose={handleMessageBoxClose}
        title="Patient Registration"
        message={message}
        primaryButtonCaption="OK"
        secondaryButtonCaption="Cancel"
        showPrimaryButton
        showSecondaryButton
      />
      <AppointmentModal
        isOpen={modalOpen}
        onClose={handleCloseModal}
        event={modalEvent}
        onPresent={handlePresent}
        onCancel={handleCancel}
      />
      {showAlert && (
        <div
          style={{
            position: "fixed",
            top: "155px",
            left: "610px",
            width: 890,
            height: 150,
            transform: "translate(-50%, -50%)",
            zIndex: 1000,
          }}
        >
          <Alert severity={alertSeverity}>
            <AlertTitle style={{ fontSize: "20px" }}>{alertTitle}</AlertTitle>
            <strong style={{ fontSize: "20px" }}>{alertDescription}</strong>
          </Alert>
        </div>
      )}
    </div>
  );
}

export default Appointment;
