import React, { useState, useEffect } from 'react';
import { Button,  Menu } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import api from "../Api.js";

const useStyles = makeStyles({
  tableContainer: {
    maxWidth: 250,
    marginTop: '.5rem',
    marginBottom: '1rem',
    overflowX: 'auto',
    width: '200px',
    height: '100px',
    border: '1px solid #ccc',
  },
  table: {
    borderCollapse: 'collapse',
    width: '100%',
    '& thead tr th': {
      backgroundColor: '#1f2c2c',
      color: 'white',
      padding: '.3rem',
      textAlign: 'center',
    },
    '& tbody tr:nth-child(even)': {
      backgroundColor: '#9ee0de',
      padding: '.1rem',
    },
    '& tbody tr:nth-child(odd)': {
      backgroundColor: '#8ec9c7',
      padding: '.1rem',
    },
    '& tbody tr:hover': {
      backgroundColor: '#ffd202',
      color: 'black',
      padding: '.1rem',
    },
    '& td': {
      padding: '.1rem',
      height: '10px',
      textAlign: 'center',
    },
    '& th, & td': {
      width: '100px',
    },
    '& th:first-child, & td:first-child': {
      width: '75px',
    },
    '& th:nth-child(7), & td:nth-child(7)': {
      width: '100px',
    },
  },
  hiddenColumn: {
    display: 'none',
  },
});

const DynamicSnackBox = ({ endpointComponent, endPointRouter, columns, onRowDoubleClick, onClose, showCloseButton }) => {
  const classes = useStyles();
  const [searchResults, setSearchResults] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/${endpointComponent}/${endPointRouter}/1`);
        const dbData = response.data.data;
        setSearchResults(dbData);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, [endpointComponent, endPointRouter]);

  const handleRowDoubleClick = (clinicNo) => {
    onRowDoubleClick(clinicNo);
    handleCloseMenu();
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
      <div style={{ display: 'fixed', }} > 
          <Button variant="contained" color="primary" onClick={handleOpenMenu}>History</Button>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            PaperProps={{
              style: {
                maxHeight: '300px',
                width: '250px',
              },
            }}
          >
            <div className={classes.tableContainer}>
              {searchResults.length > 0 && (
                <div style={{ maxHeight: '100%', overflowY: 'auto' }}>
                  <table className={classes.table}>
                    <thead>
                      <tr>
                        {columns.map(col => (
                          <th key={col.value} className={col.value === 'id' ? classes.hiddenColumn : ''}>
                            {col.label}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {searchResults.map(result => (
                        <tr key={result.id} onClick={() => handleRowDoubleClick(result.id)}>
                          {columns.map(col => (
                            <td key={col.value} className={col.value === 'id' ? classes.hiddenColumn : ''}>
                              {result[col.value]}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
         </Menu>          
      </div>
  );
};

export default DynamicSnackBox;
