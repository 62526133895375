import React, { useState, useEffect } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import CustomTextField from "../control/CustomTextField.js";
import CustomMessageBox from "../control/CustomMessageBox.js";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import dayjs from "dayjs";
import api from "../Api.js";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const UserPassWordReset = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertDescription, setAlertDescription] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [isMessageBoxOpen, setMessageBoxOpen] = useState(false);
  const [functionality, setFunctionality] = useState(null);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const handleMessageBoxClose = (isPrimaryButtonClicked) => {
    // Set isMessageBoxOpen to false to hide the message box
    setMessageBoxOpen(false);
  };

  const handleUpdate = async () => {
    try {
      const response = await api.put(
        `/user/updatepassword/${sessionStorage.getItem(
          "userid"
        )}/${oldPassword}/${newPassword}`,
        {}
      );
      if (response.status == 200) {
        triggerAlertOn("Update", "Successfully updated!", "success");
      } else {
        triggerAlertOn(
          "Update",
          "There is an error occured while save the record. Please double check your old password "
        );
      }
    } catch (error) {
      triggerAlertOn(
        "Update",
        "There is an error occured while save the record. Please double check your old password " +
          error
      );
    }
  };

  const triggerAlertOn = async (title, description, severity) => {
    setAlertTitle(title);
    setAlertDescription(description);
    setAlertSeverity(severity);
    setShowAlert(true);

    setTimeout(() => {
      setAlertTitle("");
      setAlertDescription("");
      setAlertSeverity("");
      setShowAlert(false);
    }, 2000);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        marginTop: "16px",
        marginLeft: "16px",
      }}
    >
      <Grid container spacing={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper
              elevation={0}
              style={{ padding: 0, textAlign: "left", paddingLeft: 12 }}
            >
              <Typography
                variant="h6"
                style={{ paddingTop: 10, fontWeight: 1000 }}
              >
                {sessionStorage.getItem("username")}
              </Typography>
            </Paper>
          </Grid>

          <Paper elevation={0} style={{ padding: 10, textAlign: "left" }}>
            <Grid container spacing={10}>
              <Grid item xs={12}>
                <Paper
                  elevation={0}
                  style={{ padding: 0, textAlign: "left", paddingLeft: 10 }}
                >
                  <img
                    src={sessionStorage.getItem("profilepic")}
                    alt="Profile"
                    style={{ width: "500px", height: "auto" }} // Set the desired width
                  />
                </Paper>
              </Grid>
            </Grid>
          </Paper>

          <Paper elevation={0} style={{ padding: 10, textAlign: "left" }}>
            <Grid container spacing={10}>
              <Grid item xs={5}>
                <Paper elevation={0} style={{ padding: 0, textAlign: "left" }}>
                  <Grid item xs={12}>
                    <Paper
                      elevation={0}
                      style={{ padding: 0, textAlign: "left", paddingTop: 20 }}
                    >
                      <CustomTextField
                        label="Old Password"
                        type="text"
                        id="oldPassword"
                        width="250px"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                      />
                    </Paper>
                  </Grid>
                </Paper>
              </Grid>

              <Grid item xs={5}>
                <Paper elevation={0} style={{ padding: 0, textAlign: "left" }}>
                  <Grid item xs={12}>
                    <Paper
                      elevation={0}
                      style={{ padding: 0, textAlign: "left", paddingTop: 20 }}
                    >
                      <CustomTextField
                        label="New Password"
                        type="text"
                        id="oldPassword"
                        width="250px"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                    </Paper>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Paper elevation={0} style={{ padding: 0, textAlign: "left" }}>
                  <Grid item xs={12}>
                    <Paper
                      elevation={0}
                      style={{ padding: 0, textAlign: "left" }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleUpdate}
                      >
                        Update
                      </Button>
                    </Paper>
                  </Grid>
                </Paper>
              </Grid>

              <Grid item xs={3}>
                <Paper elevation={0} style={{ padding: 0, textAlign: "left" }}>
                  <Grid item xs={12}>
                    <Paper elevation={0} style={{ padding: 0 }}></Paper>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <CustomMessageBox
        open={isMessageBoxOpen}
        onClose={handleMessageBoxClose}
        title="Patient Registration"
        message={message}
        primaryButtonCaption="OK"
        secondaryButtonCaption="Cancel"
        showPrimaryButton
        showSecondaryButton
      />
      {showAlert && (
        <div
          style={{
            position: "fixed",
            top: "155px",
            left: "610px",
            width: 890,
            height: 150,
            transform: "translate(-50%, -50%)",
            zIndex: 1000,
          }}
        >
          <Alert severity={alertSeverity}>
            <AlertTitle style={{ fontSize: "20px" }}>{alertTitle}</AlertTitle>
            <strong style={{ fontSize: "20px" }}>{alertDescription}</strong>
          </Alert>
        </div>
      )}
    </div>
  );
};

export default UserPassWordReset;
