import React, { useState, useRef, useEffect } from 'react';
import {Card,CardContent,CardMedia,IconButton,} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useDropzone } from 'react-dropzone';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import AddCircleOutlineSharpIcon from '@material-ui/icons/AddCircleOutlineSharp';

const ImageUploader = ({ props, onImageChange },ref) => {
  const [image, setImage] = useState(null);
  const [stream, setStream] = useState(null);
  const videoRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [streaming, setStreaming] = useState(false);
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);
 

  const startCamera = async () => {
    try {
						 
      const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true });
      setStream(mediaStream);

      if (videoRef.current) {
        videoRef.current.srcObject = mediaStream;
      }

      mediaRecorderRef.current = new MediaRecorder(mediaStream);
      mediaRecorderRef.current.ondataavailable = handleDataAvailable;

    } catch (error) {
      console.error('Error accessing camera:', error);
    }
  };

  const handleDataAvailable = (event) => {
    if (event.data.size > 0) {
      chunksRef.current.push(event.data);
    }
  };

  const stopCamera = () => {
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach(track => track.stop());
      setStream(null);
    }
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
        mediaRecorderRef.current.stop();
        videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
   
      }
    setStreaming(false);
  };

  const captureImage = () => {
    setCapturing(true);
    const canvas = document.createElement('canvas');
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    canvas.getContext('2d').drawImage(videoRef.current, 0, 0);

    const imageUrl = canvas.toDataURL('image/png');
    //console.log('imageUrl : ' + imageUrl);
    setImage(imageUrl);
    stopCamera();
    setStreaming(false);
    onImageChange(image);
    
  };

  const onDrop = (acceptedFiles, event) => {
    if (streaming) {
      // Prevent default behavior to disable the dialog box
      event.preventDefault();
      return;
    }
  
    const file = acceptedFiles[0];
    const reader = new FileReader();
  
    reader.onload = () => {
      setImage(reader.result);
    };
  
    reader.readAsDataURL(file);
   
  };
  

  const deleteImage = () => {
    setImage(null);
    onImageChange(null);
    setCapturing(false);
  };

  const clearImage = () => {
    setImage(null);
    onImageChange(null);
    setCapturing(false);
  };

  const setDbImage = (image) => {
    setImage(image);
    onImageChange(image);
  };

    // Attach the ref to the component
    React.useImperativeHandle(ref, () => ({
      clearImage,setDbImage
    }));

  const uploadImage = () => {
    onImageChange(image);
    setCapturing(false);
  };

  const openCamera = () => {
    if(streaming) {
        setStreaming(false)
        setCapturing(true);
        captureImage()
    } else {
        setStreaming(true)
        setCapturing(false);    
        startCamera();  
    }
  };

  useEffect(() => { 
    onImageChange(image);
    setImage(image);
  }, [image]);

 

  const { getRootProps, getInputProps } = useDropzone({onDrop, noClick: streaming, // Disable click handling if streaming is active
  });

  return (
    <Card
      style={{
        outline: 'none',
        boxShadow: 'none',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'grab' , 
      }}
    >
        <div {...getRootProps()} style={{ marginLeft: '180px' }}>
            <input {...getInputProps()} />
        
            {streaming ? (
            <video ref={videoRef} autoPlay style={{ width: '340px'}} />
            ) :

            image ? (
            <CardMedia
                component="img"
                alt="Uploaded Image"
                image={image}
                height={285 || props.height}
                style={{ maxWidth: '210px' }}
            />
            ) : (
            <CardContent style={{ display: 'flex', alignItems: 'center', height: '150px', paddingLeft: 75 }}>
                {/* CloudUploadIcon */}
                <IconButton id='CloudUploadButton' color="primary" style={{ padding: 0, margin: 0 }} onClick={uploadImage}>
                <CloudUploadIcon fontSize="large" />
                </IconButton>
            </CardContent>
        )}

        </div>

        <CardContent style={{ display: 'flex', alignItems: 'center', height: '150px', paddingLeft: 15 }}>
            {/* Camera Icon */}
            <IconButton id='CameraUploadButton' color="primary" style={{ padding: 0, margin: 0 }} onClick={openCamera}>
                {streaming ? (<AddCircleOutlineSharpIcon fontSize="large" /> ) : (<PhotoCameraIcon fontSize="large" />)}
            </IconButton>
        </CardContent>

        {
            image && (
                <CardContent
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start', // Align buttons to the left
                        padding: 1
                    }} >
                    <IconButton color="secondary" style={{ padding: 0, margin: 0 }} onClick={deleteImage}>
                        <DeleteIcon  fontSize="large"/>
                    </IconButton>
                </CardContent>
       
       )}
      

    </Card>
  );
};

export default React.forwardRef(ImageUploader);
