import React, { useState, useEffect, useCallback } from "react";
import { Button, Grid, makeStyles } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import AdvProsthesis from "../patient/treatment/AdvProsthesis";
import PatientRegister from "../patient/Register";
import Complains from "../patient/diagnosis/Complain";
import Appointment from "../patient/Appointment";
import RemProsthesis from "../patient/treatment/RemProsthesis";
import Rct from "../patient/treatment/Rct";
import Orthodontic from "../patient/treatment/Orthodontic";
import Treatments from "../patient/treatment/Treatments";
import ImageMap from "./ImageMap";
import LiveTimeLine from "../control/LiveTimeLine";
import Gallery from "../patient/Gallery";
import UserCreation from "../user/UserCreation";
import Logo from "../control/logo";
import api from "../Api";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  column: {
    height: "100%",
    padding: theme.spacing(1),
  },
  expandableColumn: {
    minWidth: "150px",
  },
  loginContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  loginInput: {
    marginBottom: theme.spacing(2),
  },
  toggleButton: {
    width: "100%",
  },
  fab: {
    position: "flex",
    top: theme.spacing(2),
    left: theme.spacing(1),
  },
  userDetails: {
    display: "flex",
    alignItems: "center", // Align items vertically
    marginRight: theme.spacing(2),
  },
  profileIcon: {
    display: "flex",
    alignItems: "center",
  },
  selectedButton: {
    background: theme.palette.secondary.main,
  },
}));

function MasterLeftNavigation(props) {
  const classes = useStyles();
  const [, setComponentToRender] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedButton, setSelectedButton] = useState(null);
  const [permissions1, setPermission] = useState([]);
  const [refModule, setRefModule] = useState(null);

  useEffect(() => {
    //setPermission(sessionStorage.getItem('userpermission').split(','));

    async function fetchData() {
      // Fetch user permissions
      const response1 = await api.get(
        `/user/permission/${sessionStorage.getItem("userid")}`
      );
      const fetchedPermissionsString = response1.data.data
        .map((permission) => permission.permission)
        .join(",");
      const permissions = fetchedPermissionsString.split(",");

      if (permissions) {
        sessionStorage.setItem("userpermission", permissions);
        setPermission(permissions);
        handleClick("LOG");
      } else {
        throw new Error("Permission data not found");
      }
    }

    fetchData();
  }, []);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClick = useCallback((type) => {
    setRefModule(null);
    setComponentToRender(getComponent(type));
    props.ClickMenuButton(getComponent(type));
    setSelectedButton(type);
  });

  const getComponent = (type) => {
    switch (type) {
      case "ADP":
        return (
          <AdvProsthesis
            refModule={refModule}
            updateComponentToRender={updateComponentToRender}
          />
        );
      case "REG":
        return <PatientRegister />;
      case "COM":
        return <Complains />;
      case "TIME":
        return <LiveTimeLine />;
      case "APP":
        return <Appointment />;
      case "REP":
        return (
          <RemProsthesis
            refModule={refModule}
            updateComponentToRender={updateComponentToRender}
          />
        );
      case "RCT":
        return (
          <Rct
            refModule={refModule}
            updateComponentToRender={updateComponentToRender}
          />
        );
      case "ORT":
        return <Orthodontic />;
      //return <Orthodontic refModule={refModule} updateComponentToRender={updateComponentToRender} />;
      case "TRE":
        return <Treatments updateComponentToRender={updateComponentToRender} />;
      case "DAS":
        return <LiveTimeLine />;
      case "STA":
        return <ImageMap />;
      case "GAL":
        return <Gallery />;
      case "ADM":
        return <UserCreation />;
      case "LOG":
        return <Logo />;
      default:
        return null;
    }
  };

  const updateComponentToRender = (e) => {
    // Set your condition or logic herex
    // if (e != null) {
    //   setRefModule(e);
    // } else {
    //   setRefModule(null);
    //   handleClick(null);
    // }
  };

  useEffect(() => {
    if (refModule != null) {
      handleClick(refModule.refModule);
    }
  }, [refModule]);

  return (
    <div>
      <Grid
        item
        xs={isExpanded ? 0 : 8}
        className={classes.column}
        style={{
          background: "linear-gradient(to bottom, #FFFFFF, #C0C0C0)",
          width: 175,
        }}
      >
        <Fab color="secondary" onClick={toggleExpand} className={classes.fab}>
          <AddIcon />
        </Fab>
        <Grid container direction="column" style={{ paddingTop: "22px" }}>
          {permissions1.map((type) => (
            <Grid
              item
              key={type}
              onClick={() => handleClick(type)}
              style={{ padding: "3px" }}
            >
              <Button
                variant="contained"
                color={selectedButton === type ? "secondary" : "primary"}
                className={`${classes.toggleButton} ${
                  selectedButton === type ? classes.selectedButton : ""
                }`}
              >
                {type === "COM"
                  ? "Complains"
                  : type === "REG"
                  ? "Register"
                  : type === "APP"
                  ? "Appointments"
                  : type === "ADP"
                  ? "Advance Prosthesis"
                  : type === "REP"
                  ? "Removabale Prosthesis"
                  : type === "RCT"
                  ? "RCT"
                  : type === "ORT"
                  ? "Orthodontic"
                  : type === "TRE"
                  ? "Treatments"
                  : type === "DAS"
                  ? "Dashboard"
                  : type === "STA"
                  ? "Statistics"
                  : type === "GAL"
                  ? "Gallery"
                  : type === "ADM"
                  ? "Administration"
                  : type === "LOG"
                  ? "Logo"
                  : type}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </div>
  );
}

export default MasterLeftNavigation;
