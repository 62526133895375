import React, { useEffect, useState } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, LabelList,AreaChart, Area } from 'recharts';
import api from '../Api';

const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', 'red', 'pink'];

const getPath = (x, y, width, height) => {
  return `M${x},${y + height}C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3}
  ${x + width / 2}, ${y}
  C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${y + height}
  Z`;
};

const TriangleBar = (props) => {
  const { fill, x, y, width, height } = props;

  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};

const GenderWise = () => {
  const [genderData, setGenderData] = useState([]); // Initialize as an empty array
  const [ageData, setAgeData] = useState([]); // Initialize as an empty array
  const [cityData, seCityData] = useState([]); // Initialize as an empty array
  const [registrationOverTimeData, setRegistrationOverTimeData] = useState([]); // Initialize as an empty array
  const [loading, setLoading] = useState(true); // State for loading
  const [selectedGender, setSelectedGender] = useState(null); // Track selected gender for dynamic chart

  const GetGenderData = async () => {
    try {
      const res = await api.get(`/patient/genderdistribution/PS`);
      console.log(res.data); // Log the response data
      setGenderData(res.data.data); // Set data from response
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };

   const GetAgeData = async () => {
    try {
      const res = await api.get(`/patient/agedistribution/PS`);
      console.log(res.data); // Log the response data
      setAgeData(res.data.data); // Set data from response
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  }; 

  const GetCityData = async () => {
    try {
      const res = await api.get(`/patient/citydistribution/PS`);
      console.log(res.data); // Log the response data
      seCityData(res.data.data); // Set data from response
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };

  const GetRegistrationOverTimeData = async () => {
    try {
      const res = await api.get(`/patient/registrationovertime/PS`);
      console.log(res.data); // Log the response data
      setRegistrationOverTimeData(res.data.data); // Set data from response
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };
  

  useEffect(() => {
    GetGenderData();
    GetAgeData();
    GetCityData();
    GetRegistrationOverTimeData();
  }, []);

  // Function to handle bar clicks and load a specific chart based on clicked bar
  const handleBarClick = (data) => {
    console.log(`Bar clicked:`, data.name);
    setSelectedGender(data.name); // Track the clicked gender to load a different chart
  };

  // Show loading text or a loading spinner while fetching data
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h2>Gender Distribution</h2>
      <BarChart
        width={500}
        height={300}
        data={genderData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 30,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" tick={{ dy: 10 }} />
        <YAxis />
        <Tooltip />
        <Bar
          dataKey="count"
          fill="#8884d8"
          shape={<TriangleBar />}
          animationDuration={1000} // Animation duration for the bars
          onClick={handleBarClick} // Add onClick handler for the whole bar
        >
          <LabelList dataKey="count" position="top" offset={10} fill="#000" />
          {genderData.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={colors[index % colors.length]}
              onClick={() => handleBarClick(entry)} // You can add the click handler here for individual bars
              animationBegin={0} // Start animation immediately
              animationDuration={1000} // Animation duration for individual cells
            />
          ))}
        </Bar>
      </BarChart>

      <h2>Age Distribution</h2>
      <BarChart
        width={500}
        height={300}
        data={ageData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 30,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" tick={{ dy: 10 }} />
        <YAxis />
        <Tooltip />
        <Bar
          dataKey="count"
          fill="#8884d8"
          shape={<TriangleBar />}
          animationDuration={1000} // Animation duration for the bars
          onClick={handleBarClick} // Add onClick handler for the whole bar
        >
          <LabelList dataKey="count" position="top" offset={10} fill="#000" />
          {ageData.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={colors[index % colors.length]}
              onClick={() => handleBarClick(entry)} // You can add the click handler here for individual bars
              animationBegin={0} // Start animation immediately
              animationDuration={1000} // Animation duration for individual cells
            />
          ))}
        </Bar>
      </BarChart>

      <h2>City Distribution</h2>
      <BarChart
        width={500}
        height={300}
        data={cityData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 30,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" tick={{ dy: 10 }} />
        <YAxis />
        <Tooltip />
        <Bar
          dataKey="count"
          fill="#8884d8"
          shape={<TriangleBar />}
          animationDuration={1000} // Animation duration for the bars
          onClick={handleBarClick} // Add onClick handler for the whole bar
        >
          <LabelList dataKey="count" position="top" offset={10} fill="#000" />
          {cityData.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={colors[index % colors.length]}
              onClick={() => handleBarClick(entry)} // You can add the click handler here for individual bars
              animationBegin={0} // Start animation immediately
              animationDuration={1000} // Animation duration for individual cells
            />
          ))}
        </Bar>
      </BarChart>

      <h2>Patient Registration Over Time</h2>
      <AreaChart
        width={500}
        height={300}
        data={registrationOverTimeData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 30,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" tick={{ dy: 10 }} />
        <YAxis />
        <Tooltip />
        <Area
          type="monotone" // Smooth curve line
          dataKey="count"
          stroke="#8884d8"
          fill="#8884d8"
          animationDuration={1000} // Animation duration for the area
          onClick={handleBarClick} // You can still use the same click handler
        >
          <LabelList dataKey="count" position="top" offset={10} fill="#000" />
          {registrationOverTimeData.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={colors[index % colors.length]}
              onClick={() => handleBarClick(entry)} // Handle individual area clicks
              animationBegin={0} // Start animation immediately
              animationDuration={1000} // Animation duration for individual cells
            />
          ))}
        </Area>
      </AreaChart>


      {/* Render different chart based on selected gender */}
      {selectedGender && (
        <div>
          <h3>Detailed Chart for {selectedGender}</h3>
          {/* Load a different chart based on the clicked bar */}
          {selectedGender === 'Male' ? (
            <div> {/* Replace this with your actual chart for Male */}
              <p>Loading Male Chart...</p>
            </div>
          ) : selectedGender === 'Female' ? (
            <div> {/* Replace this with your actual chart for Female */}
              <p>Loading Female Chart...</p>
            </div>
          ) : (
            <div> {/* Default chart for other genders */}
              <p>Loading Other Chart...</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default GenderWise;
