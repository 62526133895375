import React, { useState, useEffect } from "react";
import { Grid, Paper, Button, Modal, Typography } from "@material-ui/core";
//import { Search } from '@material-ui/icons';
import CustomTextField from "../control/CustomTextField";
import ParentSearch from "../control/PatientSearch";
import api from "../Api";
//import { isDefAndNotNull } from '../utils';
import QRCodeGenerator from "./QRCodeGenerator";
import QRCodeScanner from "./QRCodeScanner"

function ClinicNumberInput({ value, onChange, onBlur, isDisabled }) {
  return (
    <CustomTextField
      label="Clinic No"
      type="text"
      id="clinicNo"
      value={value}
      width="150px"
      height="55px"
      onChange={(e) => onChange(e.target.value)}
      onBlur={(e) => onBlur(e.target.value)}
      disabled={isDisabled}
    />
  );
}

function getShortName(title, firstName, middleName, lastName) {
  // Extract initials from first and middle names
  const firstInitial = firstName != null ? firstName.charAt(0) : "";
  const middleInitial =
    middleName != null
      ? middleName.length > 1
        ? middleName.charAt(0)
        : middleName
      : "";

  // Handle case where lastName is null or empty
  const formattedLastName = lastName ? `${lastName}` : "";

  // Concatenate the initials and the formatted last name to create the short name
  const shortName = `${title} ${firstInitial ? `${firstInitial}.` : ""}${
    middleInitial ? `${middleInitial}.` : ""
  } ${formattedLastName}`.trim();

  return shortName;
}

function calculateAge(birthdate) {
  // Create a Date object from the birthdate string
  const birthDateObj = new Date(birthdate);

  // Get the current date
  const currentDate = new Date();

  // Calculate the difference in years
  const age = currentDate.getFullYear() - birthDateObj.getFullYear();

  // Adjust the age based on the birthdate and current date
  if (
    currentDate.getMonth() < birthDateObj.getMonth() ||
    (currentDate.getMonth() === birthDateObj.getMonth() &&
      currentDate.getDate() < birthDateObj.getDate())
  ) {
    // If the birthdate hasn't occurred yet in the current year, subtract 1 from the age
    return age - 1;
  }

  return age;
}

function formatDate(dateString) {
  const options = { day: "2-digit", month: "short", year: "numeric" };
  const formattedDate = new Date(dateString).toLocaleDateString(
    "en-US",
    options
  );

  // Convert to uppercase and replace short month with title case
  return formattedDate.replace(
    /(\w+)\s(\w+)\s(\d+)/,
    (_, m, d, y) => `${d}-${m}-${y}`
  );
}

function CrudController({
  onSave,
  onClear,
  onEdit,
  onSearch,
  onClose,
  onHistory,
  onPrint,
  clinicNo,
  onClinicNoChange,
  isNameVisible,
  buttonStates,
  clearValues,
  onClearComplete,
  onDataFetchStatus,
  isDoSearch,
  isClinicNoDisable = false,
  isClinicNoVisible,
  isQRVisibale = false,
}) {
  const [showSearchModule, setShowSearchModule] = useState(false);
  const [shortName, setShortName] = useState("");
  const [age, setAge] = useState(0);
  const [regDate, setRegDate] = useState("");
  const [isDoubleClickedge, setIsDoubleClicked] = useState(false);  
  const [isScanning, setIsScanning] = useState(false);
  const [qrCode, setQRCode] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);


  const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const contentStyle = {
    background: 'white',
    padding: '20px',
    borderRadius: '8px',
    width: '400px',
    position: 'relative',
  };

  const closeButtonStyle = {
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    position: 'absolute',
    top: '10px',
    right: '10px',
  };

  const onQRScan = () => {
    setIsScanning(true);
    
    // Add a 9-second delay before performing any action after scanning starts
    setTimeout(() => {
      // You can put any additional logic here if needed after 9 seconds
    }, 9000); // 9000 milliseconds = 9 seconds
  };

  const handleQRCodeDetected = (detectedQRCode) => {
    if (detectedQRCode != null && (typeof detectedQRCode === 'string' || typeof detectedQRCode === 'number')) {setQRCode(detectedQRCode);} // Set detected QR code
    handleCloseModal();
    console.log('QR--->',detectedQRCode)
  };
  


  useEffect(() => {
    if(qrCode) {
      handleRowDoubleClick(qrCode);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qrCode]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setQRCode('');
    onQRScan();
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsScanning(false); // Reset scanning state when closing
  };

  useEffect(() => {
    if(isDoubleClickedge) {
      handleCustomTextFieldOnBlur(clinicNo);  
      setIsDoubleClicked(false);    
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDoubleClickedge]);

  const handleNameSearch = async (clinicNo) => {
    if (clinicNo === "" || clinicNo === "undefined" || clinicNo === null)
      return;

    try {
      const nextval = 1;
      var response;
      response = await api.get(`/patient/byclinicno/${clinicNo}/${nextval}`);
      const data = response.data.data;
      //console.log("dta",data[0])
      //console.log("clinicNo",typeof clinicNo)
      // eslint-disable-next-line eqeqeq
      const clinicData = data.find(
        // eslint-disable-next-line eqeqeq
        (item) => item.clinic_no.toString() == clinicNo
      );
      if (clinicData) {
        //console.log(clinicData);
        setShortName(
          getShortName(
            clinicData.title,
            clinicData.f_name,
            clinicData.m_name,
            clinicData.l_name
          )
        );
        if (clinicData.bday === null) {
          setAge(-1);
        } else {
          setAge(calculateAge(clinicData.bday));
        }
        setRegDate(formatDate(clinicData.reg_date));
        onDataFetchStatus(true); // Notify the parent that data fetch was successful
      } else {
        onDataFetchStatus(false);
        setShortName("");
        setAge(0);
        setRegDate("");
      }
    } catch (error) {
      console.info("No data found");
    }
  };

  const handleRowDoubleClick = async (clinicNo) => {
    await handleNameSearch(clinicNo);
    setShowSearchModule(false);
    onClear(true);
    onClinicNoChange(clinicNo);
    setIsDoubleClicked(true);
  };

  const handleSearchModalClose = () => {
    //onClinicNoChange('');
    setShowSearchModule(false);
  };

  // eslint-disable-next-line no-lone-blocks
  {
    /* 
    
    const handleNameSeaching=() => {
    handleRowDoubleClick(clinicNo);
    onSearch();
  } 
    */
  }

  useEffect(() => {
    if (clearValues) {
      setShortName("");
      setAge(0);
      setRegDate("");
      // Call the onClearComplete callback to signal that clearing is complete
      onClearComplete();
    }
  }, [clearValues, onClearComplete]);

  const handleCustomTextFieldOnBlur = (event) => {
    if (clinicNo !== null || clinicNo !== "undefined" || clinicNo !== "") {
      onClinicNoChange(event);
      handleNameSearch(event);
      //onSearch(); this will get the latest record.
      onClear(true);
      if (event === "" || event === null) {
        setShortName("");
        setAge(0);
        setRegDate("");
      }
      if (isDoSearch === true) onSearch();
    }
  };

  return (
    <Grid container >
      {isClinicNoVisible && (
        <>
          <Grid item xs={3}>
            <Paper elevation={0} style={{ padding: 5, textAlign: "left" }}>
              {isClinicNoDisable === false && (
                <ClinicNumberInput
                  value={clinicNo}
                  onChange={onClinicNoChange}
                  onBlur={handleCustomTextFieldOnBlur}
                  isDisabled={isClinicNoDisable}
                />
              )}

              {/*<SearchButton onClick={handleNameSeaching}  /> */}
              <Modal
                open={showSearchModule}
                onClose={() => handleSearchModalClose}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#ccedf0",
                      padding: "1.2rem",
                      width: "100%",
                      maxWidth: "850px",
                      height: "50%",
                      maxHeight: "600px",
                      borderRadius: "40px",
                      boxShadow: "1px 1px 20px 5px rgba(0,0,0,0.8)",
                      overflow: "auto",
                    }}
                  >
                    <ParentSearch
                      onRowDoubleClick={handleRowDoubleClick}
                      onClose={handleSearchModalClose}
                    />
                  </div>
                </div>
              </Modal>
            </Paper>
          </Grid>

          <Grid item xs={2}>
            <Paper elevation={0} style={{  textAlign: "left" }}>
              {isQRVisibale === true && (
                <QRCodeGenerator value={clinicNo} />
              )}
            </Paper>
          </Grid>

          <Grid item xs={7}>
            <Paper elevation={0} style={{ paddingTop: 1, textAlign: "left" }}>
              <div>
                <div style={{ marginBottom: "5px" }}>
                  {/* Use buttonStates to conditionally render and enable/disable buttons */}

                  &nbsp;
                  &nbsp;
                  {buttonStates.scan && (
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "#0098be", color: "white" }}
                      onClick={handleOpenModal} 
                      disabled={isScanning}
                    >
                       {isScanning ? 'Scanning' : 'Scan'}
                    </Button>
                  )}
                  &nbsp;
                  {buttonStates.save && (
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "#ED0800", color: "white" }}
                      onClick={onSave}
                    >
                      Save
                    </Button>
                  )}
                  &nbsp;
                  {buttonStates.edit && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={onEdit}
                    >
                      Edit
                    </Button>
                  )}
                  &nbsp;
                  {buttonStates.clear && (
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "#ffbd03" }}
                      onClick={onClear}
                    >
                      Clear
                    </Button>
                  )}
                  &nbsp;
                  {buttonStates.close && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onClose}
                    >
                      Close
                    </Button>
                  )}                  
                  {buttonStates.search && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setShowSearchModule(true)}
                    >
                      Search
                    </Button>
                  )}       
                  &nbsp;
                  {buttonStates.history && (
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "#0A0800", color: "white" }}
                      onClick={onHistory}
                    >
                      History
                    </Button>
                  )}
                  &nbsp;
                  {buttonStates.print && (
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "#0A0800", color: "white" }}
                      onClick={onPrint}
                    >
                      Print
                    </Button>
                  )}

                </div>
                {isNameVisible === true ? (
                  <Typography style={{ color: "#1976D2", fontSize: "1.1rem", fontWeight:600, paddingTop:1, paddingLeft:12}} >
                    {shortName
                      ? `${shortName}    \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0 Age :   ${age}   \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0 Reg.Date :   ${regDate}  `
                      : null}
                  </Typography>
                ) : (
                  ""
                )}
              </div>
            </Paper>
          </Grid>
          <Modal open={isModalOpen} >   
            <div style={overlayStyle}>
              <div style={contentStyle}>
                <button onClick={handleQRCodeDetected} style={closeButtonStyle}>
                  Close
                </button>
                    <QRCodeScanner
                      isScanning={isScanning}
                      setIsScanning={setIsScanning}
                      onQRCodeDetected={handleQRCodeDetected}
                    />   
              </div>
            </div>  
          </Modal>
        
        </>
      )}
    </Grid>
  );
}

//export default React.forwardRef(CrudController);
export default CrudController;
