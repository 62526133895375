import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";

const CustomTextField = (props) => {
  const {
    label,
    placeholder,
    value,
    onChange,
    width,
    height,
    multiline,
    rows,
    rowsMax,
    onBlur,
    disabled,
    type,
  } = props;
  const [focused, setFocused] = useState(false);

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = (event) => {
    setFocused(false);
    if (onBlur) {
      onBlur(event); // Call the onBlur prop if provided
    }
  };

  const multilineProps = multiline ? { multiline: true, rows, rowsMax } : null;

  return (
    <TextField
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      fullWidth
      type={type}
      InputProps={{
        style: {
          color: focused ? "black" : "black",
          fontWeight: focused ? "bold" : "normal",
          height: height || "100%",
        },
      }}
      style={{
        backgroundColor: focused ? "#feedff" : "white",
        width: width || "100%", // set the width
      }}
      InputLabelProps={{
        style: {
          //color: focused ? "blue" : "black",
          //fontWeight: focused ? 'bold' : 'normal',
        },
      }}
      onFocus={handleFocus}
      onBlur={handleBlur}
      variant="outlined"
      disabled={disabled}
      {...multilineProps}
    />
  );
};

export default CustomTextField;
