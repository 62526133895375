import React, { useState, useRef, useEffect } from "react";
import api from "../Api.js";
import CustomTextField from "../control/CustomTextField.js";
import { Grid, Modal, Button, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import ImageUploader from "../control/ImageUploader.js";
import CrudController from "../control/CrudController";
import CustomMessageBox from "../control/CustomMessageBox";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@mui/material/AlertTitle";

import { capitalizeWords } from "../utils.js";
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
} from "@mui/material";
import TrendingUpTwoToneIcon from "@mui/icons-material/TrendingUpTwoTone";

function UserCreation() {
  //#region Variable
  const imageUploaderRef = useRef(null);
  const [id, setId] = useState(null);
  const [clinicNo, setClinicNo] = useState("");
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [roles, setRoles] = useState(null);
  const [userRoles, setUserRoles] = useState(null);
  const [permission, setPermission] = useState(null);
  const [rolePermission, setRolePermission] = useState(null);

  const [picture, setPicture] = useState(null);
  const [imageData, setImageData] = useState(null);

  const [databaseImage, setDatabaseImage] = useState(null);

  const [functionality, setFunctionality] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertDescription, setAlertDescription] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [isMessageBoxOpen, setMessageBoxOpen] = useState(false);
  const [buttonStates] = useState({
    search: false,
    edit: false,
    save: true,
    clear: true,
    close: false,
    history: false,
  });
  const [clearValues, setClearValues] = useState(false);

  const [showGeneratedNumberModal, setShowGeneratedNumberModal] =
    useState(false);
  const [generatedNumber, setGeneratedNumber] = useState("");

  const [, setCreateDt] = useState("");
  const [createBy, setCreateBy] = useState("");
  const [, setUpdateDt] = useState("");
  const [, setUpdateBy] = useState("");
  const [selectedRoleId, setSelectedRoleId] = useState(null);

  //#endregion Variable

  //#region Main Function
  const getUserDetail = async () => {
    try {
      var response;
      response = await api.get(`/user/${userId}`);
      const data = response.data.data;
      // eslint-disable-next-line eqeqeq
      const clinicData = data.find((item) => item.userid == userId);

      if (clinicData) {
        clear(false);
        setId(clinicData.id);
        setPassword(clinicData.password);
        setUserName(clinicData.username);
        setEmail(clinicData.email);
        setPicture(null);
        clinicData.profile_pic != null
          ? setDatabaseImage(clinicData.profile_pic)
          : setDatabaseImage(null);
        clinicData.profile_pic != null
          ? setDBImager(
              process.env.REACT_APP_PATIENT_PROFILE +
                process.env.REACT_APP_USER_PROFILE +
                clinicData.profile_pic.replace("\\", "/")
            )
          : setDBImager(null);
      } else {
        //triggerAlertOn("Search", `There is no data to be found.`, "warning");
      }
      getUserRole();
    } catch (error) {
      console.error(error);
    }
  };

  const getUserRole = async () => {
    try {
      var response;
      response = await api.get(`/user/role/${userId}`);
      const data = response.data.data;
      // eslint-disable-next-line eqeqeq
      setUserRoles(data.filter((item) => item.user_role_id != null));
      setRoles(data.filter((item) => item.user_role_id == null));
    } catch (error) {
      console.error(error);
    }
  };

  const getRolePermission = async (role_id) => {
    try {
      var response;
      response = await api.get(`/role/permission/${role_id}`);
      const data = response.data.data;
      setRolePermission(data.filter((item) => item.role_id != null));
      setPermission(data.filter((item) => item.role_id == null));
      setSelectedRoleId(role_id);
    } catch (error) {
      console.error(error);
    }
  };

  const clear = async (isMsgShow) => {
    if (isMsgShow === true) {
      setClinicNo("");

      setUserId("");
    }
    setId(null);
    setUserName("");
    setPassword("");
    setEmail("");
    setPicture(null);
    setImageData(null);
    setDatabaseImage(null);
    clearImageInUploader();
    setId(null);
    setRoles(null);
    setUserRoles(null);
    setPermission(null);
    setRolePermission(null);
    if (isMsgShow === true) handleClearValues();
    if (isMsgShow === true)
      triggerAlertOn("Clear", "Successfully cleaned!", "success");
  };

  const save = async () => {
    try {
      if (userId === "Admin" && sessionStorage.getItem("userid") !== userId) {
        triggerAlertOn(
          "Error",
          "You are not a system adiministrator to change their user detail!",
          "error"
        );
        return;
      }
      let imageUrl = null;
      if (imageData == null && picture == null) {
        imageUrl = null;
      } else if (
        imageData != null &&
        !imageData.includes(process.env.REACT_APP_PATIENT_PROFILE)
      ) {
        const fileExtenstion = extractImageExtension(imageData);
        imageUrl = await uploadImageToServer(
          imageData,
          fileExtenstion,
          process.env.REACT_APP_USER_DEFAULT_IMAGE_FOLDER
        );
      } else if (imageData.includes(process.env.REACT_APP_PATIENT_PROFILE)) {
        imageUrl = databaseImage;
      }

      imageUrl = imageUrl.replace(/^users[\\/]/, "");

      const response = await api.post(`/user/save`, {
        id: id,
        userid: userId,
        password: password,
        username: userName,
        email: email,
        profile_pic: imageUrl,
        com_code: sessionStorage.getItem("usercom"),
        status: 1,
        create_by: createBy,
        create_dt: new Date(),
        userrole: userRoles,
        rolepermission: rolePermission,
      });

      if (response.data.success) {
        clear(true);
        triggerAlertOn("Save", "Successfully saved!", "success");
      } else {
        triggerAlertOn(
          "Save",
          `There is an error: ${response.data.message}`,
          "error"
        );
      }
    } catch (error) {
      console.error(error);
      triggerAlertOn(
        "Save",
        `There is an error occurred while saving the record. The error is ${error}`,
        "error"
      );
    }
  };

  const edit = async () => {
    try {
      let imageUrl = null;
      //console.log("sessionStorage.getItem('userid') " + sessionStorage.getItem('userid'));

      if (imageData == null && picture == null) {
        imageUrl = null;
      } else if (
        imageData != null &&
        !imageData.includes(process.env.REACT_APP_PATIENT_PROFILE)
      ) {
        const fileExtenstion = extractImageExtension(imageData);
        imageUrl = await uploadImageToServer(
          imageData,
          fileExtenstion,
          process.env.REACT_APP_USER_DEFAULT_IMAGE_FOLDER
        );
      } else if (imageData.includes(process.env.REACT_APP_PATIENT_PROFILE)) {
        imageUrl = databaseImage;
      }
      //console.log("Edit");
      api
        .put(`/patient/edit`, {
          id: id,
          profile_pic: imageUrl == null ? undefined : imageUrl,
          update_dt: new Date(),
          update_by: sessionStorage.getItem("userid"),
        })
        .then(() => {
          clear(true);
          triggerAlertOn("Edit", "Successfully edited!", "success");
        })
        .catch((error) => {
          console.error(error);
          triggerAlertOn(
            "Edit",
            "There is an error occured while edit the record. The error is " +
              error,
            "error"
          );
        });
    } catch (error) {
      console.error(error);
      triggerAlertOn(
        "Edit",
        "There is an error occured while edit the record. The error is " +
          error,
        "error"
      );
    }
  };

  const handleSave = () => {
    // if (id != null) {
    //   triggerAlertOn(
    //     "Save",
    //     "Process terminated. You cannot save this again, try edit! ",
    //     "error"
    //   );
    //   return;
    // }

    if (validatePage()) handleButtonClick("Save");
  };

  const handleClear = (isNoMsg) => {
    if (isNoMsg === true) {
      clear(false);
    } else {
      handleButtonClick("Clear");
    }
  };

  const handleEdit = () => {
    handleButtonClick("Edit");
  };

  const handleSearch = async () => {
    try {
      const nextval = 1;
      var response;
      response = await api.get(`/patient/byclinicno/${clinicNo}/${nextval}`);
      const data = response.data.data;
      // eslint-disable-next-line eqeqeq
      const clinicData = data.find((item) => item.clinic_no == clinicNo);
      if (clinicData) {
        setId(clinicData.id);
        setUserId(clinicData.f_name);
        setPassword(clinicData.m_name);
        setUserName(clinicData.l_name);
        setPicture(null);
        clinicData.profile_pic != null
          ? setDatabaseImage(clinicData.profile_pic)
          : setDatabaseImage(null);
        clinicData.profile_pic != null
          ? setDBImager(
              process.env.REACT_APP_PATIENT_PROFILE +
                "/" +
                clinicData.profile_pic.replace("\\", "/")
            )
          : setDBImager(null);
      } else {
        //triggerAlertOn("Search", `There is no data to be found.`, "warning");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    // Handle search logic
  };

  const handleHistory = () => {
    // Handle search logic
  };

  const uploadImageToServer = async (image, fileType, folderName) => {
    try {
      const formData = new FormData();
      formData.append("folderName", folderName);
      formData.append(
        "image",
        dataURItoBlob(image, fileType),
        `image.${fileType}`
      );

      const response = await api.post(`/profileimages`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        const result = response.data;
        //console.log("Image uploaded successfully. Image URL:", result.imageUrl);
        return result.imageUrl;
      } else {
        console.error("Failed to upload image:", response.statusText);
        // Handle the error, throw an exception, or return an appropriate value
      }
    } catch (error) {
      console.error("Error uploading image:", error.message);
      // Handle the error, throw an exception, or return an appropriate value
    }
  };

  // Convert data URI to Blob
  function dataURItoBlob(dataURI, imageType) {
    const mimeType = `image/${imageType}`;
    const byteString = atob(dataURI.split(",")[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeType });
  }

  const validatePage = () => {
    // Check required fields
    if (!userId || !userName) {
      return false;
    }

    // All checks passed, return true
    return true;
  };

  const closeModal = () => {
    setShowGeneratedNumberModal(false);
  };

  const handleClinicNoChange = (newClinicNo) => {
    setClinicNo(newClinicNo);
  };

  //#endregion Main Function

  //#region Image Related
  const extractImageExtension = (image) => {
    if (image == null) return "jpg";
    const firstSplit = image.split(";");
    const secondSplit = firstSplit[0].split(":");
    const thirdSplit = secondSplit[1].split("/");
    const result = thirdSplit[1];
    return result;
  };

  const clearImageInUploader = () => {
    // Call the clearImage function in ImageUploader
    if (imageUploaderRef.current) {
      imageUploaderRef.current.clearImage();
    }
  };

  const setDBImager = (image) => {
    if (imageUploaderRef.current) {
      imageUploaderRef.current.setDbImage(image);
    }
  };

  const handleImageChange = (image) => {
    setImageData(image);
  };

  //#endregion Image Related

  //#region outside controller compartments

  const handleButtonClick = (buttonId) => {
    if (buttonId === "Save") {
      setMessage("Do you really want to save this Patient?");
    } else if (buttonId === "Edit") {
      setMessage("Do you really want to edit this Patient?");
    } else if (buttonId === "Clear") {
      setMessage("Do you really want to clear this Patient?");
    }
    setFunctionality(buttonId);
    setMessageBoxOpen(true);
  };

  const handleMessageBoxClose = (isPrimaryButtonClicked) => {
    if (isPrimaryButtonClicked && functionality === "Save") {
      save();
    }
    if (isPrimaryButtonClicked && functionality === "Clear") {
      clear(true);
    }

    if (isPrimaryButtonClicked && functionality === "Edit") {
      edit();
    }

    // Set isMessageBoxOpen to false to hide the message box
    setMessageBoxOpen(false);
  };

  const triggerAlertOn = async (title, description, severity) => {
    setAlertTitle(title);
    setAlertDescription(description);
    setAlertSeverity(severity);
    setShowAlert(true);

    setTimeout(() => {
      setAlertTitle("");
      setAlertDescription("");
      setAlertSeverity("");
      setShowAlert(false);
    }, 2000);
  };

  useEffect(() => {
    setCreateDt(new Date());
    setCreateBy(sessionStorage.getItem("userid"));
    setUpdateDt(new Date());
    setUpdateBy(sessionStorage.getItem("userid"));
  }, []);

  const handleClearValues = () => {
    // Set clearValues to true to trigger the clearing in CrudController
    setClearValues(true);
  };

  // Callback function to reset clearValues in CrudController
  const handleClearComplete = () => {
    setClearValues(false);
  };

  //#endregion outside controller compartments

  const handleDataFetchStatus = (status) => {
    if (status === false) {
      //triggerAlertOn("Search", `This is an invalid clinic no. Please check the number and try again.`, "error"); setClinicNo("");
    }
  };

  const addRoleToUser = (e) => {
    setRoles((prevRoles) =>
      prevRoles.filter((role) => role.role_id !== e.role_id)
    );
    setUserRoles((prevUserRoles) => [...prevUserRoles, e]);
    setPermission(null);
    setRolePermission(null);
  };

  const removeUserRole = (e) => {
    setUserRoles((prevRoles) =>
      prevRoles.filter((role) => role.role_id !== e.role_id)
    );
    setRoles((prevUserRoles) => [...prevUserRoles, e]);
    setPermission(null);
    setRolePermission(null);
  };

  const addPermissionToRole = (e) => {
    setPermission((prevPermission) =>
      prevPermission.filter((role) => role.permission_id !== e.permission_id)
    );
    e.role_id = selectedRoleId;
    setRolePermission((prevRolesPermission) => [...prevRolesPermission, e]);
  };

  const removeRolePermission = (e) => {
    setRolePermission((prevPermission) =>
      prevPermission.filter((role) => role.permission_id !== e.permission_id)
    );
    setPermission((prevRolesPermission) => [...prevRolesPermission, e]);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        marginTop: "16px",
        marginLeft: "16px",
      }}
    >
      <Grid container spacing={2}>
        {/* 1 Row */}
        <CrudController
          onSave={handleSave}
          onClear={handleClear}
          onEdit={handleEdit}
          onSearch={handleSearch}
          onClose={handleClose}
          onHistory={handleHistory}
          clinicNo={clinicNo}
          onClinicNoChange={handleClinicNoChange}
          buttonStates={buttonStates}
          isNameVisible={false}
          clearValues={clearValues}
          onClearComplete={handleClearComplete}
          onDataFetchStatus={handleDataFetchStatus}
          isDoSearch={true}
          isClinicNoDisable={true}
          isClinicNoVisible={true}
        />

        {/* user id to email */}
        <Grid container spacing={2}>
          {/* First Column */}
          <Grid item xs={4}>
            <Paper elevation={0} style={{ padding: 5, textAlign: "left" }}>
              <CustomTextField
                label="User ID"
                type="text"
                id="userid"
                width="500px"
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
                onBlur={getUserDetail}
              />
            </Paper>

            <Paper elevation={0} style={{ padding: 5, textAlign: "left" }}>
              <CustomTextField
                label="Password"
                type="password"
                id="password"
                width="500px"
                value={password}
                onChange={(e) => setPassword(capitalizeWords(e.target.value))}
              />
            </Paper>

            <Paper elevation={0} style={{ padding: 5, textAlign: "left" }}>
              <CustomTextField
                label="Name"
                type="text"
                id="name"
                width="500px"
                value={userName}
                onChange={(e) => setUserName(capitalizeWords(e.target.value))}
              />
            </Paper>

            <Paper elevation={0} style={{ padding: 5, textAlign: "left" }}>
              <CustomTextField
                label="E-mail"
                type="text"
                id="email"
                width="500px"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Paper>
          </Grid>

          {/* Second Column */}
          <Grid item xs={8}>
            <Paper elevation={0} style={{ textAlign: "center" }}>
              <ImageUploader
                ref={imageUploaderRef}
                id="profileImage"
                onImageChange={handleImageChange}
              />
            </Paper>
          </Grid>
        </Grid>

        {/* Roles and Permission header */}
        <Grid container spacing={0} style={{ paddingTop: 10 }}>
          {/* Roles */}
          <Grid item xs={5}>
            <Paper
              elevation={0}
              style={{
                textAlign: "left",
                backgroundColor: "#050505",
              }}
            >
              <Typography
                variant="h6"
                style={{
                  color: "#F8FAFA",
                  fontWeight: 1000,
                }}
              >
                Roles
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={2}>
            <Paper elevation={0}>&nbsp;</Paper>
          </Grid>
          {/* Permission */}
          <Grid item xs={5}>
            <Paper
              elevation={0}
              style={{
                textAlign: "left",
                backgroundColor: "#050505",
              }}
            >
              <Typography
                variant="h6"
                style={{
                  color: "#F8FAFA",
                  fontWeight: 1000,
                }}
              >
                Permission
              </Typography>
            </Paper>
          </Grid>
        </Grid>

        {/* unassgined n assgined headers */}
        <Grid container spacing={0} style={{ paddingTop: 2 }}>
          {/* cell 2 */}
          <Grid item xs={2}>
            <Paper
              elevation={0}
              style={{
                textAlign: "left",
                backgroundColor: "#D3D3D3",
              }}
            >
              <Typography
                variant="h9"
                style={{
                  fontWeight: 1000,
                }}
              >
                Unassigned
              </Typography>
            </Paper>
          </Grid>
          {/* cell 1 */}
          <Grid item xs={3}>
            <Paper
              elevation={0}
              style={{
                textAlign: "left",
                backgroundColor: "#26D3C5",
              }}
            >
              <Typography
                variant="h9"
                style={{
                  fontWeight: 1000,
                }}
              >
                Assgined
              </Typography>
            </Paper>
          </Grid>
          {/* spacing */}
          <Grid item xs={2}>
            <Paper elevation={0}>&nbsp;</Paper>
          </Grid>
          {/* cell 1 */}
          <Grid item xs={2}>
            <Paper
              elevation={0}
              style={{
                textAlign: "left",
                backgroundColor: "#D3D3D3",
              }}
            >
              <Typography variant="h9" style={{ fontWeight: 1000 }}>
                Unassigned
              </Typography>
            </Paper>
          </Grid>
          {/* cell 3 */}
          <Grid item xs={3}>
            <Paper
              elevation={0}
              style={{
                textAlign: "left",
                backgroundColor: "#26D3C5",
              }}
            >
              <Typography variant="h9" style={{ fontWeight: 1000 }}>
                Assgined
              </Typography>
            </Paper>
          </Grid>
        </Grid>

        {/* unassgined n assgined values */}
        <Grid container spacing={0}>
          {/* cell 1 */}
          <Grid item xs={2}>
            <Paper
              elevation={0}
              style={{
                padding: 5,
                textAlign: "left",
                backgroundColor: "#EFF2F3",
              }}
            >
              <List id="roles">
                {roles?.map((item) => (
                  <React.Fragment key={item.role_id}>
                    <ListItem button>
                      <ListItemText
                        primary={item.role_name}
                        onDoubleClick={() => addRoleToUser(item)}
                      />
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                ))}
              </List>
            </Paper>
          </Grid>

          {/* cell 2 */}
          <Grid item xs={3}>
            <Paper
              elevation={0}
              style={{
                padding: 5,
                textAlign: "left",
                backgroundColor: "#C3FAF6",
              }}
            >
              <List id="userroles">
                {userRoles?.map((item) => (
                  <React.Fragment key={item.role_id}>
                    <ListItem>
                      {/* The primary text still displayed through ListItemText */}
                      <ListItemText
                        primary={item.role_name}
                        onDoubleClick={() => {
                          removeUserRole(item);
                        }}
                      />

                      {/* Button to handle click event for getting role permissions */}
                      <IconButton
                        onClick={() => getRolePermission(item.role_id)}
                        aria-label="get permissions"
                      >
                        <TrendingUpTwoToneIcon />
                      </IconButton>
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                ))}
              </List>
            </Paper>
          </Grid>

          {/* spacing */}
          <Grid item xs={2}>
            <Paper elevation={0}>&nbsp;</Paper>
          </Grid>

          {/* cell 1 */}
          <Grid item xs={2}>
            <Paper
              elevation={0}
              style={{
                padding: 5,
                textAlign: "left",
                backgroundColor: "#EFF2F3",
              }}
            >
              <List id="permission">
                {permission?.map((item) => (
                  <React.Fragment key={item.permission_id}>
                    <ListItem button>
                      <ListItemText
                        primary={item.permission_name}
                        onDoubleClick={() => addPermissionToRole(item)}
                      />
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                ))}
              </List>
            </Paper>
          </Grid>

          {/* cell 2 */}
          <Grid item xs={3}>
            <Paper
              elevation={0}
              style={{
                padding: 5,
                textAlign: "left",
                backgroundColor: "#C3FAF6",
              }}
            >
              <List id="rolepermission">
                {rolePermission?.map((item) => (
                  <React.Fragment key={item.permission_id}>
                    <ListItem button>
                      <ListItemText
                        primary={item.permission_name}
                        onDoubleClick={() => removeRolePermission(item)}
                      />
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                ))}
              </List>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <CustomMessageBox
        open={isMessageBoxOpen}
        onClose={handleMessageBoxClose}
        title="Patient Registration"
        message={message}
        primaryButtonCaption="OK"
        secondaryButtonCaption="Cancel"
        showPrimaryButton
        showSecondaryButton
      />
      {showAlert && (
        <div
          style={{
            position: "fixed",
            top: "155px",
            left: "610px",
            width: 890,
            height: 150,
            transform: "translate(-50%, -50%)",
            zIndex: 1000,
          }}
        >
          <Alert severity={alertSeverity}>
            <AlertTitle style={{ fontSize: "20px" }}>{alertTitle}</AlertTitle>
            <strong style={{ fontSize: "20px" }}>{alertDescription}</strong>
          </Alert>
        </div>
      )}

      {/* Modal to display generated number */}
      <Modal
        open={showGeneratedNumberModal}
        onClose={closeModal}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "rgb(256, 256, 200)", // Light gray shade
            border: "2px solid #000",
            borderRadius: "8px",
            padding: "20px",
            textAlign: "center",
            boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.75)", // Shadow
            transform: "translateZ(10px)", // Depth effect
          }}
        >
          <h2>Patient's Clinical Number</h2>
          <h1 style={{ fontSize: "100px", color: "red" }}>{generatedNumber}</h1>
          <Button variant="contained" color="primary" onClick={closeModal}>
            Close
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default UserCreation;
