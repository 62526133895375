import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  useCallback,
} from "react";
import { Grid } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import CustomTextField from "../../control/CustomTextField";
import CrudController from "../../control/CrudController";
import InputLabel from "@material-ui/core/InputLabel";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Select, MenuItem } from "@material-ui/core";
import Tooth from "../../control/Tooth.js";
import CustomMessageBox from "../../control/CustomMessageBox";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import dayjs from "dayjs";
import api from "../../Api.js";
import DynamicSnackBox from "../../control/DynamicPopupSnackBar";

const RemProsthesis = forwardRef(
  ({ refModule, updateComponentToRender }, ref) => {
    //#region Variable Section
    useImperativeHandle(ref, () => ({
      handleSearch,
      handleClear,
      save,
      edit,
    }));

    const [resetKey, setResetKey] = useState(0);
    // State to control clearing values in CrudController
    const [clearValues, setClearValues] = useState(false);

    const [, setRefClinicNo] = useState(null);
    const [refRctNo, setRefRctNo] = useState(null);

    const [buttonStates, setButtonStates] = useState({
      search: true,
      edit: true,
      save: true,
      clear: true,
      close: false,
      history: true,
      scan: true,
    });
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");
    const [alertDescription, setAlertDescription] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("");
    const [message, setMessage] = useState("");
    const [isMessageBoxOpen, setMessageBoxOpen] = useState(false);
    const [functionality, setFunctionality] = useState(null);

    const examTeethInitCommon = process.env.REACT_APP_NORMAL_TOOTH_SETTING;

    const [id, setId] = useState(null);
    const [clinicNo, setClinicNo] = useState("");
    const [, setOtherDocNo] = useState(null);
    const [type, setType] = useState("");
    const [treatmentTeethChange, setTreatmentTeethChange] =
      useState(examTeethInitCommon);
    const [material, setMaterial] = useState("");
    const [claspsTeethChange, setClaspsTeethChange] =
      useState(examTeethInitCommon);
    const [impressionDate, setImpressionDate] = useState(null);
    const [trailDate, setTrailDate] = useState(null);
    const [deliveryDate, setDeliveryDate] = useState(null);
    const [special, setSpecial] = useState("");
    const [complain, setComplain] = useState("");

    const [createBy, setCreateBy] = useState("");
    const [updateBy, setUpdateBy] = useState("");

    //#endregion Variable Section

    //#region Main Event Handlers

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const clear = useCallback(async (isMsgShow) => {
      if (isMsgShow === true) {
        setClinicNo("");
        setId(null);
      }
      resetAButton();
      if (isMsgShow === true) handleClearValues();
      if (isMsgShow === true)
        triggerAlertOn("Clear", "Successfully cleaned!", "success");

      setType("");
      setTreatmentTeethChange(examTeethInitCommon);
      setMaterial("");
      setClaspsTeethChange(examTeethInitCommon);
      setImpressionDate(null);
      setTrailDate(null);
      setDeliveryDate(null);
      setSpecial("");
      setComplain("");
    });

    const decideOtherDocumentId = (_treatmentId) => {
      if (refModule != null ) if(refModule.refTreatmentId != null){
        // if existing treatment
        return refModule.refTreatmentId;
      } else if (
        (refModule != null &&
          refModule.refTreatmentId == null &&
          _treatmentId != null) ||
        (refModule == null &&
          refModule.refTreatmentId == null &&
          _treatmentId != null)
      ) {
        // if treatment is not exist, use saved id
        return _treatmentId;
      } else {
        // if both not in a value, that is pure ortho
        return null;
      }
    };

    const save = async (_lastId) => {
      try {
        api
          .post(`/rem_prosthesis/save`, {
            clinic_no: clinicNo,
            visit_date: new Date(),
            other_doc_no: decideOtherDocumentId(_lastId),
            app_ref_no: null,
            type: type,
            for_teeth:
              treatmentTeethChange === examTeethInitCommon
                ? null
                : treatmentTeethChange,
            material: material,
            clasps_teeth:
              claspsTeethChange === examTeethInitCommon
                ? null
                : claspsTeethChange,
            first_dt: impressionDate,
            trail_dt: trailDate,
            delivery_dt: deliveryDate,
            special: special,
            complain: complain,
            com_code: sessionStorage.getItem("usercom"),
            create_dt: new Date(),
            create_by: createBy,
          })
          .then(() => {
            clear(true);
            triggerAlertOn("Save", "Successfully saved!", "success");
          })
          .catch((error) => {
            if (error.response.status === 400) {
              triggerAlertOn("Save", error.response.data.error, "error");
            } else {
              triggerAlertOn(
                "Save",
                "There is an error occured while save the record. The error is " +
                  error,
                "error"
              );
            }
          });
      } catch (error) {
        console.error(error);
        triggerAlertOn(
          "Save",
          "There is an error occured while save the record. The error is " +
            error,
          "error"
        );
      }
    };

    const edit = async () => {
      try {
        api
          .put(`/rem_prosthesis/edit`, {
            id: id,
            clinic_no: clinicNo,
            other_doc_no: refModule != null ? refModule.refTreatmentId : null,
            app_ref_no: null,
            type: type,
            for_teeth:
              treatmentTeethChange === examTeethInitCommon
                ? null
                : treatmentTeethChange,
            material: material,
            clasps_teeth:
              claspsTeethChange === examTeethInitCommon
                ? null
                : claspsTeethChange,
            first_dt: impressionDate,
            trail_dt: trailDate,
            delivery_dt: deliveryDate,
            special: special,
            complain: complain,
            update_dt: new Date(),
            update_by: updateBy,
          })
          .then(() => {
            clear(true);
            triggerAlertOn("Edit", "Successfully edited!", "success");
            if (refModule != null) {
              handleClose();
            }
          })
          .catch((error) => {
            console.error(error);
            triggerAlertOn(
              "Edit",
              "There is an error occured while edit the record. The error is " +
                error,
              "error"
            );
          });
      } catch (error) {
        console.error(error);
        triggerAlertOn(
          "Edit",
          "There is an error occured while edit the record. The error is " +
            error,
          "error"
        );
      }
    };

    const validatePage = () => {
      // Check required fields
      if (!clinicNo) {
        return false;
      }
      // All checks passed, return true
      return true;
    };

    const handleSave = async () => {
      if (id != null) {
        triggerAlertOn(
          "Save",
          "Process terminated. You cannot save this again, try edit! ",
          "error"
        );
        return;
      }

      if (validatePage()) {
        handleButtonClick("Save");
      } else {
        triggerAlertOn(
          "Save",
          "Process terminated. Please check all the required fields! ",
          "error"
        );
      }
    };

    const handleClear = (isNoMsg) => {
      if (isNoMsg === true) {
        clear(false);
      } else {
        handleButtonClick("Clear");
      }
    };

    const handleEdit = () => {
      if (id == null) {
        triggerAlertOn(
          "Edit",
          "Process terminated. Seems this is a new record!",
          "error"
        );
        return;
      }
      handleButtonClick("Edit");
    };

    const handleSearch = async (_id) => {
      try {
        //console.log("clinicNo complains " + clinicNo);
        const response = await api.get(`/rem_prosthesis/byId/${_id}`);
        const data = (await response).data.data;
        //console.log("complains",data[0])
        const clinicData = data.find((item) => item.id === _id);
        clear(false);
        if (clinicData) {
          setId(clinicData.id);
          setClinicNo(clinicData.clinic_no);
          setType(clinicData.type);
          setTreatmentTeethChange(
            clinicData.for_teeth === null
              ? examTeethInitCommon
              : clinicData.for_teeth
          );
          setMaterial(clinicData.material);
          setClaspsTeethChange(
            clinicData.clasps_teeth === null
              ? examTeethInitCommon
              : clinicData.clasps_teeth
          );
          setImpressionDate(
            clinicData.first_dt != null ? dayjs(clinicData.first_dt) : null
          );
          setTrailDate(
            clinicData.trail_dt != null ? dayjs(clinicData.trail_dt) : null
          );
          setDeliveryDate(
            clinicData.delivery_dt != null
              ? dayjs(clinicData.delivery_dt)
              : null
          );
          setSpecial(clinicData.special);
          setComplain(clinicData.complain);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const handleClose = () => {
      if (refModule != null) {
        setButtonStates({
          search: true,
          edit: true,
          save: true,
          clear: true,
          close: false,
          history: true,
          scan: true,
        });
        let refObj = {
          refModule: "TRE",
          refTreatmentId: null,
          refModuleId: null,
          refClinicId: clinicNo,
        };
        setOtherDocNo(null);
        setRefClinicNo(null);
        setRefRctNo(null);
        refModule = null;
        updateComponentToRender(refObj);
      }
    };

    const handleHistory = () => {
      // Handle search logic
      // eslint-disable-next-line eqeqeq
      if (clinicNo != null && clinicNo != "") {
        handleOpenModal();
      } else {
        triggerAlertOn(
          "History",
          "There are no history records to show.",
          "error"
        );
        return;
      }
    };

    //#endregion Main Event Handlers

    //#region Other Events

    const handleClinicNoChange = (newClinicNo) => {
      // Handle clinicNo change logic
      //handleClear();
      setClinicNo(newClinicNo);
      setId(null);
    };

    const clearImpressionDate = () => {
      setImpressionDate(null);
    };

    const clearTrailDate = () => {
      setTrailDate(null);
    };

    const clearDeliveryDate = () => {
      setDeliveryDate(null);
    };

    const handleTreatmentChangeTeeth = (myDictionary) => {
      setTreatmentTeethChange(convertDictionaryToString(myDictionary));
    };

    const handleClaspsChangeTeeth = (myDictionary) => {
      setClaspsTeethChange(convertDictionaryToString(myDictionary));
    };

    useEffect(() => {
      if (refModule != null) {
        if (refModule.refModuleId != null) {
          setButtonStates({
            search: false,
            edit: true,
            save: false,
            clear: true,
            close: true,
            history: true,
            scan: true,
          });
        } else {
          setButtonStates({
            search: false,
            edit: false,
            save: true,
            clear: true,
            close: true,
            history: false,
          });
        }
        setClinicNo(refModule.refClinicId);
        setOtherDocNo(refModule.refTreatmentId);
        setRefClinicNo(refModule.refClinicId);
        setRefRctNo(refModule.refModuleId);
      } else {
        setButtonStates({
          search: true,
          edit: true,
          save: true,
          clear: true,
          close: false,
          history: true,
          scan: true,
        });
        setOtherDocNo(null);
        setRefClinicNo(null);
        setRefRctNo(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        refModule = null;
      }
    }, [refModule]);

    useEffect(() => {
      async function getRemByID() {
        try {
          //console.log("clinicNo complains " + clinicNo);
          const response = await api.get(`/rem_prosthesis/byid/${refRctNo}`);
          const data = (await response).data.data;
          //console.log("complains",data[0])
          const clinicData = data.find((item) => item.id === refRctNo);

          if (clinicData) {
            clear(false);

            setId(clinicData.id);
            setClinicNo(clinicData.clinic_no);
            setType(clinicData.type);
            setTreatmentTeethChange(
              clinicData.for_teeth === null
                ? examTeethInitCommon
                : clinicData.for_teeth
            );
            setMaterial(clinicData.material);
            setClaspsTeethChange(
              clinicData.clasps_teeth === null
                ? examTeethInitCommon
                : clinicData.clasps_teeth
            );
            setImpressionDate(
              clinicData.first_dt != null ? dayjs(clinicData.first_dt) : null
            );
            setTrailDate(
              clinicData.trail_dt != null ? dayjs(clinicData.trail_dt) : null
            );
            setDeliveryDate(
              clinicData.delivery_dt != null
                ? dayjs(clinicData.delivery_dt)
                : null
            );
            setSpecial(clinicData.special);
            setComplain(clinicData.complain);
          } else {
            //todo:
          }
        } catch (error) {
          console.error(error);
        }
      }

      if (refRctNo != null) {
        getRemByID();
      }
      if (refModule != null) {
        setOtherDocNo(refModule.refTreatmentId);
      }
    }, [refRctNo]);
    //#endregion Other Events

    //#region outside controller compartments

    const convertDictionaryToString = (dictionary) => {
      return Object.entries(dictionary)
        .map(([key, value]) => `${key}.${value}`)
        .join(",");
    };

    const handleButtonClick = (buttonId) => {
      if (buttonId === "Save") {
        setMessage("Do you really want to save this removable prosthesis?");
      } else if (buttonId === "Edit") {
        setMessage("Do you really want to edit this removable prosthesis?");
      } else if (buttonId === "Clear") {
        setMessage("Do you really want to clear this removable prosthesis?");
      }
      setFunctionality(buttonId);
      setMessageBoxOpen(true);
    };

    const handleMessageBoxClose = (isPrimaryButtonClicked) => {
      if (isPrimaryButtonClicked && functionality === "Save") {
        save();
      }
      if (isPrimaryButtonClicked && functionality === "Clear") {
        clear(true);
      }

      if (isPrimaryButtonClicked && functionality === "Edit") {
        edit();
      }
      // Set isMessageBoxOpen to false to hide the message box
      setMessageBoxOpen(false);
    };

    const triggerAlertOn = async (title, description, severity) => {
      setAlertTitle(title);
      setAlertDescription(description);
      setAlertSeverity(severity);
      setShowAlert(true);

      setTimeout(() => {
        setAlertTitle("");
        setAlertDescription("");
        setAlertSeverity("");
        setShowAlert(false);
      }, 2000);
    };

    useEffect(() => {
      setCreateBy(sessionStorage.getItem("userid"));
      setUpdateBy(sessionStorage.getItem("userid"));
    }, []);

    const resetAButton = () => {
      // Update the key to remount component A and reset its state
      setResetKey((prevKey) => prevKey + 1);
    };

    const handleClearValues = () => {
      // Set clearValues to true to trigger the clearing in CrudController
      setClearValues(true);
    };

    // Callback function to reset clearValues in CrudController
    const handleClearComplete = () => {
      setClearValues(false);
    };

    //#endregion outside controller compartments

    const handleDataFetchStatus = (status) => {
      if (status === false) {
        triggerAlertOn(
          "Search",
          `This is an invalid clinic no. Please check the number and try again.`,
          "error"
        );
        setClinicNo("");
      }
    };

    const searchFields = [
      { label: "Visit #", value: "visit_sequence" },
      { label: "Date", value: "visit_date" },
      { label: "id", value: "id" },
    ];

    const endpointComponent = "rem_prosthesis";
    const endPointRouter = "visit";

    const handleRowSingleClick = (id) => {
      //console.log("selected id " + id);
      setId(id);
      setOpenModal(false); // Close the modal after selecting a row
      handleSearch(id);
    };

    const handleOpenModal = () => {
      setOpenModal(true);
    };

    const handleCloseModal = () => {
      setOpenModal(false);
    };

    const [openModal, setOpenModal] = useState(false);

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: "16px",
          marginLeft: "16px",
        }}
      >
        <Grid container spacing={2}>
          {/* 1 Row */}
          <CrudController
            onSave={handleSave}
            onClear={handleClear}
            onEdit={handleEdit}
            onSearch={handleSearch}
            onClose={handleClose}
            onHistory={handleHistory}
            clinicNo={clinicNo}
            onClinicNoChange={handleClinicNoChange}
            buttonStates={buttonStates}
            isNameVisible={true}
            clearValues={clearValues}
            onClearComplete={handleClearComplete}
            onDataFetchStatus={handleDataFetchStatus}
            isClinicNoVisible={
              refModule == null || refModule == "undefined" ? true : false
            }
          />

          {/* 2 Row */}
          <Grid container spacing={2} style={{ paddingTop: 15 }}>
            {/* cell 1 */}
            <Grid item xs={4}>
              <Paper elevation={0} style={{ padding: 5, textAlign: "left" }}>
                <InputLabel id="type-label">Type</InputLabel>
                <Select
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  labelId="type-label"
                  displayEmpty
                  style={{ width: "210px" }}
                >
                  <MenuItem value={null} disabled>
                    {" "}
                    --select--{" "}
                  </MenuItem>
                  <MenuItem value={"UC"}>UC</MenuItem>
                  <MenuItem value={"LC"}>LC</MenuItem>
                  <MenuItem value={"UC/LC"}>UC/LC</MenuItem>
                  <MenuItem value={"UC/LP"}>UC/LP</MenuItem>
                  <MenuItem value={"UP/LC"}>UP/LC</MenuItem>
                  <MenuItem value={"UP"}>UP</MenuItem>
                  <MenuItem value={"LP"}>LP</MenuItem>
                  <MenuItem value={"UP/LP"}>UP/LP</MenuItem>
                </Select>
              </Paper>
            </Grid>

            {/* cell 2 */}
            <Grid item xs={3}>
              <Paper elevation={0} style={{ padding: 5, textAlign: "left" }}>
                <InputLabel id="treatment-label">Treatment @</InputLabel>
                <Tooth
                  initialButtonString={treatmentTeethChange}
                  key={resetKey}
                  onClickPatternChange={handleTreatmentChangeTeeth}
                />
              </Paper>
            </Grid>
          </Grid>

          {/* 3 Row */}
          <Grid container spacing={2} style={{ paddingTop: 15 }}>
            {/* cell 1 */}
            <Grid item xs={4}>
              <Paper elevation={0} style={{ padding: 5, textAlign: "left" }}>
                <InputLabel id="material-label">Materials</InputLabel>
                <Select
                  value={material}
                  onChange={(e) => setMaterial(e.target.value)}
                  labelId="material-label"
                  displayEmpty
                  style={{ width: "210px" }}
                >
                  <MenuItem value={null} disabled>
                    {" "}
                    --select--{" "}
                  </MenuItem>
                  <MenuItem value={"Acrylic"}>Acrylic</MenuItem>
                  <MenuItem value={"Metal"}>Metal</MenuItem>
                  <MenuItem value={"Welplast(flexi)"}>Welplast(Flexi)</MenuItem>
                </Select>
              </Paper>
            </Grid>

            {/* cell 2 */}
            <Grid item xs={3}>
              <Paper elevation={0} style={{ padding: 5, textAlign: "left" }}>
                <InputLabel id="clasps-label">Clasps @</InputLabel>
                <Tooth
                  initialButtonString={claspsTeethChange}
                  key={resetKey}
                  onClickPatternChange={handleClaspsChangeTeeth}
                />
              </Paper>
            </Grid>
          </Grid>

          {/* 4 Row */}
          <Grid container spacing={2} style={{ paddingTop: 10 }}>
            <Grid item xs={12}>
              <Paper
                elevation={0}
                style={{ paddingBottom: 13, textAlign: "left" }}
              >
                <Grid container spacing={10}>
                  {/* cell 1 */}
                  <Grid item xs={3}>
                    <Paper
                      elevation={0}
                      style={{ padding: 5, textAlign: "left" }}
                    >
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        locale="en"
                      >
                        <DatePicker
                          label="1st impression"
                          format={process.env.REACT_APP_DATE_FORMAT}
                          style={{ width: "170px" }}
                          value={
                            impressionDate != null
                              ? dayjs(impressionDate)
                              : null
                          }
                          onChange={(newValue) => setImpressionDate(newValue)}
                          clearable // Enable clearable option
                          onAccept={(newValue) => setImpressionDate(newValue)}
                          onClear={clearImpressionDate}
                        />
                      </LocalizationProvider>
                    </Paper>
                  </Grid>
                  {/* cell 2 */}
                  <Grid item xs={3}>
                    <Paper
                      elevation={0}
                      style={{ padding: 5, textAlign: "left", paddingLeft: 18 }}
                    >
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        locale="en"
                      >
                        <DatePicker
                          label="Trail"
                          format={process.env.REACT_APP_DATE_FORMAT}
                          style={{ width: "170px" }}
                          value={trailDate != null ? dayjs(trailDate) : null}
                          onChange={(newValue) => setTrailDate(newValue)}
                          clearable // Enable clearable option
                          onAccept={(newValue) => setTrailDate(newValue)}
                          onClear={clearTrailDate}
                        />
                      </LocalizationProvider>
                    </Paper>
                  </Grid>
                  {/* cell 3 */}
                  <Grid item xs={3}>
                    <Paper
                      elevation={0}
                      style={{ padding: 5, textAlign: "left", paddingLeft: 14 }}
                    >
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        locale="en"
                      >
                        <DatePicker
                          label="Delivery"
                          format={process.env.REACT_APP_DATE_FORMAT}
                          style={{ width: "190px" }}
                          value={
                            deliveryDate != null ? dayjs(deliveryDate) : null
                          }
                          onChange={(newValue) => setDeliveryDate(newValue)}
                          clearable // Enable clearable option
                          onAccept={(newValue) => setDeliveryDate(newValue)}
                          onClear={clearDeliveryDate}
                        />
                      </LocalizationProvider>
                    </Paper>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>

          {/* 5 Row */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper elevation={0} style={{ padding: 0, textAlign: "left" }}>
                <CustomTextField
                  label="Special Notes"
                  type="text"
                  id="middlename"
                  width="774px"
                  value={special}
                  onChange={(e) => setSpecial(e.target.value)}
                />
              </Paper>
            </Grid>

            {/* 6 Row */}
            <Grid item xs={12}>
              <Paper elevation={0} style={{ padding: 0, textAlign: "left" }}>
                <CustomTextField
                  label="Post Delivery Complains"
                  type="text"
                  id="lastname"
                  width="774px"
                  value={complain}
                  onChange={(e) => setComplain(e.target.value)}
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <CustomMessageBox
          open={isMessageBoxOpen}
          onClose={handleMessageBoxClose}
          title="Patient Registration"
          message={message}
          primaryButtonCaption="OK"
          secondaryButtonCaption="Cancel"
          showPrimaryButton
          showSecondaryButton
        />
        <DynamicSnackBox
          endpointComponent={endpointComponent}
          endPointRouter={endPointRouter}
          columns={searchFields}
          onRowDoubleClick={handleRowSingleClick}
          handleCloseModal={handleCloseModal}
          clinicNo={clinicNo}
          openModal={openModal} // Pass modalOpen state as open prop
          showCloseButton={false}
        />
        {showAlert && (
          <div
            style={{
              position: "fixed",
              top: "155px",
              left: "610px",
              width: 890,
              height: 150,
              transform: "translate(-50%, -50%)",
              zIndex: 1000,
            }}
          >
            <Alert severity={alertSeverity}>
              <AlertTitle style={{ fontSize: "20px" }}>{alertTitle}</AlertTitle>
              <strong style={{ fontSize: "20px" }}>{alertDescription}</strong>
            </Alert>
          </div>
        )}
      </div>
    );
  }
);

export default RemProsthesis;
