import React, { useState,useEffect } from "react";
import {  Grid,  makeStyles} from "@material-ui/core";
import LoginPg from "./components/login/LoginPage"
import { createTheme } from "@material-ui/core/styles";
import MasterTopBar from "./components/control/MasterTopBar";
import MasterLeftNavigation from "./components/control/MasterLeftNavigation";



const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  column: {
    height: "100%",
    padding: theme.spacing(1),
 
  },
  expandableColumn: {
    minWidth: "150px",
  },
  loginContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%"
  },
  loginInput: {
    marginBottom: theme.spacing(2),
  },
  toggleButton: {
    width: "100%",

  },
  fab: {
    position: 'fixed',
    top: theme.spacing(9),
    left: theme.spacing(6),
  },
  userDetails: {
    display: "flex",
    alignItems: "center", // Align items vertically
    marginRight: theme.spacing(2),
  },
  profileIcon: {
    display: "flex",
    alignItems: "center",
  },
  leftNavigation: {
    position: 'fixed',
    left: 0,
    top: 0,
    height: '100%',
    zIndex: 1000, // Adjust the z-index as needed
  },
  contentArea: {
    marginLeft: theme.spacing(8), // Adjust the margin to accommodate the fixed left navigation width
  },

}));

// eslint-disable-next-line no-unused-vars
const theme = createTheme({
  palette: {
    primary: {
      main: "#2196f3",
    },
    secondary: {
      main: "#2196f3",
    },
  },
});

function Home() {
    const classes = useStyles();
    const [componentToRender, setComponentToRender] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user_name, setUser_name] = useState('');
    const [timeoutId, setTimeoutId] = useState(null);

      // Function to handle session timeout
  const handleSessionTimeout = () => {
    setIsAuthenticated(false);
    setUser_name('');
    // Additional logout logic here (e.g., clearing user data from local storage)
  };
  
    const handleLoginClick = (value) => {
      setUser_name(value);
      setIsAuthenticated(true);
    };
    
    const ClickMenuButton = (value) => {
      setComponentToRender(value);
    };

    const ReturnElement = () => {
      return componentToRender;
    };

    // Function to handle logout
    const handleLogout = () => {
      setIsAuthenticated(false);
      setUser_name('');
      clearTimeout(timeoutId);
    };

    useEffect(() => { 
      ReturnElement();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [componentToRender]);

    const resetTimeout = () => {
      if (isAuthenticated) {
        clearTimeout(timeoutId);
        const sessionTimeoutId = setTimeout(handleSessionTimeout, process.env.REACT_APP_SESSIONTIMEOUT * 60 * 1000); // Reset timer to 30 minutes
        setTimeoutId(sessionTimeoutId);
      }
    };

    useEffect(() => {
      // Set up session timeout when the component mounts
      const sessionTimeoutId = setTimeout(handleSessionTimeout, process.env.REACT_APP_SESSIONTIMEOUT * 60 * 1000); // 30 minutes in milliseconds
      setTimeoutId(sessionTimeoutId);



      // Clear the timeout when the component unmounts or when authentication status changes
      return () => {
        clearTimeout(timeoutId);
        setComponentToRender(null);
      };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    useEffect(() => {       window.addEventListener("mousemove", resetTimeout);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      window.addEventListener("keypress", resetTimeout);}, []);

    return (
      <div className={classes.root} >
      {/* ... */}
        <MasterTopBar isAuthenticated={isAuthenticated} userName={user_name} ClicklogOut={handleLogout}/>
       
        {isAuthenticated ? (
             <Grid container direction="row" style={{ height: "calc(100vh - 100px)" }}>
                <MasterLeftNavigation ClickMenuButton={ClickMenuButton}/>
                <Grid item xs={6} id="renderArea" >
                  <div>
                    &nbsp; &nbsp;
                  </div>
              
                  {componentToRender}
                  {/*<p>Result: {result}</p>f
                 <ImageMap/>*/}
                </Grid>
        
            </Grid>
              ) : (
            <LoginPg setIsAuthenticated={setIsAuthenticated} setLoggedUserName={handleLoginClick} />
          )}
  
      </div>
    )
  }
export default Home;