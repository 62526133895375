import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  useCallback,
} from "react";
import { Grid } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import CustomTextField from "../../control/CustomTextField";
import CrudController from "../../control/CrudController";
import CustomMessageBox from "../../control/CustomMessageBox";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import dayjs from "dayjs";
import api from "../../Api.js";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DynamicSnackBox from "../../control/DynamicPopupSnackBar";

const Orthodontic = forwardRef(
  ({ refModule, updateComponentToRender }, ref) => {
    //#region Variable Section

    useImperativeHandle(ref, () => ({
      handleSearch,
      handleClear,
      save,
      edit,
    }));

    const [, setResetKey] = useState(0);
    // State to control clearing values in CrudController
    const [clearValues, setClearValues] = useState(false);

    const [, setRefClinicNo] = useState(null);
    const [refRctNo, setRefRctNo] = useState(null);

    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");
    const [alertDescription, setAlertDescription] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("");
    const [message, setMessage] = useState("");
    const [isMessageBoxOpen, setMessageBoxOpen] = useState(false);
    const [functionality, setFunctionality] = useState(null);
    const [buttonStates, setButtonStates] = useState({
      search: true,
      edit: true,
      save: true,
      clear: true,
      close: false,
      history: true,
    });

    const [id, setId] = useState(null);
    const [clinicNo, setClinicNo] = useState("");
    const [, setOtherDocNo] = useState("");
    const [dentalClass, setDentalClass] = useState("");
    const [skeletalClass, setSkeletalClass] = useState("");
    const [descriptionProblem, setDescriptionProblem] = useState("");
    const [diagnosis, setDiagnosis] = useState("");
    const [treatmentWith, setTreatmentWith] = useState("");
    const [startedDt, setStartedDt] = useState(null);
    const [finishedDt, setFinishedDt] = useState(null);
    const [other, setOther] = useState("");

    const [createBy, setCreateBy] = useState("");
    const [updateBy, setUpdateBy] = useState("");

    //#endregion Variable Section

    //#region Main Event Handlers

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const clear = useCallback(async (isMsgShow) => {
      if (isMsgShow === true) {
        setClinicNo("");
      }

      setId(null);
      setOtherDocNo("");
      setDentalClass("");
      setSkeletalClass("");
      setDescriptionProblem("");
      setDiagnosis("");
      setTreatmentWith("");
      setStartedDt(null);
      setFinishedDt(null);
      setOther("");

      resetAButton();
      if (isMsgShow === true) handleClearValues();
      if (isMsgShow === true)
        triggerAlertOn("Clear", "Successfully cleaned!", "success");
    });

    const decideOtherDocumentId = (_treatmentId) => {
      if (refModule != null ) if(refModule.refTreatmentId != null){
        // if existing treatment
        return refModule.refTreatmentId;
      } else if (
        (refModule != null &&
          refModule.refTreatmentId == null &&
          _treatmentId != null) ||
        (refModule == null &&
          refModule.refTreatmentId == null &&
          _treatmentId != null)
      ) {
        // if treatment is not exist, use saved id
        return _treatmentId;
      } else {
        // if both not in a value, that is pure ortho
        return null;
      }
    };

    const save = async (_lastId) => {
      try {
        api
          .post(`/orthodontics/save`, {
            clinic_no: clinicNo,
            visit_date: new Date(),
            other_doc_no: decideOtherDocumentId(_lastId),
            app_ref_no: null,
            dental_class: dentalClass,
            skeletal_class: skeletalClass,
            problem_description: descriptionProblem,
            exact_diagnosis: diagnosis,
            treatment_with: treatmentWith,
            started: startedDt,
            finished: finishedDt,
            other: other,
            com_code: sessionStorage.getItem("usercom"),
            create_dt: new Date(),
            create_by: createBy,
          })
          .then(() => {
            clear(true);
            triggerAlertOn("Save", "Successfully saved!", "success");
          })
          .catch((error) => {
            if (error.response.status === 400) {
              triggerAlertOn("Save", error.response.data.error, "error");
            } else {
              triggerAlertOn(
                "Save",
                "There is an error occured while save the record. The error is " +
                  error,
                "error"
              );
            }
          });
      } catch (error) {
        console.error(error);
        triggerAlertOn(
          "Save",
          "There is an error occured while save the record. The error is " +
            error,
          "error"
        );
      }
    };

    const edit = async (_id, _clinicNo) => {
      try {
        api
          .put(`/orthodontics/edit`, {
            id: _id,
            clinic_no: _clinicNo,
            other_doc_no: refModule != null ? refModule.refTreatmentId : null,
            app_ref_no: null,
            dental_class: dentalClass,
            skeletal_class: skeletalClass,
            problem_description: descriptionProblem,
            exact_diagnosis: diagnosis,
            treatment_with: treatmentWith,
            started: startedDt,
            finished: finishedDt,
            other: other,
            com_code: sessionStorage.getItem("usercom"),
            update_dt: new Date(),
            update_by: updateBy,
          })
          .then(() => {
            clear(true);
            triggerAlertOn("Edit", "Successfully edited!", "success");
            if (refModule != null) {
              handleClose();
            }
          })
          .catch((error) => {
            console.error(error);
            triggerAlertOn(
              "Edit",
              "There is an error occured while edit the record. The error is " +
                error,
              "error"
            );
          });
      } catch (error) {
        console.error(error);
        triggerAlertOn(
          "Edit",
          "There is an error occured while edit the record. The error is " +
            error,
          "error"
        );
      }
    };

    const validatePage = () => {
      // Check required fields
      if (!clinicNo) {
        return false;
      }
      // All checks passed, return true
      return true;
    };

    const handleSave = () => {
      if (id !== null) {
        triggerAlertOn(
          "Save",
          "Process terminated. You cannot save this again, try edit! ",
          "error"
        );
        return;
      }
      if (validatePage()) {
        handleButtonClick("Save");
      } else {
        triggerAlertOn(
          "Save",
          "Process terminated. Please check all the required fields! ",
          "error"
        );
      }
    };

    const handleClear = (isNoMsg) => {
      if (isNoMsg === true || refModule != null) {
        clear(false);
      } else {
        handleButtonClick("Clear");
      }
    };

    const handleEdit = () => {
      if (id == null) {
        triggerAlertOn(
          "Edit",
          "Process terminated. Seems this is a new record!",
          "error"
        );
        return;
      }
      handleButtonClick("Edit");
    };

    const handleSearch = async (_id) => {
      try {
        //console.log("clinicNo complains " + clinicNo);
        const response = await api.get(`/orthodontics/byid/${_id}`);
        const data = (await response).data.data;
        //console.log("complains",data[0])
        const clinicData = data.find((item) => item.id == _id);

        clear(false);
        if (clinicData) {
          setId(clinicData.id);
          setClinicNo(clinicData.clinic_no);
          setOtherDocNo(clinicData.other_doc_no);
          setDentalClass(clinicData.dental_class);
          setSkeletalClass(clinicData.skeletal_class);
          setDescriptionProblem(clinicData.problem_description);
          setDiagnosis(clinicData.exact_diagnosis);
          setTreatmentWith(clinicData.treatment_with);
          setStartedDt(clinicData.started);
          setFinishedDt(clinicData.finished);
          setOther(clinicData.other);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const handleClose = () => {
      if (refModule != null) {
        setButtonStates({
          search: true,
          edit: true,
          save: true,
          clear: true,
          close: false,
          history: true,
          scan: true,
        });
        // let refObj = {
        //   refModule: "TRE",
        //   refTreatmentId: null,
        //   refModuleId: null,
        //   refClinicId: clinicNo,
        // };
        setOtherDocNo(null);
        setRefClinicNo(null);
        setRefRctNo(null);
        refModule = null;

        updateComponentToRender(null);
      }
    };

    const handleHistory = () => {
      // eslint-disable-next-line eqeqeq
      if (clinicNo != null && clinicNo != "") {
        handleOpenModal();
      } else {
        triggerAlertOn(
          "History",
          "There are no history records to show.",
          "error"
        );
        return;
      }
    };

    //#endregion Main Event Handlers

    //#region Other Events

    const clearStartedDate = () => {
      setStartedDt(null);
    };

    const clearFinishedDate = () => {
      setFinishedDt(null);
    };

    const handleClinicNoChange = (newClinicNo) => {
      // Handle clinicNo change logic
      //handleClear();
      setClinicNo(newClinicNo);
      setId(null);
    };

    useEffect(() => {
      if (refModule != null) {
        if (refModule.refModuleId != null) {
          setButtonStates({
            search: false,
            edit: true,
            save: false,
            clear: true,
            close: true,
            history: true,
            scan: true,
          });
        } else {
          setButtonStates({
            search: false,
            edit: false,
            save: true,
            clear: true,
            close: true,
            history: false,
          });
        }
        setClinicNo(refModule.refClinicId);
        setOtherDocNo(refModule.refTreatmentId);
        setRefClinicNo(refModule.refClinicId);
        setRefRctNo(refModule.refModuleId);
      } else {
        setButtonStates({
          search: true,
          edit: true,
          save: true,
          clear: true,
          close: false,
          history: true,
          scan: true,
        });
        setOtherDocNo(null);
        setRefClinicNo(null);
        setRefRctNo(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        refModule = null;
      }
    }, [refModule]);

    useEffect(() => {
      async function getRemByID() {
        try {
          //console.log("clinicNo complains " + clinicNo);
          const response = await api.get(`/orthodontics/byid/${refRctNo}`);
          const data = (await response).data.data;
          const clinicData = data.find((item) => item.id === refRctNo);
          clear(false);

          if (clinicData) {
            setId(clinicData.id);
            setClinicNo(clinicData.clinic_no);
            setOtherDocNo(clinicData.other_doc_no);
            setDentalClass(clinicData.dental_class);
            setSkeletalClass(clinicData.skeletal_class);
            setDescriptionProblem(clinicData.problem_description);
            setDiagnosis(clinicData.exact_diagnosis);
            setTreatmentWith(clinicData.treatment_with);
            setStartedDt(clinicData.started);
            setFinishedDt(clinicData.finished);
            setOther(clinicData.other);
          } else {
            //todo:
          }
        } catch (error) {
          console.error(error);
        }
      }

      if (refRctNo != null) {
        getRemByID();
      }
      if (refModule != null) {
        setOtherDocNo(refModule.refTreatmentId);
      }
    }, [refRctNo]);

    //#endregion Other Events

    //#region outside controller compartments

    const handleButtonClick = (buttonId) => {
      if (buttonId === "Save") {
        setMessage("Do you really want to save this RCT?");
      } else if (buttonId === "Edit") {
        setMessage("Do you really want to edit this RCT?");
      } else if (buttonId === "Clear") {
        setMessage("Do you really want to clear this RCT?");
      }
      setFunctionality(buttonId);
      setMessageBoxOpen(true);
    };

    const handleMessageBoxClose = (isPrimaryButtonClicked) => {
      if (isPrimaryButtonClicked && functionality === "Save") {
        save();
      }
      if (isPrimaryButtonClicked && functionality === "Clear") {
        clear(true);
      }

      if (isPrimaryButtonClicked && functionality === "Edit") {
        edit(id, clinicNo);
      }

      // Set isMessageBoxOpen to false to hide the message box
      setMessageBoxOpen(false);
    };

    const triggerAlertOn = async (title, description, severity) => {
      setAlertTitle(title);
      setAlertDescription(description);
      setAlertSeverity(severity);
      setShowAlert(true);

      setTimeout(() => {
        setAlertTitle("");
        setAlertDescription("");
        setAlertSeverity("");
        setShowAlert(false);
      }, 2000);
    };

    useEffect(() => {
      setCreateBy(sessionStorage.getItem("userid"));
      setUpdateBy(sessionStorage.getItem("userid"));
    }, []);

    const resetAButton = () => {
      // Update the key to remount component A and reset its state
      setResetKey((prevKey) => prevKey + 1);
    };

    const handleClearValues = () => {
      // Set clearValues to true to trigger the clearing in CrudController
      setClearValues(true);
    };

    // Callback function to reset clearValues in CrudController
    const handleClearComplete = () => {
      setClearValues(false);
    };

    //#endregion outside controller compartments

    const handleDataFetchStatus = (status) => {
      if (status === false) {
        triggerAlertOn(
          "Search",
          `This is an invalid clinic no. Please check the number and try again.`,
          "error"
        );
        setClinicNo("");
      }
    };

    const searchFields = [
      { label: "Visit #", value: "visit_sequence" },
      { label: "Date", value: "visit_date" },
      { label: "id", value: "id" },
    ];

    const endpointComponent = "orthodontics";
    const endPointRouter = "visit";

    const handleRowSingleClick = (id) => {
      setId(id);
      setOpenModal(false); // Close the modal after selecting a row
      handleSearch(id);
    };

    const handleOpenModal = () => {
      setOpenModal(true);
    };

    const handleCloseModal = () => {
      setOpenModal(false);
    };

    const [openModal, setOpenModal] = useState(false);

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: "16px",
          marginLeft: "16px",
        }}
      >
        <Grid container spacing={2}>
          {/* 1 Row */}
          <CrudController
            onSave={handleSave}
            onClear={handleClear}
            onEdit={handleEdit}
            onSearch={handleSearch}
            onClose={handleClose}
            onHistory={handleHistory}
            clinicNo={clinicNo}
            onClinicNoChange={handleClinicNoChange}
            buttonStates={buttonStates}
            isNameVisible={true}
            clearValues={clearValues}
            onClearComplete={handleClearComplete}
            onDataFetchStatus={handleDataFetchStatus}
            isClinicNoVisible={
              refModule == null || refModule === "undefined" ? true : false
            }
          />

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper
                elevation={0}
                style={{ padding: 0, textAlign: "left", paddingTop: 20 }}
              >
                <CustomTextField
                  label="Dental class"
                  type="text"
                  id="dentalClass"
                  width="774px"
                  value={dentalClass}
                  onChange={(e) => setDentalClass(e.target.value)}
                />
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper elevation={0} style={{ padding: 0, textAlign: "left" }}>
                <CustomTextField
                  label="Skeletal class"
                  type="text"
                  id="skeletalClass"
                  width="774px"
                  value={skeletalClass}
                  onChange={(e) => setSkeletalClass(e.target.value)}
                />
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper elevation={0} style={{ padding: 0, textAlign: "left" }}>
                <CustomTextField
                  label="Patient's description of problem"
                  type="text"
                  id="problem"
                  width="774px"
                  value={descriptionProblem}
                  onChange={(e) => setDescriptionProblem(e.target.value)}
                />
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper elevation={0} style={{ padding: 0, textAlign: "left" }}>
                <CustomTextField
                  label="Exact diagnosis"
                  type="text"
                  id="diagnosis"
                  width="774px"
                  value={diagnosis}
                  onChange={(e) => setDiagnosis(e.target.value)}
                />
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper elevation={0} style={{ padding: 0, textAlign: "left" }}>
                <CustomTextField
                  label="Treatment with"
                  type="text"
                  id="Treatment"
                  width="774px"
                  value={treatmentWith}
                  onChange={(e) => setTreatmentWith(e.target.value)}
                />
              </Paper>
            </Grid>

            <Paper elevation={0} style={{ padding: 10, textAlign: "left" }}>
              <Grid container spacing={10}>
                <Grid item xs={5}>
                  <Paper
                    elevation={0}
                    style={{ padding: 0, textAlign: "left" }}
                  >
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      locale="en"
                    >
                      <DatePicker
                        label="Treatment started"
                        format={process.env.REACT_APP_DATE_FORMAT}
                        style={{ width: "190px" }}
                        value={startedDt != null ? dayjs(startedDt) : null}
                        onChange={(newValue) => setStartedDt(newValue)}
                        clearable // Enable clearable option
                        onAccept={(newValue) => setStartedDt(newValue)}
                        onClear={clearStartedDate}
                      />
                    </LocalizationProvider>
                  </Paper>
                </Grid>

                <Grid item xs={5}>
                  <Paper
                    elevation={0}
                    style={{ padding: 0, textAlign: "left" }}
                  >
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      locale="en"
                    >
                      <DatePicker
                        label="Treatment finished"
                        format={process.env.REACT_APP_DATE_FORMAT}
                        style={{ width: "190px" }}
                        value={finishedDt != null ? dayjs(finishedDt) : null}
                        onChange={(newValue) => setFinishedDt(newValue)}
                        clearable // Enable clearable option
                        onAccept={(newValue) => setFinishedDt(newValue)}
                        onClear={clearFinishedDate}
                      />
                    </LocalizationProvider>
                  </Paper>
                </Grid>
              </Grid>
            </Paper>

            <Grid item xs={12}>
              <Paper elevation={0} style={{ padding: 0, textAlign: "left" }}>
                <CustomTextField
                  label="Other"
                  type="text"
                  id="Other"
                  width="774px"
                  value={other}
                  onChange={(e) => setOther(e.target.value)}
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>

        <CustomMessageBox
          open={isMessageBoxOpen}
          onClose={handleMessageBoxClose}
          title="Patient Registration"
          message={message}
          primaryButtonCaption="OK"
          secondaryButtonCaption="Cancel"
          showPrimaryButton
          showSecondaryButton
        />
        <DynamicSnackBox
          endpointComponent={endpointComponent}
          endPointRouter={endPointRouter}
          columns={searchFields}
          onRowDoubleClick={handleRowSingleClick}
          handleCloseModal={handleCloseModal}
          clinicNo={clinicNo}
          openModal={openModal} // Pass modalOpen state as open prop
          showCloseButton={false}
        />
        {showAlert && (
          <div
            style={{
              position: "fixed",
              top: "155px",
              left: "610px",
              width: 890,
              height: 150,
              transform: "translate(-50%, -50%)",
              zIndex: 1000,
            }}
          >
            <Alert severity={alertSeverity}>
              <AlertTitle style={{ fontSize: "20px" }}>{alertTitle}</AlertTitle>
              <strong style={{ fontSize: "20px" }}>{alertDescription}</strong>
            </Alert>
          </div>
        )}
      </div>
    );
  }
);

export default Orthodontic;
