import React from 'react';

const Card = () => {
  const handlePrint = () => {
    window.print();
  };

  const cardStyle = {
    width: '86mm', // Width of the card
    height: '54mm', // Height of the card
    border: '1px solid #000',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
    boxSizing: 'border-box',
    backgroundColor: '#fff',
    margin: 'auto',
  };

  const buttonStyle = {
    marginTop: '20px',
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
  };

  return (
    <div>
      <div style={cardStyle}>
        <h2>Not implemented yet</h2>      <button onClick={handlePrint} style={buttonStyle}>Print Card</button>
      </div>

    </div>
  );
};

export default Card;
