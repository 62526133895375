import React, { useState,useRef,useEffect   } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

function LiveTimeLine() {
  const calendarRef = useRef(null);
    const [events, setEvents] = useState([
      {
        title: 'Mr.Gankanda',
        start: new Date(2023, 12, 18, 10, 20),
        end: new Date(2023, 12, 18, 10,25),
        style: {},
      },
      {
        title: 'Mrs.Shirosh Romeshika',
        start: new Date(2023, 12, 18, 10, 30),
        end: new Date(2023, 12, 18, 10, 45),
        style: {},
      },
      {
        title: 'Ms.Pavithra Wanniarachchi',
        start: new Date(2023, 12, 18, 10, 50),
        end: new Date(2023, 12, 18, 10, 55),
        style: {},
      },
      {
        title: 'Mr.Gonawala Sunil',
        start: new Date(2023, 12, 18, 11, 0),
        end: new Date(2023, 12, 18, 11, 15),
        style: {},
      },
      {
        title: 'Mr.Kishor Kumar',
        start: new Date(2023, 12, 18, 11, 15),
        end: new Date(2023, 12, 18, 11, 30),
        style: {},
      },
      {
        title: 'Ms.Lochi Dikwella',
        start: new Date(2023, 12, 18, 11, 35),
        end: new Date(2023, 12, 18, 11, 45),
        style: {},
      },
    ]);

    const updateEventStyles = () => {
      const now = moment();
      const updatedEvents = events.map((event) => {
        const isNow = moment(event.start).isBefore(now) && moment(event.end).isAfter(now);
        return {
          ...event,
          style: {
            backgroundColor: isNow ? '#f50057' : '#1976D2',
          },
        };
      });
      setEvents(updatedEvents);
    };
  
    useEffect(() => {
      updateEventStyles();
      const interval = setInterval(() => {
        updateEventStyles();
      }, 1000); // update the styles every second
      return () => clearInterval(interval); // clear the interval when the component unmounts
    }, []);

    const nowIndicatorStyle = {
    
      height: '40px !important',
      
    };

    const customCSS = `
    .rbc-now-indicator {
      background-color: white !important;
    }
  `;
  
    const localizer = momentLocalizer(moment);
  
    const handleViewChange = (view) => {
      const now = new Date();
      if (view === 'day') {
        setTimeout(() => {
          const calendarApi = calendarRef.current.getApi();
          calendarApi.scrollToTime(now.getHours(), now.getMinutes(), now.getSeconds());
        }, 0);
      }
    };

  
    return (
      <div>
         <style>{customCSS}</style>
        <Calendar
          localizer={localizer}
          defaultView="day"
          views={['day']}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 600,width:550 }}
          ref={calendarRef}
          eventPropGetter={(event) => ({ style: event.style,  })}
          nowIndicator={true}
          nowIndicatorStyle={nowIndicatorStyle}
          step={5} // Set the step to 60 minutes (1 hour)
          timeslots={1} // Show one timeslot per hour
          min={new Date(2023, 11, 18, 8, 0)} // Adjust the minimum time to start at 8:00 AM
          max={new Date(2023, 11, 18, 20, 0)} // Adjust the maximum time to end at 8:00 PM
          eventStyleGetter={(event, start, end, isSelected) => {
            const height = 40; // Set the desired height for events (in pixels)
            return {
              style: {
                height,
              },
            };
          }}

        />
      </div>
    );
  }
  
export default LiveTimeLine;