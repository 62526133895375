import React, { useState, useEffect, useRef } from "react";
import { Button, Grid, Paper, Dialog, DialogContent } from "@material-ui/core";
import ImageUploader from "../control/GalaryImageUploader";
import { makeStyles } from "@material-ui/core/styles";
import CrudController from "../control/CrudController";
import api from "../Api.js";
import CustomMessageBox from "../control/CustomMessageBox";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import DynamicSnackBox from "../control/DynamicSnackBox.js";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.primary,
  },
}));

const Gallery = () => {
  const [, setId] = useState(null);

  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const uploaderRef = useRef();
  const classes = useStyles();
  const [clinicNo, setClinicNo] = useState("");
  const [savedImages, setSavedImages] = useState([]);
  const [newImages, setNewImages] = useState([]);

  const [functionality, setFunctionality] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertDescription, setAlertDescription] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [isMessageBoxOpen, setMessageBoxOpen] = useState(false);
  const [buttonStates] = useState({
    search: true,
    edit: false,
    save: true,
    clear: true,
    close: false,
    history: false,
  });
  const [clearValues, setClearValues] = useState(false);

  const [, setCreateBy] = useState("");
  const [, setUpdateBy] = useState("");

  const [selectedRowId, setSelectedRowId] = useState(null); // State to store selected row ID
  const [modalOpen, setModalOpen] = useState(false); // State to manage modal open/close

  const handleRowDoubleClick = (id) => {
    setSelectedRowId(id);
    setModalOpen(false); // Close the modal after selecting a row
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const extractImageExtension = (image) => {
    if (image == null) return "jpg";
    const firstSplit = image.split(";");
    const secondSplit = firstSplit[0].split(":");
    const thirdSplit = secondSplit[1].split("/");
    return thirdSplit[1];
  };

  const dataURItoBlob = (dataURI, imageType) => {
    const mimeType = `image/${imageType}`;
    const byteString = atob(dataURI.split(",")[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeType });
  };

  const uploadImageToServer = async (image, fileType, folderName) => {
    try {
      const formData = new FormData();
      formData.append(
        "folderName",
        folderName + "/" + process.env.REACT_APP_PATIENT_DEFAULT_IMAGE_FOLDER
      );
      formData.append(
        "image",
        dataURItoBlob(image, fileType),
        `image.${fileType}`
      );

      const response = await api.post(`/ClinicImages`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        const result = response.data;
        //console.log("Image uploaded successfully. Image URL:", result.imageUrl);
        return result.imageUrl;
      } else {
        console.error("Failed to upload image:", response.statusText);
      }
    } catch (error) {
      console.error("Error uploading image:", error.message);
    }
  };

  const save = async () => {
    try {
      // Upload new images to the server
      const uploadPromises = newImages.map((image) => {
        const fileType = extractImageExtension(image);
        return uploadImageToServer(image, fileType, clinicNo);
      });

      const uploadedImageUrls = await Promise.all(uploadPromises);
      uploadedImageUrls.flat();

      //Update the database with new image filenames
      //await api.post('/save-images', { clinicNo, filenames: flattenedImageUrls, status: 'active' });

      clear();
      triggerAlertOn("Success", "Images saved successfully", "success");
    } catch (error) {
      triggerAlertOn("Error", "Failed to save images", "error");
      console.error(error);
    }
  };

  const edit = async () => {};

  const clear = async (isMsgShow) => {
    setImages([]);
    setNewImages([]);
    uploaderRef.current.clearImages();
    if (isMsgShow === true) {
      setClinicNo("");
      setId(null);
      handleClearValues();
      triggerAlertOn("Clear", "Successfully cleaned!", "success");
    }
  };

  const handleSave = async () => {
    handleButtonClick("Save");
  };

  const handleClear = (isNoMsg) => {
    if (isNoMsg === true) {
      clear(false);
    } else {
      handleButtonClick("Clear");
    }
  };

  const handleEdit = async () => {
    try {
      const deletePromises = savedImages.map((image) =>
        api.post("/delete-image", { image })
      );
      await Promise.all(deletePromises);

      await handleSave(); // Save new images after deleting the old ones
    } catch (error) {
      triggerAlertOn("Error", "Failed to edit images", "error");
      console.error(error);
    }
  };

  const setDBImager = (image) => {
    if (imageUploaderRef.current) {
      imageUploaderRef.current.setDbImage(image);
    }
  };
  const imageUploaderRef = useRef(null);
  const handleSearch = async () => {
    try {
      let fodlerPath =
        clinicNo + "/" + process.env.REACT_APP_PATIENT_DEFAULT_IMAGE_FOLDER;
      const response = await api.get("/get-images", {
        params: { clinicNo: fodlerPath },
      });
      const fetchedImages = response.data.images.map(
        (file) => `${process.env.REACT_APP_SERVER_PORT}/image/${file}`
      );
      setDBImager(fetchedImages);

      setSavedImages(fetchedImages);
      uploaderRef.current.setDbImages(fetchedImages);
    } catch (error) {
      triggerAlertOn("Error", "Failed to load images", "error");
      console.error(error);
    }
  };

  const handleClinicNoChange = (newClinicNo) => {
    setClinicNo(newClinicNo);
  };

  const addImages = (newImages) => {
    if (newImages.length > 0) {
      setImages([...images, ...newImages]);
      setNewImages([...newImages]);
      if (uploaderRef.current) {
        uploaderRef.current.clearImages();
      }
    }
  };

  //const removeImage = (index) => {
  //  const updatedImages = [...images];
  //  updatedImages.splice(index, 1);
  //  setImages(updatedImages);
  //};

  const removeImages = async (index, isNewImage) => {
    if (isNewImage) {
      const updatedImages = [...images];
      updatedImages.splice(index, 1);
      setImages(updatedImages);
    } else {
      const imageToRemove = savedImages[index];
      //console.log("imageToRemove : "+imageToRemove);
      try {
        await api.delete("/delete-image", { data: { image: imageToRemove } });

        const updatedSavedImages = [...savedImages];
        updatedSavedImages.splice(index, 1);
        setSavedImages(updatedSavedImages);

        const updatedImages = [...images];
        updatedImages.splice(index, 1);
        setImages(updatedImages);
        triggerAlertOn("success", "Successfully removed!", "success");
      } catch (error) {
        console.error("Failed to remove image:", error);
      }
    }
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  const extractFilename = (url) => {
    return url.split("/").pop();
  };

  const imageElements = images.map((image, index) => (
    <Grid
      item
      xs={6}
      sm={4}
      md={3}
      key={index}
      style={{ marginBottom: "20px" }}
    >
      <div style={{ position: "relative" }}>
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <img
          src={image}
          style={{
            width: "100%",
            height: "250px",
            objectFit: "cover",
            cursor: "pointer",
          }}
          onClick={() => handleImageClick(image)}
        />
        <Button
          variant="contained"
          color="secondary"
          style={{ position: "absolute", top: "10px", right: "10px" }}
          onClick={() =>
            removeImages(index, !savedImages.includes(images[index]))
          }
        >
          Remove
        </Button>
        <div style={{ textAlign: "leftcenter", marginTop: "10px" }}>
          {extractFilename(image)}
        </div>
      </div>
    </Grid>
  ));

  useEffect(() => {
    setImages([]);
  }, []);

  //#region outside controller compartments

  const handleButtonClick = (buttonId) => {
    if (buttonId === "Save") {
      setMessage("Do you really want to save this Patient's image references?");
    } else if (buttonId === "Edit") {
      setMessage("Do you really want to edit this Patient's image references?");
    } else if (buttonId === "Clear") {
      setMessage(
        "Do you really want to clear this Patient's image references?"
      );
    }
    setFunctionality(buttonId);
    setMessageBoxOpen(true);
  };

  const handleMessageBoxClose = (isPrimaryButtonClicked) => {
    if (isPrimaryButtonClicked && functionality === "Save") {
      save();
    }
    if (isPrimaryButtonClicked && functionality === "Clear") {
      clear(true);
    }

    if (isPrimaryButtonClicked && functionality === "Edit") {
      edit();
    }

    // Set isMessageBoxOpen to false to hide the message box
    setMessageBoxOpen(false);
  };

  const triggerAlertOn = async (title, description, severity) => {
    setAlertTitle(title);
    setAlertDescription(description);
    setAlertSeverity(severity);
    setShowAlert(true);

    setTimeout(() => {
      setAlertTitle("");
      setAlertDescription("");
      setAlertSeverity("");
      setShowAlert(false);
    }, 2000);
  };

  useEffect(() => {
    setCreateBy(sessionStorage.getItem("userid"));
    setUpdateBy(sessionStorage.getItem("userid"));
  }, []);

  const handleClearValues = () => {
    // Set clearValues to true to trigger the clearing in CrudController
    setClearValues(true);
  };

  // Callback function to reset clearValues in CrudController
  const handleClearComplete = () => {
    setClearValues(false);
  };

  const handleDataFetchStatus = (status) => {
    if (status === false)
      triggerAlertOn("Search", `There is no data to be found.`, "warning");
  };

  const searchFields = [
    { label: "Visit #", value: "visit_sequence" },
    { label: "Date", value: "visit_date" },
    { label: "id", value: "id" },
  ];

  const endpointComponent = "treatment";
  const endPointRouter = "visitsbyclinicno";

  //#endregion outside controller compartments

  return (
    <div
    style={{
      display: "flex",
      flexDirection: "row",
      marginTop: "3px",
      marginLeft: "3px",
    }}
  >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper elevation={0} style={{ padding: 5, textAlign: "left" }}>
            <CrudController
              onSave={handleSave}
              onClear={handleClear}
              onEdit={handleEdit}
              onSearch={handleSearch}
              onClose={handleClose}
              onHistory={() => {}}
              clinicNo={clinicNo}
              onClinicNoChange={handleClinicNoChange}
              buttonStates={buttonStates}
              isNameVisible={true}
              clearValues={clearValues}
              onClearComplete={handleClearComplete}
              onDataFetchStatus={handleDataFetchStatus}
              isClinicNoVisible={true}
              isDoSearch={true}
            />
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={0} style={{ padding: 5, textAlign: "left" }}>
            <ImageUploader ref={uploaderRef} onImageChange={addImages} />
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={0} style={{ padding: 5, textAlign: "left" }}>
            <Grid container spacing={2}>
              {imageElements}
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Dialog open={!!selectedImage} onClose={handleClose} maxWidth="md">
        <DialogContent>
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Selected"
              style={{ width: "100%", height: "auto" }}
            />
          )}
        </DialogContent>
      </Dialog>
      <CustomMessageBox
        open={isMessageBoxOpen}
        onClose={handleMessageBoxClose}
        title="Patient's Gallery"
        message={message}
        primaryButtonCaption="OK"
        secondaryButtonCaption="Cancel"
        showPrimaryButton
        showSecondaryButton
      />
      {showAlert && (
        <div
          style={{
            position: "fixed",
            top: "155px",
            left: "610px",
            width: 890,
            height: 150,
            transform: "translate(-50%, -50%)",
            zIndex: 1000,
          }}
        >
          <Alert severity={alertSeverity}>
            <AlertTitle style={{ fontSize: "20px" }}>{alertTitle}</AlertTitle>
            <strong style={{ fontSize: "20px" }}>{alertDescription}</strong>
          </Alert>
        </div>
      )}

      {selectedRowId && (
        <div>
          Selected Row ID: {selectedRowId}
          {/* Display additional details or perform actions based on the selected row ID */}
        </div>
      )}
    </div>
  );
};

export default Gallery;
