import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import InputLabel from '@material-ui/core/InputLabel';

const FourButtonQuadrant = ({ initialString, onButtonClick, titleName }) => {
    const [buttonState, setButtonState] = useState(initialString.split('').map(Number));
  
    useEffect(() => {
      setButtonState(initialString.split('').map(Number));
    }, [initialString]);
  
    const handleButtonClick = (index) => {
      const newButtonState = buttonState.map((value, i) => (i === index ? 1 - value : value));
      setButtonState(newButtonState);
      onButtonClick(newButtonState.join(''));
    };
  
    const buttonStyle = {
      height: 25, // Set the desired height
      width: 80,  // Set the desired width
    };
  
    return (
      <Grid container>
        {/* r 1 */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper elevation={0} style={{ padding: 5, textAlign: 'left' }}>
              <InputLabel id="title-label">{titleName}</InputLabel>
            </Paper>
          </Grid>
        </Grid>
  
        {/* First Column */}
        <Grid item xs={'auto'}>
          <Paper elevation={0} style={{ padding: 1, textAlign: 'right' }}>
            <Button
              style={buttonStyle}
              variant={buttonState[0] === 1 ? 'contained' : 'outlined'}
              color={buttonState[0] === 1 ? 'secondary' : 'primary'}
              onClick={() => handleButtonClick(0)}
            >
              UL
            </Button>
          </Paper>
  
          <Paper elevation={2} style={{ padding: 1, textAlign: 'right' }}>
            <Button
              style={buttonStyle}
              variant={buttonState[3] === 1 ? 'contained' : 'outlined'}
              color={buttonState[3] === 1 ? 'secondary' : 'primary'}
              onClick={() => handleButtonClick(3)}
            >
              LL
            </Button>
          </Paper>
        </Grid>
  
        {/* Second Column */}
        <Grid item xs={'auto'}>
          <Paper elevation={0} style={{ padding: 1, textAlign: 'left' }}>
            <Button
              style={buttonStyle}
              variant={buttonState[1] === 1 ? 'contained' : 'outlined'}
              color={buttonState[1] === 1 ? 'secondary' : 'primary'}
              onClick={() => handleButtonClick(1)}
            >
              UR
            </Button>
          </Paper>
  
          <Paper elevation={0} style={{ padding: 1, textAlign: 'left' }}>
            <Button
              style={buttonStyle}
              variant={buttonState[2] === 1 ? 'contained' : 'outlined'}
              color={buttonState[2] === 1 ? 'secondary' : 'primary'}
              onClick={() => handleButtonClick(2)}
            >
              LP
            </Button>
          </Paper>
        </Grid>
      </Grid>
    );
  };
  
  export default FourButtonQuadrant;
