import React, { useState, useEffect } from "react";
import CustomButton from "./CustomButton";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  row: {
    display: "flex",
    flexDirection: "row",
    gap: "5px", // Adjust the gap as needed
  },
  container: {
    marginBottom: "20px", // Adjust the margin between rows
  },
  rightAlign: {
    textAlign: "right",
  },
  leftAlign: {
    textAlign: "left",
  },
  table: {
    border: "none",
  },
  cell: {
    border: "none",
  },
  button: {
    display: "flex",
    flexDirection: "row",
    gap: "2px", // Adjust the gap as needed
  },
}));

const AdvanceTooth = ({ initialButtonString, ClickSelectedDictionary }) => {
  //Parameter area
  const classes = useStyles();
  const ParentToothCount = 8;
  const ChildToothCount = 5;

  const initialButtonValues = initialButtonString.split(",").map((item) => {
    const [key, value] = item.split(".");
    return { key: parseInt(key), value };
  });

  const [myDictionary, setMyDictionary] = useState(
    initialButtonValues.reduce((acc, { key, value }) => {
      acc[key] = value;
      return acc;
    }, {})
  );

  const updateValueForKey = (key, value) => {
    setMyDictionary((prevDictionary) => ({
      ...prevDictionary,
      [key]: value,
    }));
  };

  const getValueForKey = (key) => {
    return myDictionary[key];
  };

  const handleButtonClick = (controllerIndex, value) => {
    updateValueForKey(controllerIndex, value);
  };

  const convertDictionaryToString = (dictionary) => {
    return Object.entries(dictionary)
      .map(([key, value]) => `${key}.${value}`)
      .join(",");
  };

  //Component functions
  useEffect(() => {
    ClickSelectedDictionary(convertDictionaryToString(myDictionary));
  }, [myDictionary]);

  return (
    <div
      style={{
        display: "relative",
        transform: "scale(0.815)",
        transformOrigin: "top left",
      }}
    >
      {/* Zero Row */}
      <div className={classes.container}>
        <div
          className={classes.row}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {[...Array(ChildToothCount)].map((_, index) => (
            <div key={55 - index} className={classes.button}>
              <CustomButton
                controllerIndex={55 - index}
                onClick={handleButtonClick}
                InitialToothPartal={getValueForKey(55 - index)}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
          ))}
          &nbsp;&nbsp;&nbsp;
          {[...Array(ChildToothCount)].map((_, index) => (
            <div key={61 + index} className={classes.button}>
              <CustomButton
                controllerIndex={61 + index}
                onClick={handleButtonClick}
                InitialToothPartal={getValueForKey(61 + index)}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
          ))}
        </div>
      </div>
      {/* First Row */}
      <div className={classes.container}>
        <div className={classes.row}>
          {[...Array(ParentToothCount)].map((_, index) => (
            <div key={18 - index} className={classes.button}>
              <CustomButton
                controllerIndex={18 - index}
                onClick={handleButtonClick}
                InitialToothPartal={getValueForKey(18 - index)}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
          ))}
          &nbsp;&nbsp;&nbsp;
          {[...Array(ParentToothCount)].map((_, index) => (
            <div key={21 + index} className={classes.button}>
              <CustomButton
                controllerIndex={21 + index}
                onClick={handleButtonClick}
                InitialToothPartal={getValueForKey(21 + index)}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
          ))}
        </div>
      </div>

      {/* Second Row */}
      <div className={classes.container}>
        <div className={classes.row}>
          {[...Array(ParentToothCount)].map((_, index) => (
            <div key={48 - index} className={classes.button}>
              <CustomButton
                controllerIndex={48 - index}
                onClick={handleButtonClick}
                InitialToothPartal={getValueForKey(48 - index)}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
          ))}
          &nbsp;&nbsp;&nbsp;
          {[...Array(ParentToothCount)].map((_, index) => (
            <div key={31 + index} className={classes.button}>
              <CustomButton
                controllerIndex={31 + index}
                onClick={handleButtonClick}
                InitialToothPartal={getValueForKey(31 + index)}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
          ))}
        </div>
      </div>
      {/* Zero end Row */}
      <div className={classes.container}>
        <div
          className={classes.row}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {[...Array(ChildToothCount)].map((_, index) => (
            <div key={85 - index} className={classes.button}>
              <CustomButton
                controllerIndex={85 - index}
                onClick={handleButtonClick}
                InitialToothPartal={getValueForKey(85 - index)}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
          ))}
          &nbsp;&nbsp;&nbsp;
          {[...Array(ChildToothCount)].map((_, index) => (
            <div key={71 + index} className={classes.button}>
              <CustomButton
                controllerIndex={71 + index}
                onClick={handleButtonClick}
                InitialToothPartal={getValueForKey(71 + index)}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdvanceTooth;
