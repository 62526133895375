import React, { useEffect, useRef, useState } from 'react';
import jsQR from 'jsqr';

const QRCodeScanner = ({ onQRCodeDetected, isScanning, setIsScanning }) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [error, setError] = useState('');

  useEffect(() => {
    let stream = null;

    const startCamera = async () => {
      try {
        stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: 'environment', width: 1280, height: 720 }, // Use higher resolution
        });

        if (videoRef.current) {
          videoRef.current.srcObject = stream;

          videoRef.current.onloadedmetadata = () => {
            videoRef.current.play();
          };
        }
      } catch (err) {
        setError('Could not access camera.');
        console.error(err);
      }
    };

    if (isScanning) {
      startCamera();
      const intervalId = setInterval(() => {
        scanQRCode();
      }, 5); // Scan every 500ms

      return () => {
        clearInterval(intervalId);
        stopCamera(stream);
      };
    }

    return () => stopCamera(stream);
  }, [isScanning]);

  const scanQRCode = () => {
    const canvas = canvasRef.current;
    const video = videoRef.current;

    if (video && canvas && video.videoWidth > 0 && video.videoHeight > 0) {
      const context = canvas.getContext('2d', { willReadFrequently: true });
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
      const code = jsQR(imageData.data, canvas.width, canvas.height);

      if (code) {
        stopCamera(video.srcObject);
        setIsScanning(false);
        onQRCodeDetected(code.data); // Pass detected QR code data
      }
    }
  };

  const stopCamera = (stream) => {
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }
    }
  };

  return (
    <div>
      <video ref={videoRef} style={{ display: isScanning ? 'block' : 'none', width: '100%' }} />
      <canvas ref={canvasRef} style={{ display: 'none' }} />
      {error && <p>{error}</p>}
    </div>
  );
};

export default QRCodeScanner;
