import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  useCallback,
} from "react";
import { Grid, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import CustomTextField from "../../control/CustomTextField";
import CrudController from "../../control/CrudController";
import Tooth from "../../control/Tooth.js";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { blue, blueGrey } from "@material-ui/core/colors";
import CustomMessageBox from "../../control/CustomMessageBox";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import dayjs from "dayjs";
import { Select, MenuItem } from "@material-ui/core";
import api from "../../Api.js";
import InputLabel from "@material-ui/core/InputLabel";
import DynamicSnackBox from "../../control/DynamicPopupSnackBar";

const Rct = forwardRef(({ refModule, updateComponentToRender }, ref) => {
  useImperativeHandle(ref, () => ({
    handleSearch,
    handleClear,
    save,
    edit,
  }));

  //#region Variable Section

  const [resetKey, setResetKey] = useState(0);
  // State to control clearing values in CrudController
  const [clearValues, setClearValues] = useState(false);

  const [, setRefClinicNo] = useState(null);
  const [refRctNo, setRefRctNo] = useState(null);

  const examTeethInitCommon = process.env.REACT_APP_NORMAL_TOOTH_SETTING;

  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertDescription, setAlertDescription] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [isMessageBoxOpen, setMessageBoxOpen] = useState(false);
  const [functionality, setFunctionality] = useState(null);
  const [buttonStates, setButtonStates] = useState({
    search: true,
    edit: true,
    save: true,
    clear: true,
    close: false,
    history: false,
  });

  const [id, setId] = useState(null);
  const [clinicNo, setClinicNo] = useState("");
  const [, setOtherDocNo] = useState(null);
  const [teeth, setTeeth] = useState(examTeethInitCommon);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  //#region row 1
  const [isChk, setIsChk] = useState(false);
  const [isMMChk, setIsMMChk] = useState(false);
  const [isISOChk, setIsISOChk] = useState(false);
  const [isMM, setIsMM] = useState(null);
  const [isISO, setIsISO] = useState(null);

  const [fsChk, setFsChk] = useState(false);
  const [fsMMChk, setFsMMChk] = useState(false);
  const [fsISOChk, setFsISOChk] = useState(false);
  const [fsMM, setFsMM] = useState(null);
  const [fsISO, setFsISO] = useState(null);
  //#endregion row 1

  //#region row 2
  const [ibChk, setIbChk] = useState(false);
  const [ibMMChk, setIbMMChk] = useState(false);
  const [ibISOChk, setIbISOChk] = useState(false);
  const [ibMM, setIbMM] = useState(null);
  const [ibISO, setIbISO] = useState(null);

  const [fbChk, setFbChk] = useState(false);
  const [fbMMChk, setFbMMChk] = useState(false);
  const [fbISOChk, setFbISOChk] = useState(false);
  const [fbMM, setFbMM] = useState(null);
  const [fbISO, setFbISO] = useState(null);
  //#endregion row 2

  //#region row 3
  const [ipChk, setIpChk] = useState(false);
  const [ipMMChk, setIpMMChk] = useState(false);
  const [ipISOChk, setIpISOChk] = useState(false);
  const [ipMM, setIpMM] = useState(null);
  const [ipISO, setIpISO] = useState(null);

  const [fpChk, setFpChk] = useState(false);
  const [fpMMChk, setFpMMChk] = useState(false);
  const [fpISOChk, setFpISOChk] = useState(false);
  const [fpMM, setFpMM] = useState(null);
  const [fpISO, setFpISO] = useState(null);
  //#endregion row 3

  //#region row 4
  const [ilChk, setIlChk] = useState(false);
  const [ilMMChk, setIlMMChk] = useState(false);
  const [ilISOChk, setIlISOChk] = useState(false);
  const [ilMM, setIlMM] = useState(null);
  const [ilISO, setIlISO] = useState(null);

  const [flChk, setFlChk] = useState(false);
  const [flMMChk, setFlMMChk] = useState(false);
  const [flISOChk, setFlISOChk] = useState(false);
  const [flMM, setFlMM] = useState(null);
  const [flISO, setFlISO] = useState(null);
  //#endregion row 4

  //#region row 5
  const [imbChk, setImbChk] = useState(false);
  const [imbMMChk, setImbMMChk] = useState(false);
  const [imbISOChk, setImbISOChk] = useState(false);
  const [imbMM, setImbMM] = useState(null);
  const [imbISO, setImbISO] = useState(null);

  const [fmbChk, setFmbChk] = useState(false);
  const [fmbMMChk, setFmbMMChk] = useState(false);
  const [fmbISOChk, setFmbISOChk] = useState(false);
  const [fmbMM, setFmbMM] = useState(null);
  const [fmbISO, setFmbISO] = useState(null);
  //#endregion row 5

  //#region row 6
  const [idChk, setIdChk] = useState(false);
  const [idMMChk, setIdMMChk] = useState(false);
  const [idISOChk, setIdISOChk] = useState(false);
  const [idMM, setIdMM] = useState(null);
  const [idISO, setIdISO] = useState(null);

  const [fdChk, setFdChk] = useState(false);
  const [fdMMChk, setFdMMChk] = useState(false);
  const [fdISOChk, setFdISOChk] = useState(false);
  const [fdMM, setFdMM] = useState(null);
  const [fdISO, setFdISO] = useState(null);
  //#endregion row 6

  //#region row 7
  const [imlChk, setImlChk] = useState(false);
  const [imlMMChk, setImlMMChk] = useState(false);
  const [imlISOChk, setImlISOChk] = useState(false);
  const [imlMM, setImlMM] = useState(null);
  const [imlISO, setImlISO] = useState(null);

  const [fmlChk, setFmlChk] = useState(false);
  const [fmlMMChk, setFmlMMChk] = useState(false);
  const [fmlISOChk, setFmlISOChk] = useState(false);
  const [fmlMM, setFmlMM] = useState(null);
  const [fmlISO, setFmlISO] = useState(null);
  //#endregion row 7

  //#region row 8
  const [special, setSpecial] = useState("");
  const [crown, setCrown] = useState("");
  //#endregion row 8

  const [createBy, setCreateBy] = useState("");
  const [updateBy, setUpdateBy] = useState("");

  //#endregion Variable Section

  //#region Main Event Handlers

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const clear = useCallback(async (isMsgShow) => {
    if (isMsgShow === true) {
      setClinicNo("");
      setId(null);
    }

    setTeeth(examTeethInitCommon);
    setStartDate(null);
    setEndDate(null);

    //#region row 1
    setIsChk(false);
    setIsMMChk(false);
    setIsISOChk(false);
    setIsMM(null);
    setIsISO(null);

    setFsChk(false);
    setFsMMChk(false);
    setFsISOChk(false);
    setFsMM(null);
    setFsISO(null);
    //#endregion row 1

    //#region row 2
    setIbChk(false);
    setIbMMChk(false);
    setIbISOChk(false);
    setIbMM(null);
    setIbISO(null);

    setFbChk(false);
    setFbMMChk(false);
    setFbISOChk(false);
    setFbMM(null);
    setFbISO(null);
    //#endregion row 2

    //#region row 3
    setIpChk(false);
    setIpMMChk(false);
    setIpISOChk(false);
    setIpMM(null);
    setIpISO(null);

    setFpChk(false);
    setFpMMChk(false);
    setFpISOChk(false);
    setFpMM(null);
    setFpISO(null);
    //#endregion row 3

    //#region row 4
    setIlChk(false);
    setIlMMChk(false);
    setIlISOChk(false);
    setIlMM(null);
    setIlISO(null);

    setFlChk(false);
    setFlMMChk(false);
    setFlISOChk(false);
    setFlMM(null);
    setFlISO(null);
    //#endregion row 4

    //#region row 5
    setImbChk(false);
    setImbMMChk(false);
    setImbISOChk(false);
    setImbMM(null);
    setImbISO(null);

    setFmbChk(false);
    setFmbMMChk(false);
    setFmbISOChk(false);
    setFmbMM(null);
    setFmbISO(null);
    //#endregion row 5

    //#region row 6
    setIdChk(false);
    setIdMMChk(false);
    setIdISOChk(false);
    setIdMM(null);
    setIdISO(null);

    setFdChk(false);
    setFdMMChk(false);
    setFdISOChk(false);
    setFdMM(null);
    setFdISO(null);
    //#endregion row 6

    //#region row 7
    setImlChk(false);
    setImlMMChk(false);
    setImlISOChk(false);
    setImlMM(null);
    setImlISO(null);

    setFmlChk(false);
    setFmlMMChk(false);
    setFmlISOChk(false);
    setFmlMM(null);
    setFmlISO(null);
    //#endregion row 7

    //#region row 8
    setSpecial("");
    setCrown("");
    //#endregion row 8

    resetAButton();
    if (isMsgShow === true) handleClearValues();
    if (isMsgShow === true)
      triggerAlertOn("Clear", "Successfully cleaned!", "success");
  });

  const decideOtherDocumentId = (_treatmentId) => {
    if (refModule != null  ) if(refModule.refTreatmentId != null){
      // if existing treatment
      return refModule.refTreatmentId;
    } else if (
      (refModule != null &&
        refModule.refTreatmentId == null &&
        _treatmentId != null) ||
      (refModule == null &&
        refModule.refTreatmentId == null &&
        _treatmentId != null)
    ) {
      // if treatment is not exist, use saved id
      return _treatmentId;
    } else {
      // if both not in a value, that is pure ortho
      return null;
    }
  };

  const save = async (_lastId) => {
    try {
      api
        .post(`/rct/save`, {
          clinic_no: clinicNo,
          visit_date: new Date(),
          other_doc_no: decideOtherDocumentId(_lastId),
          app_ref_no: null,
          teeth: teeth === examTeethInitCommon ? null : teeth,
          start_date: startDate,
          end_date: endDate,
          is_mm: isMM,
          is_iso: isISO,
          fs_mm: fsMM,
          fs_iso: fsISO,
          ib_mm: ibMM,
          ib_iso: ibISO,
          fb_mm: fbMM,
          fb_iso: fbISO,
          ip_mm: ipMM,
          ip_iso: ipISO,
          fp_mm: fpMM,
          fp_iso: fpISO,
          il_mm: ilMM,
          il_iso: ilISO,
          fl_mm: flMM,
          fl_iso: flISO,
          imb_mm: imbMM,
          imb_iso: imbISO,
          fmb_mm: fmbMM,
          fmb_iso: fmbISO,
          id_mm: idMM,
          id_iso: idISO,
          fd_mm: fdMM,
          fd_iso: fdISO,
          iml_mm: imlMM,
          iml_iso: imlISO,
          fml_mm: fmlMM,
          fml_iso: fmlISO,
          special: special,
          crown: crown,
          com_code: sessionStorage.getItem("usercom"),
          create_dt: new Date(),
          create_by: createBy,
        })
        .then(() => {
          clear(true);
          triggerAlertOn("Save", "Successfully saved!", "success");
        })
        .catch((error) => {
          if (error.response.status === 400) {
            triggerAlertOn("Save", error.response.data.error, "error");
          } else {
            triggerAlertOn(
              "Save",
              "There is an error occured while save the record. The error is " +
                error,
              "error"
            );
          }
        });
    } catch (error) {
      console.error(error);
      triggerAlertOn(
        "Save",
        "There is an error occured while save the record. The error is " +
          error,
        "error"
      );
    }
  };

  const edit = async () => {
    try {
      api
        .put(`/rct/edit`, {
          id: id,
          clinic_no: clinicNo,
          other_doc_no: refModule != null ? refModule.refTreatmentId : null,
          app_ref_no: null,
          teeth: teeth === examTeethInitCommon ? null : teeth,
          start_date: startDate,
          end_date: endDate,
          is_mm: isMM,
          is_iso: isISO,
          fs_mm: fsMM,
          fs_iso: fsISO,
          ib_mm: ibMM,
          ib_iso: ibISO,
          fb_mm: fbMM,
          fb_iso: fbISO,
          ip_mm: ipMM,
          ip_iso: ipISO,
          fp_mm: fpMM,
          fp_iso: fpISO,
          il_mm: ilMM,
          il_iso: ilISO,
          fl_mm: flMM,
          fl_iso: flISO,
          imb_mm: imbMM,
          imb_iso: imbISO,
          fmb_mm: fmbMM,
          fmb_iso: fmbISO,
          id_mm: idMM,
          id_iso: idISO,
          fd_mm: fdMM,
          fd_iso: fdISO,
          iml_mm: imlMM,
          iml_iso: imlISO,
          fml_mm: fmlMM,
          fml_iso: fmlISO,
          special: special,
          crown: crown,
          update_dt: new Date(),
          update_by: updateBy,
        })
        .then(() => {
          clear(true);
          triggerAlertOn("Edit", "Successfully edited!", "success");
          if (refModule != null) {
            handleClose();
          }
        })
        .catch((error) => {
          console.error(error);
          triggerAlertOn(
            "Edit",
            "There is an error occured while edit the record. The error is " +
              error,
            "error"
          );
        });
    } catch (error) {
      console.error(error);
      triggerAlertOn(
        "Edit",
        "There is an error occured while edit the record. The error is " +
          error,
        "error"
      );
    }
  };

  const validatePage = () => {
    // Check required fields
    if (!clinicNo) {
      return false;
    }

    if (endDate != null)
      if (endDate < startDate) {
        triggerAlertOn(
          "Edit",
          "Process terminated. Start date and end date is mismatched.",
          "error"
        );
        return false;
      }

    // All checks passed, return true
    return true;
  };

  const handleSave = () => {
    if (id != null) {
      triggerAlertOn(
        "Save",
        "Process terminated. You cannot save this again, try edit! ",
        "error"
      );
      return;
    }

    if (validatePage()) {
      handleButtonClick("Save");
    } else {
      triggerAlertOn(
        "Save",
        "Process terminated. Please check all the required fields! ",
        "error"
      );
    }
  };

  const handleClear = (isNoMsg) => {
    if (isNoMsg === true) {
      clear(false);
    } else {
      handleButtonClick("Clear");
    }
  };

  const handleEdit = () => {
    if (id === null) {
      triggerAlertOn(
        "Edit",
        "Process terminated. Seems this is a new record!",
        "error"
      );
      return;
    }
    handleButtonClick("Edit");
  };

  const handleSearch = async (_id) => {
    try {
      //console.log("clinicNo complains " + clinicNo);
      const response = await api.get(`/rct/byid/${_id}`);
      const data = (await response).data.data;
      //console.log("complains",data[0])
      const clinicData = data.find((item) => item.id === _id);

      clear(false);
      if (clinicData) {
        setId(clinicData.id);
        setClinicNo(clinicData.clinic_no);
        setTeeth(
          clinicData.teeth === null ? examTeethInitCommon : clinicData.teeth
        );

        setStartDate(
          clinicData.start_date !== null ? dayjs(clinicData.start_date) : null
        );
        setEndDate(
          clinicData.end_date !== null ? dayjs(clinicData.end_date) : null
        );

        //#region row 1
        if (clinicData.is_mm !== null || clinicData.is_iso !== null) {
          setIsChk(true);
          if (clinicData.is_mm !== null) {
            setIsMMChk(true);
            setIsMM(clinicData.is_mm);
          }
          if (clinicData.is_iso !== null) {
            setIsISOChk(true);
            setIsISO(clinicData.is_iso);
          }
        }

        if (clinicData.fs_mm !== null || clinicData.fs_iso !== null) {
          setFsChk(true);
          if (clinicData.fs_mm !== null) {
            setFsMMChk(true);
            setFsMM(clinicData.fs_mm);
          }
          if (clinicData.fs_iso !== null) {
            setFsISOChk(true);
            setFsISO(clinicData.fs_iso);
          }
        }

        //#endregion row 1

        //#region row 2

        if (clinicData.ib_mm !== null || clinicData.ib_iso !== null) {
          setIbChk(true);
          if (clinicData.ib_mm != null) {
            setIbMMChk(true);
            setIbMM(clinicData.ib_mm);
          }
          if (clinicData.ib_iso != null) {
            setIbISOChk(true);
            setIbISO(clinicData.ib_iso);
          }
        }

        if (clinicData.fb_mm != null || clinicData.fb_iso != null) {
          setFbChk(true);
          if (clinicData.fb_mm != null) {
            setFbMMChk(true);
            setFbMM(clinicData.fb_mm);
          }
          if (clinicData.fb_iso != null) {
            setFbISOChk(true);
            setFbISO(clinicData.fb_iso);
          }
        }

        //#endregion row 2

        //#region row 3

        if (clinicData.ip_mm != null || clinicData.ip_iso != null) {
          setIpChk(true);
          if (clinicData.ip_mm != null) {
            setIpMMChk(true);
            setIpMM(clinicData.ip_mm);
          }
          if (clinicData.ip_iso != null) {
            setIpISOChk(true);
            setIpISO(clinicData.ip_iso);
          }
        }

        if (clinicData.fp_mm != null || clinicData.fp_iso != null) {
          setFpChk(true);
          if (clinicData.fp_mm != null) {
            setFpMMChk(true);
            setFpMM(clinicData.fp_mm);
          }
          if (clinicData.fp_iso != null) {
            setFpISOChk(true);
            setFpISO(clinicData.fp_iso);
          }
        }

        //#endregion row 3

        //#region row 4

        if (clinicData.il_mm != null || clinicData.il_iso != null) {
          setIlChk(true);
          if (clinicData.il_mm != null) {
            setIlMMChk(true);
            setIlMM(clinicData.il_mm);
          }
          if (clinicData.il_iso != null) {
            setIlISOChk(true);
            setIlISO(clinicData.il_iso);
          }
        }

        if (clinicData.fl_mm != null || clinicData.fl_iso != null) {
          setFlChk(true);
          if (clinicData.fl_mm != null) {
            setFlMMChk(true);
            setFlMM(clinicData.fl_mm);
          }
          if (clinicData.fl_iso != null) {
            setFlISOChk(true);
            setFlISO(clinicData.fl_iso);
          }
        }

        //#endregion row 4

        //#region row 5

        if (clinicData.imb_mm != null || clinicData.imb_iso != null) {
          setImbChk(true);
          if (clinicData.imb_mm != null) {
            setImbMMChk(true);
            setImbMM(clinicData.imb_mm);
          }
          if (clinicData.imb_iso != null) {
            setImbISOChk(true);
            setImbISO(clinicData.imb_iso);
          }
        }

        if (clinicData.fmb_mm != null || clinicData.fmb_iso != null) {
          setFmbChk(true);
          if (clinicData.fmb_mm != null) {
            setFmbMMChk(true);
            setFmbMM(clinicData.fmb_mm);
          }
          if (clinicData.fmb_iso != null) {
            setFmbISOChk(true);
            setFmbISO(clinicData.fmb_iso);
          }
        }

        //#endregion row 5

        //#region row 6

        if (clinicData.id_mm != null || clinicData.id_iso != null) {
          setIdChk(true);
          if (clinicData.id_mm != null) {
            setIdMMChk(true);
            setIdMM(clinicData.id_mm);
          }
          if (clinicData.id_iso != null) {
            setIdISOChk(true);
            setIdISO(clinicData.id_iso);
          }
        }

        if (clinicData.fd_mm != null || clinicData.fd_iso != null) {
          setFdChk(true);
          if (clinicData.fd_mm != null) {
            setFdMMChk(true);
            setFdMM(clinicData.fd_mm);
          }
          if (clinicData.fd_iso != null) {
            setFdISOChk(true);
            setFdISO(clinicData.fd_iso);
          }
        }

        //#endregion row 6

        //#region row 7

        if (clinicData.iml_mm != null || clinicData.iml_iso != null) {
          setImlChk(true);
          if (clinicData.iml_mm != null) {
            setImlMMChk(true);
            setImlMM(clinicData.iml_mm);
          }
          if (clinicData.iml_iso != null) {
            setImlISOChk(true);
            setImlISO(clinicData.iml_iso);
          }
        }

        if (clinicData.fml_mm != null || clinicData.fml_iso != null) {
          setFmlChk(true);
          if (clinicData.fml_mm != null) {
            setFmlMMChk(true);
            setFmlMM(clinicData.fml_mm);
          }
          if (clinicData.fml_iso != null) {
            setFmlISOChk(true);
            setFmlISO(clinicData.fml_iso);
          }
        }

        //#endregion row 7

        //#region row 8
        setSpecial(clinicData.special);
        setCrown(clinicData.crown);
        //#endregion row 8
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    if (refModule != null) {
      setButtonStates({
        search: true,
        edit: true,
        save: true,
        clear: true,
        close: false,
        history: true,
        scan: true,
      });
      let refObj = {
        refModule: "TRE",
        refTreatmentId: null,
        refModuleId: null,
        refClinicId: clinicNo,
      };
      setOtherDocNo(null);
      setRefClinicNo(null);
      setRefRctNo(null);
      refModule = null;
      updateComponentToRender(refObj);
    }
  };

  const handleHistory = () => {
    // Handle search logic
    // eslint-disable-next-line eqeqeq
    if (clinicNo != null && clinicNo != "") {
      handleOpenModal();
    } else {
      triggerAlertOn(
        "History",
        "There are no history records to show.",
        "error"
      );
      return;
    }
  };

  //#endregion Main Event Handlers

  //#region Other Events

  const handleIsChk = (result) => {
    setIsChk(result.target.checked);
    if (result.target.checked === false) {
      setIsMMChk(result.target.checked);
      setIsISOChk(result.target.checked);
      setIsMM(null);
      setIsISO(null);
    }
  };

  const handleIsMMChk = (result) => {
    setIsMMChk(result.target.checked);
    if (result.target.checked === false) {
      setIsMM(null);
    }
  };

  const handleIsISOChk = (result) => {
    setIsISOChk(result.target.checked);
    // eslint-disable-next-line eqeqeq
    if (result.target.checked === false) {
      setIsISO(null);
    }
  };

  const handleFsChk = (result) => {
    setFsChk(result.target.checked);
    if (result.target.checked === false) {
      setFsMMChk(result.target.checked);
      setFsISOChk(result.target.checked);
      setFsMM(null);
      setFsISO(null);
    }
  };

  const handleFsMMChk = (result) => {
    setFsMMChk(result.target.checked);
    if (result.target.checked === false) {
      setFsMM(null);
    }
  };

  const handleFsISOChk = (result) => {
    setFsISOChk(result.target.checked);
    if (result.target.checked === false) {
      setFsISO(null);
    }
  };

  const handleIbChk = (result) => {
    setIbChk(result.target.checked);
    if (result.target.checked === false) {
      setIbMMChk(result.target.checked);
      setIbISOChk(result.target.checked);
      setIbMM(null);
      setIbISO(null);
    }
  };

  const handleIbMMChk = (result) => {
    setIbMMChk(result.target.checked);
    if (result.target.checked === false) {
      setIbMM(null);
    }
  };

  const handleIbISOChk = (result) => {
    setIbISOChk(result.target.checked);
    if (result.target.checked === false) {
      setIbISO(null);
    }
  };

  const handleFbChk = (result) => {
    setFbChk(result.target.checked);
    if (result.target.checked === false) {
      setFbMMChk(result.target.checked);
      setFbISOChk(result.target.checked);
      setFbMM(null);
      setFbISO(null);
    }
  };

  const handleFbMMChk = (result) => {
    setFbMMChk(result.target.checked);
    if (result.target.checked === false) {
      setFbMM(null);
    }
  };

  const handleFbISOChk = (result) => {
    setFbISOChk(result.target.checked);
    if (result.target.checked === false) {
      setFbISO(null);
    }
  };

  const handleIpChk = (result) => {
    setIpChk(result.target.checked);
    if (result.target.checked === false) {
      setIpMMChk(result.target.checked);
      setIpISOChk(result.target.checked);
      setIpMM(null);
      setIpISO(null);
    }
  };

  const handleIpMMChk = (result) => {
    setIpMMChk(result.target.checked);
    if (result.target.checked === false) {
      setIpMM(null);
    }
  };

  const handleIpISOChk = (result) => {
    setIpISOChk(result.target.checked);
    if (result.target.checked === false) {
      setIpISO(null);
    }
  };

  const handleFpChk = (result) => {
    setFpChk(result.target.checked);
    if (result.target.checked === false) {
      setFpMMChk(result.target.checked);
      setFpISOChk(result.target.checked);
      setFpMM(null);
      setFpISO(null);
    }
  };

  const handleFpMMChk = (result) => {
    setFpMMChk(result.target.checked);
    if (result.target.checked === false) {
      setFpMM(null);
    }
  };

  const handleFpISOChk = (result) => {
    setFpISOChk(result.target.checked);
    if (result.target.checked === false) {
      setFpISO(null);
    }
  };

  const handleIlChk = (result) => {
    setIlChk(result.target.checked);
    if (result.target.checked === false) {
      setIlMMChk(result.target.checked);
      setIlISOChk(result.target.checked);
      setIlMM(null);
      setIlISO(null);
    }
  };

  const handleIlMMChk = (result) => {
    setIlMMChk(result.target.checked);
    if (result.target.checked === false) {
      setIlMM(null);
    }
  };

  const handleIlISOChk = (result) => {
    setIlISOChk(result.target.checked);
    if (result.target.checked === false) {
      setIlISO(null);
    }
  };

  const handleFlChk = (result) => {
    setFlChk(result.target.checked);
    if (result.target.checked === false) {
      setFlMMChk(result.target.checked);
      setFlISOChk(result.target.checked);
      setFlMM(null);
      setFlISO(null);
    }
  };

  const handleFlMMChk = (result) => {
    setFlMMChk(result.target.checked);
    if (result.target.checked === false) {
      setFlMM(null);
    }
  };

  const handleFlISOChk = (result) => {
    setFlISOChk(result.target.checked);
    if (result.target.checked === false) {
      setFlISO(null);
    }
  };

  const handleImbChk = (result) => {
    setImbChk(result.target.checked);
    if (result.target.checked === false) {
      setImbMMChk(result.target.checked);
      setImbISOChk(result.target.checked);
      setImbMM(null);
      setImbISO(null);
    }
  };

  const handleImbMMChk = (result) => {
    setImbMMChk(result.target.checked);
    if (result.target.checked === false) {
      setImbMM(null);
    }
  };

  const handleImbISOChk = (result) => {
    setImbISOChk(result.target.checked);
    if (result.target.checked === false) {
      setImbISO(null);
    }
  };

  const handleFmbChk = (result) => {
    setFmbChk(result.target.checked);
    if (result.target.checked === false) {
      setFmbMMChk(result.target.checked);
      setFmbISOChk(result.target.checked);
      setFmbMM(null);
      setFmbISO(null);
    }
  };

  const handleFmbMMChk = (result) => {
    setFmbMMChk(result.target.checked);
    if (result.target.checked === false) {
      setFmbMM(null);
    }
  };

  const handleFmbISOChk = (result) => {
    setFmbISOChk(result.target.checked);
    if (result.target.checked === false) {
      setFmbISO(null);
    }
  };

  const handleIdChk = (result) => {
    setIdChk(result.target.checked);
    if (result.target.checked === false) {
      setIdMMChk(result.target.checked);
      setIdISOChk(result.target.checked);
      setIdMM(null);
      setIdISO(null);
    }
  };

  const handleIdMMChk = (result) => {
    setIdMMChk(result.target.checked);
    if (result.target.checked === false) {
      setIdMM(null);
    }
  };

  const handleIdISOChk = (result) => {
    setIdISOChk(result.target.checked);
    if (result.target.checked === false) {
      setIdISO(null);
    }
  };

  const handleFdChk = (result) => {
    setFdChk(result.target.checked);
    if (result.target.checked === false) {
      setFdMMChk(result.target.checked);
      setFdISOChk(result.target.checked);
      setFdMM(null);
      setFdISO(null);
    }
  };

  const handleFdMMChk = (result) => {
    setFdMMChk(result.target.checked);
    if (result.target.checked === false) {
      setFdMM(null);
    }
  };

  const handleFdISOChk = (result) => {
    setFdISOChk(result.target.checked);
    if (result.target.checked === false) {
      setFdISO(null);
    }
  };

  const handleImlChk = (result) => {
    setImlChk(result.target.checked);
    if (result.target.checked === false) {
      setImlMMChk(result.target.checked);
      setImlISOChk(result.target.checked);
      setImlMM(null);
      setImlISO(null);
    }
  };

  const handleImlMMChk = (result) => {
    setImlMMChk(result.target.checked);
    if (result.target.checked === false) {
      setImlMM(null);
    }
  };

  const handleImlISOChk = (result) => {
    setImlISOChk(result.target.checked);
    if (result.target.checked === false) {
      setImlISO(null);
    }
  };

  const handleFmlChk = (result) => {
    setFmlChk(result.target.checked);
    if (result.target.checked === false) {
      setFmlMMChk(result.target.checked);
      setFmlISOChk(result.target.checked);
      setFmlMM(null);
      setFmlISO(null);
    }
  };

  const handleFmlMMChk = (result) => {
    setFmlMMChk(result.target.checked);
    if (result.target.checked === false) {
      setFmlMM(null);
    }
  };

  const handleFmlISOChk = (result) => {
    setFmlISOChk(result.target.checked);
    if (result.target.checked === false) {
      setFmlISO(null);
    }
  };

  const generateMenuItems = () => {
    const items = [];
    for (let i = 10; i <= 32; i += 0.5) {
      items.push(
        <MenuItem key={i} value={i.toString()}>
          {i % 1 === 0 ? i : i.toFixed(1)}
        </MenuItem>
      );
    }
    return items;
  };

  const handleClinicNoChange = (newClinicNo) => {
    setClinicNo(newClinicNo);
    setId(null);
  };

  const handleTeethClick = (myDictionary) => {
    setTeeth(convertDictionaryToString(myDictionary));
  };

  const clearStartDate = () => {
    setStartDate(null);
  };

  const clearEndDate = () => {
    setEndDate(null);
  };

  useEffect(() => {
    if (refModule != null) {
      if (refModule.refModuleId != null) {
        setButtonStates({
          search: false,
          edit: true,
          save: false,
          clear: true,
          close: true,
          history: true,
          scan: true,
        });
      } else {
        setButtonStates({
          search: false,
          edit: false,
          save: true,
          clear: true,
          close: true,
          history: false,
        });
      }
      setClinicNo(refModule.refClinicId);
      setOtherDocNo(refModule.refTreatmentId);
      setRefClinicNo(refModule.refClinicId);
      setRefRctNo(refModule.refModuleId);
    } else {
      setButtonStates({
        search: true,
        edit: true,
        save: true,
        clear: true,
        close: false,
        history: true,
        scan: true,
      });
      setOtherDocNo(null);
      setRefClinicNo(null);
      setRefRctNo(null);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      refModule = null;
    }
  }, [refModule]);

  useEffect(() => {
    async function getRctByID() {
      try {
        //console.log("clinicNo complains " + clinicNo);
        const response = await api.get(`/rct/byid/${refRctNo}`);
        const data = (await response).data.data;
        //console.log("complains",data[0])
        const clinicData = data.find((item) => item.id === refRctNo);

        if (clinicData) {
          clear(false);

          setId(clinicData.id);
          setClinicNo(clinicData.clinic_no);
          setTeeth(
            clinicData.teeth === null ? examTeethInitCommon : clinicData.teeth
          );

          setStartDate(
            clinicData.start_date != null ? dayjs(clinicData.start_date) : null
          );
          setEndDate(
            clinicData.end_date != null ? dayjs(clinicData.end_date) : null
          );

          //#region row 1
          if (clinicData.is_mm != null || clinicData.is_iso != null) {
            setIsChk(true);
            if (clinicData.is_mm != null) {
              setIsMMChk(true);
              setIsMM(clinicData.is_mm);
            }
            if (clinicData.is_iso != null) {
              setIsISOChk(true);
              setIsISO(clinicData.is_iso);
            }
          }

          if (clinicData.fs_mm != null || clinicData.fs_iso != null) {
            setFsChk(true);
            if (clinicData.fs_mm != null) {
              setFsMMChk(true);
              setFsMM(clinicData.fs_mm);
            }
            if (clinicData.fs_iso != null) {
              setFsISOChk(true);
              setFsISO(clinicData.fs_iso);
            }
          }

          //#endregion row 1

          //#region row 2

          if (clinicData.ib_mm != null || clinicData.ib_iso != null) {
            setIbChk(true);
            if (clinicData.ib_mm != null) {
              setIbMMChk(true);
              setIbMM(clinicData.ib_mm);
            }
            if (clinicData.ib_iso != null) {
              setIbISOChk(true);
              setIbISO(clinicData.ib_iso);
            }
          }

          if (clinicData.fb_mm != null || clinicData.fb_iso != null) {
            setFbChk(true);
            if (clinicData.fb_mm != null) {
              setFbMMChk(true);
              setFbMM(clinicData.fb_mm);
            }
            if (clinicData.fb_iso != null) {
              setFbISOChk(true);
              setFbISO(clinicData.fb_iso);
            }
          }

          //#endregion row 2

          //#region row 3

          if (clinicData.ip_mm != null || clinicData.ip_iso != null) {
            setIpChk(true);
            if (clinicData.ip_mm != null) {
              setIpMMChk(true);
              setIpMM(clinicData.ip_mm);
            }
            if (clinicData.ip_iso != null) {
              setIpISOChk(true);
              setIpISO(clinicData.ip_iso);
            }
          }

          if (clinicData.fp_mm != null || clinicData.fp_iso != null) {
            setFpChk(true);
            if (clinicData.fp_mm != null) {
              setFpMMChk(true);
              setFpMM(clinicData.fp_mm);
            }
            if (clinicData.fp_iso != null) {
              setFpISOChk(true);
              setFpISO(clinicData.fp_iso);
            }
          }

          //#endregion row 3

          //#region row 4

          if (clinicData.il_mm != null || clinicData.il_iso != null) {
            setIlChk(true);
            if (clinicData.il_mm != null) {
              setIlMMChk(true);
              setIlMM(clinicData.il_mm);
            }
            if (clinicData.il_iso != null) {
              setIlISOChk(true);
              setIlISO(clinicData.il_iso);
            }
          }

          if (clinicData.fl_mm != null || clinicData.fl_iso != null) {
            setFlChk(true);
            if (clinicData.fl_mm != null) {
              setFlMMChk(true);
              setFlMM(clinicData.fl_mm);
            }
            if (clinicData.fl_iso != null) {
              setFlISOChk(true);
              setFlISO(clinicData.fl_iso);
            }
          }

          //#endregion row 4

          //#region row 5

          if (clinicData.imb_mm != null || clinicData.imb_iso != null) {
            setImbChk(true);
            if (clinicData.imb_mm != null) {
              setImbMMChk(true);
              setImbMM(clinicData.imb_mm);
            }
            if (clinicData.imb_iso != null) {
              setImbISOChk(true);
              setImbISO(clinicData.imb_iso);
            }
          }

          if (clinicData.fmb_mm != null || clinicData.fmb_iso != null) {
            setFmbChk(true);
            if (clinicData.fmb_mm != null) {
              setFmbMMChk(true);
              setFmbMM(clinicData.fmb_mm);
            }
            if (clinicData.fmb_iso != null) {
              setFmbISOChk(true);
              setFmbISO(clinicData.fmb_iso);
            }
          }

          //#endregion row 5

          //#region row 6

          if (clinicData.id_mm != null || clinicData.id_iso != null) {
            setIdChk(true);
            if (clinicData.id_mm != null) {
              setIdMMChk(true);
              setIdMM(clinicData.id_mm);
            }
            if (clinicData.id_iso != null) {
              setIdISOChk(true);
              setIdISO(clinicData.id_iso);
            }
          }

          if (clinicData.fd_mm != null || clinicData.fd_iso != null) {
            setFdChk(true);
            if (clinicData.fd_mm != null) {
              setFdMMChk(true);
              setFdMM(clinicData.fd_mm);
            }
            if (clinicData.fd_iso != null) {
              setFdISOChk(true);
              setFdISO(clinicData.fd_iso);
            }
          }

          //#endregion row 6

          //#region row 7

          if (clinicData.iml_mm != null || clinicData.iml_iso != null) {
            setImlChk(true);
            if (clinicData.iml_mm != null) {
              setImlMMChk(true);
              setImlMM(clinicData.iml_mm);
            }
            if (clinicData.iml_iso != null) {
              setImlISOChk(true);
              setImlISO(clinicData.iml_iso);
            }
          }

          if (clinicData.fml_mm != null || clinicData.fml_iso != null) {
            setFmlChk(true);
            if (clinicData.fml_mm != null) {
              setFmlMMChk(true);
              setFmlMM(clinicData.fml_mm);
            }
            if (clinicData.fml_iso != null) {
              setFmlISOChk(true);
              setFmlISO(clinicData.fml_iso);
            }
          }

          //#endregion row 7

          //#region row 8
          setSpecial(clinicData.special);
          setCrown(clinicData.crown);
          //#endregion row 8
        } else {
          //todo:
        }
      } catch (error) {
        console.error(error);
      }
    }

    if (refRctNo != null) {
      getRctByID();
    }
    if (refModule != null) {
      setOtherDocNo(refModule.refTreatmentId);
    }
  }, [refRctNo]);

  //#endregion Other Events

  //#region outside controller compartments

  const convertDictionaryToString = (dictionary) => {
    return Object.entries(dictionary)
      .map(([key, value]) => `${key}.${value}`)
      .join(",");
  };

  const handleButtonClick = (buttonId) => {
    if (buttonId === "Save") {
      setMessage("Do you really want to save this RCT?");
    } else if (buttonId === "Edit") {
      setMessage("Do you really want to edit this RCT?");
    } else if (buttonId === "Clear") {
      setMessage("Do you really want to clear this RCT?");
    }
    setFunctionality(buttonId);
    setMessageBoxOpen(true);
  };

  const handleMessageBoxClose = (isPrimaryButtonClicked) => {
    if (isPrimaryButtonClicked && functionality === "Save") {
      save();
    }
    if (isPrimaryButtonClicked && functionality === "Clear") {
      clear(true);
    }

    if (isPrimaryButtonClicked && functionality === "Edit") {
      edit();
    }
    // Set isMessageBoxOpen to false to hide the message box
    setMessageBoxOpen(false);
  };

  const triggerAlertOn = async (title, description, severity) => {
    setAlertTitle(title);
    setAlertDescription(description);
    setAlertSeverity(severity);
    setShowAlert(true);

    setTimeout(() => {
      setAlertTitle("");
      setAlertDescription("");
      setAlertSeverity("");
      setShowAlert(false);
    }, 2000);
  };

  useEffect(() => {
    setCreateBy(sessionStorage.getItem("userid"));
    setUpdateBy(sessionStorage.getItem("userid"));
  }, []);

  const resetAButton = () => {
    // Update the key to remount component A and reset its state
    setResetKey((prevKey) => prevKey + 1);
  };

  const handleClearValues = () => {
    // Set clearValues to true to trigger the clearing in CrudController
    setClearValues(true);
  };

  // Callback function to reset clearValues in CrudController
  const handleClearComplete = () => {
    setClearValues(false);
  };

  //#endregion outside controller compartments

  const handleDataFetchStatus = (status) => {
    if (status === false) {
      triggerAlertOn(
        "Search",
        `This is an invalid clinic no. Please check the number and try again.`,
        "error"
      );
      setClinicNo("");
    }
  };

  const searchFields = [
    { label: "Visit #", value: "visit_sequence" },
    { label: "Date", value: "visit_date" },
    { label: "id", value: "id" },
  ];

  const endpointComponent = "rct";
  const endPointRouter = "visit";

  const handleRowSingleClick = (id) => {
    //console.log("selected id " + id);
    setId(id);
    setOpenModal(false); // Close the modal after selecting a row
    handleSearch(id);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [openModal, setOpenModal] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        marginTop: "16px",
        marginLeft: "16px",
      }}
    >
      <Grid container spacing={2}>
        {/* 1 Row */}
        <CrudController
          onSave={handleSave}
          onClear={handleClear}
          onEdit={handleEdit}
          onSearch={handleSearch}
          onClose={handleClose}
          onHistory={handleHistory}
          clinicNo={clinicNo}
          onClinicNoChange={handleClinicNoChange}
          buttonStates={buttonStates}
          isNameVisible={true}
          clearValues={clearValues}
          onClearComplete={handleClearComplete}
          onDataFetchStatus={handleDataFetchStatus}
          isClinicNoVisible={
            refModule == null || refModule == "undefined" ? true : false
          }
        />

        {/* 2 Row */}
        <Grid container spacing={2} style={{ paddingTop: 15 }}>
          {/* cell 1 */}
          <Grid item xs={7}>
            <Paper elevation={0} style={{ padding: 5, textAlign: "left" }}>
              <Tooth
                initialButtonString={teeth}
                key={resetKey}
                onClickPatternChange={handleTeethClick}
              />
            </Paper>
          </Grid>

          {/* cell 2 */}
          <Grid item xs={3}>
            <Paper elevation={0} style={{ padding: 5, textAlign: "left" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
                <DatePicker
                  label="Start Date"
                  format={process.env.REACT_APP_DATE_FORMAT}
                  style={{ width: "170px" }}
                  value={startDate != null ? dayjs(startDate) : null}
                  onChange={(newValue) => setStartDate(newValue)}
                  clearable // Enable clearable option
                  onAccept={(newValue) => setStartDate(newValue)}
                  onClear={clearStartDate}
                />
              </LocalizationProvider>
              <div>&nbsp;</div>
              <LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
                <DatePicker
                  label="Finish Date"
                  format={process.env.REACT_APP_DATE_FORMAT}
                  style={{ width: "170px" }}
                  value={endDate != null ? dayjs(endDate) : null}
                  onChange={(newValue) => setEndDate(newValue)}
                  clearable // Enable clearable option
                  onAccept={(newValue) => setEndDate(newValue)}
                  onClear={clearEndDate}
                />
              </LocalizationProvider>
            </Paper>
          </Grid>
        </Grid>

        {/* 3 Row */}
        <Grid container spacing={10} style={{ paddingTop: 15 }}>
          {/* cell 1 */}
          <Grid item xs={6}>
            <Paper elevation={0} style={{ padding: 5, textAlign: "left" }}>
              <Typography variant="h6" style={{ paddingTop: 10 ,fontWeight:550}}>
                Inserted Legnths & ISO Sizes Roots
              </Typography>
            </Paper>
          </Grid>

          {/* cell 2 */}
          <Grid item xs={6}>
            <Paper elevation={0} style={{ padding: 5, textAlign: "left" }}>
              <Typography variant="h6" style={{ paddingTop: 10 ,fontWeight:550}}>
                Filled Lengths & ISO Sizes Roots
              </Typography>
            </Paper>
          </Grid>
        </Grid>

        {/* 4 Row Single */}
        <Grid container spacing={10} style={{ paddingTop: 15 }}>
          {/* cell 1 */}
          <Grid item xs={6}>
            <Paper elevation={0}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="isChk"
                    checked={isChk}
                    onChange={handleIsChk}
                    sx={{
                      color: blue[800],
                      "&.Mui-checked": {
                        color: blueGrey[600],
                      },
                    }}
                  />
                }
                label="Single"
              />{" "}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <FormControlLabel
                control={
                  <Checkbox
                    name="isMMChk"
                    disabled={!isChk}
                    checked={isMMChk}
                    onChange={handleIsMMChk}
                    sx={{
                      color: blue[800],
                      "&.Mui-checked": {
                        color: blueGrey[600],
                      },
                    }}
                  />
                }
                label="mm"
              />
              <Select
                displayEmpty
                style={{ width: "60px" }}
                value={isMM}
                onChange={(e) => setIsMM(e.target.value)}
                disabled={!isMMChk}
              >
                <MenuItem value={null}> -- </MenuItem>
                {generateMenuItems()}
              </Select>
              <FormControlLabel
                control={
                  <Checkbox
                    name="isISOChk"
                    disabled={!isChk}
                    checked={isISOChk}
                    onChange={handleIsISOChk}
                    sx={{
                      color: blue[800],
                      "&.Mui-checked": {
                        color: blueGrey[600],
                      },
                    }}
                  />
                }
                label="ISO"
              />
              <Select
                displayEmpty
                style={{ width: "60px" }}
                disabled={!isISOChk}
                value={isISO}
                onChange={(e) => setIsISO(e.target.value)}
              >
                <MenuItem value={null}> -- </MenuItem>
                <MenuItem value={"F1"}>F1</MenuItem>
                <MenuItem value={"F2"}>F2</MenuItem>
                <MenuItem value={"F3"}>F3</MenuItem>
              </Select>
            </Paper>
          </Grid>

          {/* cell 2 */}
          <Grid item xs={6}>
            <Paper elevation={0}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="fsChk"
                    checked={fsChk}
                    onChange={handleFsChk}
                    sx={{
                      color: blue[800],
                      "&.Mui-checked": {
                        color: blueGrey[600],
                      },
                    }}
                  />
                }
                label="Single"
              />{" "}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <FormControlLabel
                control={
                  <Checkbox
                    name="fsMMChk"
                    disabled={!fsChk}
                    checked={fsMMChk}
                    onChange={handleFsMMChk}
                    sx={{
                      color: blue[800],
                      "&.Mui-checked": {
                        color: blueGrey[600],
                      },
                    }}
                  />
                }
                label="mm"
              />
              <Select
                displayEmpty
                style={{ width: "60px" }}
                value={fsMM}
                onChange={(e) => setFsMM(e.target.value)}
                disabled={!fsMMChk}
              >
                <MenuItem value={null}> -- </MenuItem>
                {generateMenuItems()}
              </Select>
              <FormControlLabel
                control={
                  <Checkbox
                    name="fsISOChk"
                    disabled={!fsChk}
                    checked={fsISOChk}
                    onChange={handleFsISOChk}
                    sx={{
                      color: blue[800],
                      "&.Mui-checked": {
                        color: blueGrey[600],
                      },
                    }}
                  />
                }
                label="ISO"
              />
              <Select
                displayEmpty
                style={{ width: "60px" }}
                value={fsISO}
                onChange={(e) => setFsISO(e.target.value)}
                disabled={!fsISOChk}
              >
                <MenuItem value={null}> -- </MenuItem>
                <MenuItem value={"F1"}>F1</MenuItem>
                <MenuItem value={"F2"}>F2</MenuItem>
                <MenuItem value={"F3"}>F3</MenuItem>
              </Select>
            </Paper>
          </Grid>
        </Grid>

        {/* 5 Row Buccal */}
        <Grid container spacing={10} style={{ paddingTop: 15 }}>
          {/* cell 1 */}
          <Grid item xs={6}>
            <Paper elevation={0}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="ibChk"
                    checked={ibChk}
                    onChange={handleIbChk}
                    sx={{
                      color: blue[800],
                      "&.Mui-checked": {
                        color: blueGrey[600],
                      },
                    }}
                  />
                }
                label="Buccal"
              />{" "}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <FormControlLabel
                control={
                  <Checkbox
                    name="ibMMChk"
                    disabled={!ibChk}
                    checked={ibMMChk}
                    onChange={handleIbMMChk}
                    sx={{
                      color: blue[800],
                      "&.Mui-checked": {
                        color: blueGrey[600],
                      },
                    }}
                  />
                }
                label="mm"
              />
              <Select
                displayEmpty
                style={{ width: "60px" }}
                value={ibMM}
                onChange={(e) => setIbMM(e.target.value)}
                disabled={!ibMMChk}
              >
                <MenuItem value={null}> -- </MenuItem>
                {generateMenuItems()}
              </Select>
              <FormControlLabel
                control={
                  <Checkbox
                    name="ibISOChk"
                    disabled={!ibChk}
                    checked={ibISOChk}
                    onChange={handleIbISOChk}
                    sx={{
                      color: blue[800],
                      "&.Mui-checked": {
                        color: blueGrey[600],
                      },
                    }}
                  />
                }
                label="ISO"
              />
              <Select
                displayEmpty
                style={{ width: "60px" }}
                disabled={!ibISOChk}
                value={ibISO}
                onChange={(e) => setIbISO(e.target.value)}
              >
                <MenuItem value={null}> -- </MenuItem>
                <MenuItem value={"F1"}>F1</MenuItem>
                <MenuItem value={"F2"}>F2</MenuItem>
                <MenuItem value={"F3"}>F3</MenuItem>
              </Select>
            </Paper>
          </Grid>

          {/* cell 2 */}
          <Grid item xs={6}>
            <Paper elevation={0}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="fbChk"
                    checked={fbChk}
                    onChange={handleFbChk}
                    sx={{
                      color: blue[800],
                      "&.Mui-checked": {
                        color: blueGrey[600],
                      },
                    }}
                  />
                }
                label="Buccal"
              />{" "}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <FormControlLabel
                control={
                  <Checkbox
                    name="fbMMChk"
                    disabled={!fbChk}
                    checked={fbMMChk}
                    onChange={handleFbMMChk}
                    sx={{
                      color: blue[800],
                      "&.Mui-checked": {
                        color: blueGrey[600],
                      },
                    }}
                  />
                }
                label="mm"
              />
              <Select
                displayEmpty
                style={{ width: "60px" }}
                value={fbMM}
                onChange={(e) => setFbMM(e.target.value)}
                disabled={!fbMMChk}
              >
                <MenuItem value={null}> -- </MenuItem>
                {generateMenuItems()}
              </Select>
              <FormControlLabel
                control={
                  <Checkbox
                    name="fbISOChk"
                    disabled={!fbChk}
                    checked={fbISOChk}
                    onChange={handleFbISOChk}
                    sx={{
                      color: blue[800],
                      "&.Mui-checked": {
                        color: blueGrey[600],
                      },
                    }}
                  />
                }
                label="ISO"
              />
              <Select
                displayEmpty
                style={{ width: "60px" }}
                value={fbISO}
                onChange={(e) => setFbISO(e.target.value)}
                disabled={!fbISOChk}
              >
                <MenuItem value={null}> -- </MenuItem>
                <MenuItem value={"F1"}>F1</MenuItem>
                <MenuItem value={"F2"}>F2</MenuItem>
                <MenuItem value={"F3"}>F3</MenuItem>
              </Select>
            </Paper>
          </Grid>
        </Grid>

        {/* 6 Row Palatal */}
        <Grid container spacing={10} style={{ paddingTop: 15 }}>
          {/* cell 1 */}
          <Grid item xs={6}>
            <Paper elevation={0}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="ipChk"
                    checked={ipChk}
                    onChange={handleIpChk}
                    sx={{
                      color: blue[800],
                      "&.Mui-checked": {
                        color: blueGrey[600],
                      },
                    }}
                  />
                }
                label="Palatal"
              />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <FormControlLabel
                control={
                  <Checkbox
                    name="ipMMChk"
                    disabled={!ipChk}
                    checked={ipMMChk}
                    onChange={handleIpMMChk}
                    sx={{
                      color: blue[800],
                      "&.Mui-checked": {
                        color: blueGrey[600],
                      },
                    }}
                  />
                }
                label="mm"
              />
              <Select
                displayEmpty
                style={{ width: "60px" }}
                value={ipMM}
                onChange={(e) => setIpMM(e.target.value)}
                disabled={!ipMMChk}
              >
                <MenuItem value={null}> -- </MenuItem>
                {generateMenuItems()}
              </Select>
              <FormControlLabel
                control={
                  <Checkbox
                    name="ipISOChk"
                    disabled={!ipChk}
                    checked={ipISOChk}
                    onChange={handleIpISOChk}
                    sx={{
                      color: blue[800],
                      "&.Mui-checked": {
                        color: blueGrey[600],
                      },
                    }}
                  />
                }
                label="ISO"
              />
              <Select
                displayEmpty
                style={{ width: "60px" }}
                disabled={!ipISOChk}
                value={ipISO}
                onChange={(e) => setIpISO(e.target.value)}
              >
                <MenuItem value={null}> -- </MenuItem>
                <MenuItem value={"F1"}>F1</MenuItem>
                <MenuItem value={"F2"}>F2</MenuItem>
                <MenuItem value={"F3"}>F3</MenuItem>
              </Select>
            </Paper>
          </Grid>

          {/* cell 2 */}
          <Grid item xs={6}>
            <Paper elevation={0}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="fpChk"
                    checked={fpChk}
                    onChange={handleFpChk}
                    sx={{
                      color: blue[800],
                      "&.Mui-checked": {
                        color: blueGrey[600],
                      },
                    }}
                  />
                }
                label="Palatal"
              />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <FormControlLabel
                control={
                  <Checkbox
                    name="fpMMChk"
                    disabled={!fpChk}
                    checked={fpMMChk}
                    onChange={handleFpMMChk}
                    sx={{
                      color: blue[800],
                      "&.Mui-checked": {
                        color: blueGrey[600],
                      },
                    }}
                  />
                }
                label="mm"
              />
              <Select
                displayEmpty
                style={{ width: "60px" }}
                value={fpMM}
                onChange={(e) => setFpMM(e.target.value)}
                disabled={!fpMMChk}
              >
                <MenuItem value={null}> --</MenuItem>
                {generateMenuItems()}
              </Select>
              <FormControlLabel
                control={
                  <Checkbox
                    name="fpISOChk"
                    disabled={!fpChk}
                    checked={fpISOChk}
                    onChange={handleFpISOChk}
                    sx={{
                      color: blue[800],
                      "&.Mui-checked": {
                        color: blueGrey[600],
                      },
                    }}
                  />
                }
                label="ISO"
              />
              <Select
                displayEmpty
                style={{ width: "60px" }}
                value={fpISO}
                onChange={(e) => setFpISO(e.target.value)}
                disabled={!fpISOChk}
              >
                <MenuItem value={null}> --</MenuItem>
                <MenuItem value={"F1"}>F1</MenuItem>
                <MenuItem value={"F2"}>F2</MenuItem>
                <MenuItem value={"F3"}>F3</MenuItem>
              </Select>
            </Paper>
          </Grid>
        </Grid>

        {/* 7 Row  Lingual  */}
        <Grid container spacing={10} style={{ paddingTop: 15 }}>
          {/* cell 1 */}
          <Grid item xs={6}>
            <Paper elevation={0}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="ilChk"
                    checked={ilChk}
                    onChange={handleIlChk}
                    sx={{
                      color: blue[800],
                      "&.Mui-checked": {
                        color: blueGrey[600],
                      },
                    }}
                  />
                }
                label="Lingual"
              />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <FormControlLabel
                control={
                  <Checkbox
                    name="ilMMChk"
                    disabled={!ilChk}
                    checked={ilMMChk}
                    onChange={handleIlMMChk}
                    sx={{
                      color: blue[800],
                      "&.Mui-checked": {
                        color: blueGrey[600],
                      },
                    }}
                  />
                }
                label="mm"
              />
              <Select
                displayEmpty
                style={{ width: "60px" }}
                value={ilMM}
                onChange={(e) => setIlMM(e.target.value)}
                disabled={!ilMMChk}
              >
                <MenuItem value={null}> -- </MenuItem>
                {generateMenuItems()}
              </Select>
              <FormControlLabel
                control={
                  <Checkbox
                    name="ilISOChk"
                    disabled={!ilChk}
                    checked={ilISOChk}
                    onChange={handleIlISOChk}
                    sx={{
                      color: blue[800],
                      "&.Mui-checked": {
                        color: blueGrey[600],
                      },
                    }}
                  />
                }
                label="ISO"
              />
              <Select
                displayEmpty
                style={{ width: "60px" }}
                disabled={!ilISOChk}
                value={ilISO}
                onChange={(e) => setIlISO(e.target.value)}
              >
                <MenuItem value={null}> -- </MenuItem>
                <MenuItem value={"F1"}>F1</MenuItem>
                <MenuItem value={"F2"}>F2</MenuItem>
                <MenuItem value={"F3"}>F3</MenuItem>
              </Select>
            </Paper>
          </Grid>

          {/* cell 2 */}
          <Grid item xs={6}>
            <Paper elevation={0}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="flChk"
                    checked={flChk}
                    onChange={handleFlChk}
                    sx={{
                      color: blue[800],
                      "&.Mui-checked": {
                        color: blueGrey[600],
                      },
                    }}
                  />
                }
                label="Lingual"
              />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <FormControlLabel
                control={
                  <Checkbox
                    name="flMMChk"
                    disabled={!flChk}
                    checked={flMMChk}
                    onChange={handleFlMMChk}
                    sx={{
                      color: blue[800],
                      "&.Mui-checked": {
                        color: blueGrey[600],
                      },
                    }}
                  />
                }
                label="mm"
              />
              <Select
                displayEmpty
                style={{ width: "60px" }}
                value={flMM}
                onChange={(e) => setFlMM(e.target.value)}
                disabled={!flMMChk}
              >
                <MenuItem value={null}> --</MenuItem>
                {generateMenuItems()}
              </Select>
              <FormControlLabel
                control={
                  <Checkbox
                    name="flISOChk"
                    disabled={!flChk}
                    checked={flISOChk}
                    onChange={handleFlISOChk}
                    sx={{
                      color: blue[800],
                      "&.Mui-checked": {
                        color: blueGrey[600],
                      },
                    }}
                  />
                }
                label="ISO"
              />
              <Select
                displayEmpty
                style={{ width: "60px" }}
                value={flISO}
                onChange={(e) => setFlISO(e.target.value)}
                disabled={!flISOChk}
              >
                <MenuItem value={null}> -- </MenuItem>
                <MenuItem value={"F1"}>F1</MenuItem>
                <MenuItem value={"F2"}>F2</MenuItem>
                <MenuItem value={"F3"}>F3</MenuItem>
              </Select>
            </Paper>
          </Grid>
        </Grid>

        {/* 8 Row Mesio Buccal */}
        <Grid container spacing={10} style={{ paddingTop: 15 }}>
          {/* cell 1 */}
          <Grid item xs={6}>
            <Paper elevation={0}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="imbChk"
                    checked={imbChk}
                    onChange={handleImbChk}
                    sx={{
                      color: blue[800],
                      "&.Mui-checked": {
                        color: blueGrey[600],
                      },
                    }}
                  />
                }
                label="Mesio Buccal"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="imbMMChk"
                    disabled={!imbChk}
                    checked={imbMMChk}
                    onChange={handleImbMMChk}
                    sx={{
                      color: blue[800],
                      "&.Mui-checked": {
                        color: blueGrey[600],
                      },
                    }}
                  />
                }
                label="mm"
              />
              <Select
                displayEmpty
                style={{ width: "60px" }}
                value={imbMM}
                onChange={(e) => setImbMM(e.target.value)}
                disabled={!imbMMChk}
              >
                <MenuItem value={null}> -- </MenuItem>
                {generateMenuItems()}
              </Select>
              <FormControlLabel
                control={
                  <Checkbox
                    name="imbISOChk"
                    disabled={!imbChk}
                    checked={imbISOChk}
                    onChange={handleImbISOChk}
                    sx={{
                      color: blue[800],
                      "&.Mui-checked": {
                        color: blueGrey[600],
                      },
                    }}
                  />
                }
                label="ISO"
              />
              <Select
                displayEmpty
                style={{ width: "60px" }}
                disabled={!imbISOChk}
                value={imbISO}
                onChange={(e) => setImbISO(e.target.value)}
              >
                <MenuItem value={null}> -- </MenuItem>
                <MenuItem value={"F1"}>F1</MenuItem>
                <MenuItem value={"F2"}>F2</MenuItem>
                <MenuItem value={"F3"}>F3</MenuItem>
              </Select>
            </Paper>
          </Grid>

          {/* cell 2 */}
          <Grid item xs={6}>
            <Paper elevation={0}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="fmbChk"
                    checked={fmbChk}
                    onChange={handleFmbChk}
                    sx={{
                      color: blue[800],
                      "&.Mui-checked": {
                        color: blueGrey[600],
                      },
                    }}
                  />
                }
                label="Mesio Buccal"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="fmbMMChk"
                    disabled={!fmbChk}
                    checked={fmbMMChk}
                    onChange={handleFmbMMChk}
                    sx={{
                      color: blue[800],
                      "&.Mui-checked": {
                        color: blueGrey[600],
                      },
                    }}
                  />
                }
                label="mm"
              />
              <Select
                displayEmpty
                style={{ width: "60px" }}
                value={fmbMM}
                onChange={(e) => setFmbMM(e.target.value)}
                disabled={!fmbMMChk}
              >
                <MenuItem value={null}> -- </MenuItem>
                {generateMenuItems()}
              </Select>
              <FormControlLabel
                control={
                  <Checkbox
                    name="fmbISOChk"
                    disabled={!fmbChk}
                    checked={fmbISOChk}
                    onChange={handleFmbISOChk}
                    sx={{
                      color: blue[800],
                      "&.Mui-checked": {
                        color: blueGrey[600],
                      },
                    }}
                  />
                }
                label="ISO"
              />
              <Select
                displayEmpty
                style={{ width: "60px" }}
                value={fmbISO}
                onChange={(e) => setFmbISO(e.target.value)}
                disabled={!fmbISOChk}
              >
                <MenuItem value={null}> -- </MenuItem>
                <MenuItem value={"F1"}>F1</MenuItem>
                <MenuItem value={"F2"}>F2</MenuItem>
                <MenuItem value={"F3"}>F3</MenuItem>
              </Select>
            </Paper>
          </Grid>
        </Grid>

        {/* 9 Row Disto Buccal */}
        <Grid container spacing={10} style={{ paddingTop: 15 }}>
          {/* cell 1 */}
          <Grid item xs={6}>
            <Paper elevation={0}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="idChk"
                    checked={idChk}
                    onChange={handleIdChk}
                    sx={{
                      color: blue[800],
                      "&.Mui-checked": {
                        color: blueGrey[600],
                      },
                    }}
                  />
                }
                label="Disto Buccal"
              />
              &nbsp;
              <FormControlLabel
                control={
                  <Checkbox
                    name="idMMChk"
                    disabled={!idChk}
                    checked={idMMChk}
                    onChange={handleIdMMChk}
                    sx={{
                      color: blue[800],
                      "&.Mui-checked": {
                        color: blueGrey[600],
                      },
                    }}
                  />
                }
                label="mm"
              />
              <Select
                displayEmpty
                style={{ width: "60px" }}
                value={idMM}
                onChange={(e) => setIdMM(e.target.value)}
                disabled={!idMMChk}
              >
                <MenuItem value={null}> -- </MenuItem>
                {generateMenuItems()}
              </Select>
              <FormControlLabel
                control={
                  <Checkbox
                    name="idISOChk"
                    disabled={!idChk}
                    checked={idISOChk}
                    onChange={handleIdISOChk}
                    sx={{
                      color: blue[800],
                      "&.Mui-checked": {
                        color: blueGrey[600],
                      },
                    }}
                  />
                }
                label="ISO"
              />
              <Select
                displayEmpty
                style={{ width: "60px" }}
                disabled={!idISOChk}
                value={idISO}
                onChange={(e) => setIdISO(e.target.value)}
              >
                <MenuItem value={null}> -- </MenuItem>
                <MenuItem value={"F1"}>F1</MenuItem>
                <MenuItem value={"F2"}>F2</MenuItem>
                <MenuItem value={"F3"}>F3</MenuItem>
              </Select>
            </Paper>
          </Grid>

          {/* cell 2 */}
          <Grid item xs={6}>
            <Paper elevation={0}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="fdChk"
                    checked={fdChk}
                    onChange={handleFdChk}
                    sx={{
                      color: blue[800],
                      "&.Mui-checked": {
                        color: blueGrey[600],
                      },
                    }}
                  />
                }
                label="Disto Buccal"
              />
              &nbsp;
              <FormControlLabel
                control={
                  <Checkbox
                    name="fdMMChk"
                    disabled={!fdChk}
                    checked={fdMMChk}
                    onChange={handleFdMMChk}
                    sx={{
                      color: blue[800],
                      "&.Mui-checked": {
                        color: blueGrey[600],
                      },
                    }}
                  />
                }
                label="mm"
              />
              <Select
                displayEmpty
                style={{ width: "60px" }}
                value={fdMM}
                onChange={(e) => setFdMM(e.target.value)}
                disabled={!fdMMChk}
              >
                <MenuItem value={null}> -- </MenuItem>
                {generateMenuItems()}
              </Select>
              <FormControlLabel
                control={
                  <Checkbox
                    name="fdISOChk"
                    disabled={!fdChk}
                    checked={fdISOChk}
                    onChange={handleFdISOChk}
                    sx={{
                      color: blue[800],
                      "&.Mui-checked": {
                        color: blueGrey[600],
                      },
                    }}
                  />
                }
                label="ISO"
              />
              <Select
                displayEmpty
                style={{ width: "60px" }}
                value={fdISO}
                onChange={(e) => setFdISO(e.target.value)}
                disabled={!fdISOChk}
              >
                <MenuItem value={null}> -- </MenuItem>
                <MenuItem value={"F1"}>F1</MenuItem>
                <MenuItem value={"F2"}>F2</MenuItem>
                <MenuItem value={"F3"}>F3</MenuItem>
              </Select>
            </Paper>
          </Grid>
        </Grid>

        {/* 10 Row Mesio lingual */}
        <Grid container spacing={10} style={{ paddingTop: 15 }}>
          {/* cell 1 */}
          <Grid item xs={6}>
            <Paper elevation={0}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="imlChk"
                    checked={imlChk}
                    onChange={handleImlChk}
                    sx={{
                      color: blue[800],
                      "&.Mui-checked": {
                        color: blueGrey[600],
                      },
                    }}
                  />
                }
                label="Mesio lingual"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="imlMMChk"
                    disabled={!imlChk}
                    checked={imlMMChk}
                    onChange={handleImlMMChk}
                    sx={{
                      color: blue[800],
                      "&.Mui-checked": {
                        color: blueGrey[600],
                      },
                    }}
                  />
                }
                label="mm"
              />
              <Select
                displayEmpty
                style={{ width: "60px" }}
                value={imlMM}
                onChange={(e) => setImlMM(e.target.value)}
                disabled={!imlMMChk}
              >
                <MenuItem value={null}> -- </MenuItem>
                {generateMenuItems()}
              </Select>
              <FormControlLabel
                control={
                  <Checkbox
                    name="imlISOChk"
                    disabled={!imlChk}
                    checked={imlISOChk}
                    onChange={handleImlISOChk}
                    sx={{
                      color: blue[800],
                      "&.Mui-checked": {
                        color: blueGrey[600],
                      },
                    }}
                  />
                }
                label="ISO"
              />
              <Select
                displayEmpty
                style={{ width: "60px" }}
                disabled={!imlISOChk}
                value={imlISO}
                onChange={(e) => setImlISO(e.target.value)}
              >
                <MenuItem value={null}> -- </MenuItem>
                <MenuItem value={"F1"}>F1</MenuItem>
                <MenuItem value={"F2"}>F2</MenuItem>
                <MenuItem value={"F3"}>F3</MenuItem>
              </Select>
            </Paper>
          </Grid>

          {/* cell 2 */}
          <Grid item xs={6}>
            <Paper elevation={0}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="fmlChk"
                    checked={fmlChk}
                    onChange={handleFmlChk}
                    sx={{
                      color: blue[800],
                      "&.Mui-checked": {
                        color: blueGrey[600],
                      },
                    }}
                  />
                }
                label="Mesio lingual"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="fmlMMChk"
                    disabled={!fmlChk}
                    checked={fmlMMChk}
                    onChange={handleFmlMMChk}
                    sx={{
                      color: blue[800],
                      "&.Mui-checked": {
                        color: blueGrey[600],
                      },
                    }}
                  />
                }
                label="mm"
              />
              <Select
                displayEmpty
                style={{ width: "60px" }}
                value={fmlMM}
                onChange={(e) => setFmlMM(e.target.value)}
                disabled={!fmlMMChk}
              >
                <MenuItem value={null}> -- </MenuItem>
                {generateMenuItems()}
              </Select>
              <FormControlLabel
                control={
                  <Checkbox
                    name="fmlISOChk"
                    disabled={!fmlChk}
                    checked={fmlISOChk}
                    onChange={handleFmlISOChk}
                    sx={{
                      color: blue[800],
                      "&.Mui-checked": {
                        color: blueGrey[600],
                      },
                    }}
                  />
                }
                label="ISO"
              />
              <Select
                displayEmpty
                style={{ width: "60px" }}
                value={fmlISO}
                onChange={(e) => setFmlISO(e.target.value)}
                disabled={!fmlISOChk}
              >
                <MenuItem value={null}> -- </MenuItem>
                <MenuItem value={"F1"}>F1</MenuItem>
                <MenuItem value={"F2"}>F2</MenuItem>
                <MenuItem value={"F3"}>F3</MenuItem>
              </Select>
            </Paper>
          </Grid>
        </Grid>

        {/* 11 Row 2 TEXT BOXES*/}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper elevation={0} style={{ padding: 0, textAlign: "left" }}>
              <CustomTextField
                label="Special Remarks"
                type="text"
                id="special"
                width="774px"
                value={special}
                onChange={(e) => setSpecial(e.target.value)}
              />
            </Paper>
          </Grid>

          {/* 6 Row */}
          <Grid item xs={12}>
            <Paper elevation={0} style={{ padding: 0, textAlign: "left" }}>
              <InputLabel
                id="crown-label"
                style={{ width: 200, paddingBottom: 10, paddingTop: 10 }}
              >
                Crown
              </InputLabel>
              <Select
                value={crown}
                onChange={(e) => setCrown(e.target.value)}
                labelId="crown-label"
                displayEmpty
                style={{ width: "210px" }}
              >
                <MenuItem value="" disabled>
                  {" "}
                  --select--{" "}
                </MenuItem>
                <MenuItem value={"GIC LC"}>GIC LC</MenuItem>
                <MenuItem value={"GIC IX"}>GIC IX</MenuItem>
                <MenuItem value={"Zerconomer"}>Zerconomer</MenuItem>
                <MenuItem value={"Composite"}>Composite</MenuItem>
                <MenuItem value={"Composite+GIC"}>Composite+GIC</MenuItem>
                <MenuItem value={"On Post Buildup"}>On Post Buildup</MenuItem>
              </Select>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <CustomMessageBox
        open={isMessageBoxOpen}
        onClose={handleMessageBoxClose}
        title="Patient Registration"
        message={message}
        primaryButtonCaption="OK"
        secondaryButtonCaption="Cancel"
        showPrimaryButton
        showSecondaryButton
      />
      <DynamicSnackBox
        endpointComponent={endpointComponent}
        endPointRouter={endPointRouter}
        columns={searchFields}
        onRowDoubleClick={handleRowSingleClick}
        handleCloseModal={handleCloseModal}
        clinicNo={clinicNo}
        openModal={openModal} // Pass modalOpen state as open prop
        showCloseButton={false}
      />
      {showAlert && (
        <div
          style={{
            position: "fixed",
            top: "155px",
            left: "610px",
            width: 890,
            height: 150,
            transform: "translate(-50%, -50%)",
            zIndex: 1000,
          }}
        >
          <Alert severity={alertSeverity}>
            <AlertTitle style={{ fontSize: "20px" }}>{alertTitle}</AlertTitle>
            <strong style={{ fontSize: "20px" }}>{alertDescription}</strong>
          </Alert>
        </div>
      )}
    </div>
  );
});

export default Rct;
