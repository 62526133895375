import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { pink } from '@mui/material/colors';

const CustomCheckBoxTextField = React.memo((props) => {
  const {
    label,
    placeholder,
    value,
    onChange,
    width,
    height,
    multiline,
    rows,
    rowsMax,
  } = props;
  const [focused, setFocused] = useState(false);
  const [isTextFieldEnabled, setIsTextFieldEnabled] = useState(false);
  const [textFieldValue, setTextFieldValue] = useState('');

  
  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const handleCheckboxChange = () => {
    setIsTextFieldEnabled(!isTextFieldEnabled);
    // Clear text field value only if it's currently enabled
    if (isTextFieldEnabled) {
      setTextFieldValue('');
    }
  };

  const handleTextFieldChange = (e) => {
    const { value } = e.target;
    setTextFieldValue(value);
    if (typeof onChange === 'function') {
      onChange(e);
    }
  };

  useEffect(() => {
    // Check the checkbox if text value is available
    if (value && value !== null) {
      setIsTextFieldEnabled(true);
      setTextFieldValue(value);
    } else {
      // Clear the checkbox if there is no text value
      setIsTextFieldEnabled(false);
      setTextFieldValue('');
      handleBlur();
    }
  }, [value]);

  const multilineProps = multiline ? { multiline: true, rows, rowsMax } : null;

  return (
    <div style={{ display: 'flex', alignItems: 'left' }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={isTextFieldEnabled}
            onChange={handleCheckboxChange}
            style={{ paddingRight: 1 }}
            sx={{
              color: pink[800],
              '&.Mui-checked': {
                color: pink[600],
              },
            }}
          />
        }
      />
      <TextField
        label={label}
        placeholder={placeholder}
        value={textFieldValue}
        onChange={handleTextFieldChange}
        fullWidth
        InputProps={{
          style: {
            color: focused ? 'black' : 'black',
            fontWeight: focused ? 'bold' : 'normal',
            height: height || '100%',
          },
          readOnly: !isTextFieldEnabled,
        }}
        style={{
          backgroundColor: focused ? '#abfee9 ' : 'white',
          width: width || '100%',
        }}
        InputLabelProps={{
          style: {},
        }}
        onFocus={handleFocus}
        onBlur={handleBlur}
        variant="outlined"
        disabled={!isTextFieldEnabled}
        {...multilineProps}
      />
    </div>
  );
});

export default CustomCheckBoxTextField;
