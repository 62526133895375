import React from "react";
import { Paper } from "@material-ui/core";
import backgroundImage from "../../images/PS_LOGO.png";

function Logo({ setIsAuthenticated, setLoggedUserName }) {
  return (
    <div
      style={{
        padding: 50,
        paddingLeft: 350,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Paper
        elevation={0}
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100%", // Make it responsive to the container width
          maxWidth: 500, // Limit maximum width for larger screens
          height: "500px", // Set a fixed height to ensure visibility
        }}
      >
        &nbsp;
      </Paper>
    </div>
  );
}

export default Logo;
