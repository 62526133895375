
import React, { useState } from 'react';
import GenderWise from '../bi/genderwise';

function ImageMap() {
  const [clickedArea, setClickedArea] = useState(null);

  function handleClick(event) {
    //const area = event.target.getAttribute('data-area');
    //setClickedArea(area);
    const x = event.clientX;
    const y = event.clientY;
    setClickedArea({ x, y });
  }

  return (
    <div>
      <GenderWise/>
    </div>
  );
}

export default ImageMap;
