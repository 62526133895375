import React from "react";
import { makeStyles } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Home";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  column: {
    height: "100%",
    padding: theme.spacing(1),
  },
  expandableColumn: {
    minWidth: "150px",
  },
  loginContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  loginInput: {
    marginBottom: theme.spacing(2),
  },
  toggleButton: {
    width: "100%",
  },
  fab: {
    position: "fixed",
    top: theme.spacing(9),
    left: theme.spacing(6),
  },
  userDetails: {
    display: "flex",
    alignItems: "center", // Align items vertically
    marginRight: theme.spacing(2),
  },
  profileIcon: {
    display: "flex",
    alignItems: "center",
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      main: "#2196f3",
    },
    secondary: {
      main: "#2196f3",
    },
  },
});

function App() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
