import React, { useState , useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles(() => ({
    
    container: {
      position: 'relative',
      color:'white',
    },
    button: {
      position: 'absolute',
    },
    buttonCenter: {
        minWidth: '12px', // Set the minimum width
        minHeight: '12px', // Set the minimum height
        padding: '0px', // Adjust padding as needed
        fontSize: '2px', // Adjust font size as needed
      top: '11px',
      left: '13px',
      backgroundColor: 'black',
    },
    buttonTop: {
        top: '0px',
        left: '11px',
        display: 'inline-block',
        backgroundColor: 'black',
        color: 'red',
        border: 'none',
        zIndex: 1,
        overflow: 'hidden',
        transition: 'all 0.8s ease',
        clipPath: 'polygon(0 0, 100% 0, 80% 100%, 20% 100%)',
        transform: 'rotate(0deg)',
        minHeight: '10px',
        minWidth: '15px', // set width to match the height of the center button
        padding: '0px', // Adjust padding as needed
        fontSize: '5px', // Adjust font size as needed
      },
      buttonRight: {
        top: '12px',
        left: '24px',
        display: 'inline-block',
        backgroundColor: 'black',
        color: 'red',
        border: 'none',
        zIndex: 1,
        overflow: 'hidden',
        transition: 'all 0.8s ease',
        clipPath: 'polygon(0 0, 100% 0, 80% 100%, 20% 100%)',
        transform: 'rotate(90deg)',
        minHeight: '10px',
        minWidth: '15px', // set width to match the height of the center button
        padding: '0px', // Adjust padding as needed
        fontSize: '5px', // Adjust font size as needed
      },
      buttonBottom: {
        top: '25px',
        left: '11.5px',
        display: 'inline-block',
        backgroundColor: 'black',
        color: 'red',
        border: 'none',
        zIndex: 1,
        overflow: 'hidden',
        transition: 'all 0.8s ease',
        clipPath: 'polygon(0 0, 100% 0, 80% 100%, 20% 100%)',
        transform: 'rotate(180deg)',
        minHeight: '10px',
        minWidth: '15px', // set width to match the height of the center button
        padding: '0px', // Adjust padding as needed
        fontSize: '5px', // Adjust font size as needed
      },
      buttonLeft: {
        top: '12px',
        left: '-1px',
        display: 'inline-block',
        backgroundColor: 'black',
        color: 'red',
        border: 'none',
        zIndex: 1,
        overflow: 'hidden',
        transition: 'all 0.8s ease',
        clipPath: 'polygon(0 0, 100% 0, 80% 100%, 20% 100%)',
        transform: 'rotate(270deg)',
        minHeight: '10px',
        minWidth: '15px', // set width to match the height of the center button
        padding: '0px', // Adjust padding as needed
        fontSize: '5px', // Adjust font size as needed
      },
      trapezoid: {
        width: '100px', /* Adjust the width as needed */
        height: '0', /* Set height to 0 so that it only shows the trapezoid shape */
        borderStyle: 'solid',
        borderWidth: '40px 25px 0 25px', /* Adjust the border-width values to control the trapezoid shape */
        borderColor: 'transparent transparent transparent transparent',
      },
  }));
  

function CustomButton({ controllerIndex,  onClick, InitialToothPartal }) {
  const classes = useStyles();
  const [buttonString, setButtonString] = useState('00000');
  const [buttonClicked, setButtonClicked] = useState([false, false, false, false, false]);

  useEffect(() => {
    // Set the initial state based on InitialToothPartal
    if (InitialToothPartal) {
      const initialString = InitialToothPartal.split('');
      setButtonClicked(initialString.map((char) => char === '1'));
      setButtonString(InitialToothPartal);
    }
  }, [InitialToothPartal]);

  const handleButtonClick = (index, value) => {
    const newButtonClicked = [...buttonClicked];
    const newString = buttonString.split('');

    if (newButtonClicked[index]) {
      // Button was clicked, reset the color and string
      newButtonClicked[index] = false;
      newString[index] = '0';
    } else {
      // Button was not clicked, set the color to blue and update the string
      newButtonClicked[index] = true;
      newString[index] = value;
    }

    setButtonClicked(newButtonClicked);
    setButtonString(newString.join(''));
    onClick(controllerIndex, newString.join(''));
  };

  return (
    <div className={classes.container}>
      <Button 
        variant="contained" 
        className={`${classes.button} ${classes.buttonTop}`}  
        style={{ backgroundColor: buttonClicked[0] ? 'red' : 'black' }} 
        onClick={() => handleButtonClick(0, '1')}>
          &nbsp;
      </Button>
      <Button 
        variant="contained" 
        className={`${classes.button} ${classes.buttonRight}`} 
        style={{ backgroundColor: buttonClicked[1] ? 'red' : 'black' }} 
        onClick={() =>  handleButtonClick(1, '1')}>
          &nbsp;
      </Button>
      <Button 
        variant="contained" 
        className={`${classes.button} ${classes.buttonBottom}`} 
        style={{ backgroundColor: buttonClicked[2] ? 'red' : 'black' }} 
        onClick={() =>  handleButtonClick(2, '1')}>
          &nbsp;
      </Button>
      <Button 
        variant="contained" 
        className={`${classes.button} ${classes.buttonLeft}`} 
        style={{ backgroundColor: buttonClicked[3] ? 'red' : 'black' }} 
        onClick={() =>  handleButtonClick(3, '1')}>
          &nbsp;
      </Button>
      <Button 
        variant="contained" 
        className={`${classes.button} ${classes.buttonCenter}`} 
        style={{ backgroundColor: buttonClicked[4] ? 'red' : 'black' }} 
        onClick={() =>  handleButtonClick(4, '1')}>
          &nbsp;
      </Button>
    </div>
  );
}



export default CustomButton;