import React, { useState, useEffect, useCallback, useRef } from "react";
import { Grid } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import CustomTextField from "../../control/CustomTextField";
import CrudController from "../../control/CrudController";
import InputLabel from "@material-ui/core/InputLabel";
import Tooth from "../../control/Tooth";
import AdvanceTooth from "../../control/AdvanceTooth";
import { makeStyles } from "@material-ui/core";
import { Select, MenuItem } from "@material-ui/core";
import CustomMessageBox from "../../control/CustomMessageBox";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import api from "../../Api.js";
import DynamicSnackBox from "../../control/DynamicPopupSnackBar";
import Orthodontic from "./Orthodontic.js";
import Rct from "./Rct";
import AdvProsthesis from "./AdvProsthesis";
import RemProsthesis from "./RemProsthesis";

const useStyles = makeStyles(() => ({
  advtooth: {
    position: "relative",
    marginTop: "1px",
    display: "flex",
    flexDirection: "row",
  },
}));

const Treatments = ({ updateComponentToRender }) => {
  //#region Variable Section
  const childRef = useRef(null);

  const [resetKey, setResetKey] = useState(0);
  // State to control clearing values in CrudController
  const [clearValues, setClearValues] = useState(false);

  const classes = useStyles();
  const [buttonStates] = useState({
    search: true,
    edit: true,
    save: true,
    clear: true,
    close: false,
    history: true,
    scan: true,
  });
  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertDescription, setAlertDescription] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [isMessageBoxOpen, setMessageBoxOpen] = useState(false);
  const [functionality, setFunctionality] = useState(null);

  const examTeethInitCommon = process.env.REACT_APP_NORMAL_TOOTH_SETTING;
  const examAdvaceTeethInitCommon = process.env.REACT_APP_ADVANCE_TOOTH_SETTING;

  const [id, setId] = useState(null);
  const [clinicNo, setClinicNo] = useState("");
  const [medications, setMedications] = useState("");
  const [extractionChangeTeeth, setExtractionChangeTeeth] =
    useState(examTeethInitCommon);
  const [drainedChangeTeeth, setDrainedChangeTeeth] =
    useState(examTeethInitCommon);
  const [esdChangeTeeth, setEsdChangeTeeth] = useState(
    examAdvaceTeethInitCommon
  );
  const [permenentChangeTeeth, setPermenentChangeTeeth] = useState(
    examAdvaceTeethInitCommon
  );
  const [usedMaterial, setUsedMaterial] = useState("");
  const [fullMouth, setFullMouth] = useState("");
  const [rct, setRct] = useState(null);
  const [orthodontic, setOrthodontic] = useState(null);
  const [advProsthesis, setAdvProsthesis] = useState(null);
  const [remProsthesis, setRemProsthesis] = useState(null);
  const [isRctClicked, setIsRctClicked] = useState(false);
  const [isOrthodonticClicked, setIsOrthodonticClicked] = useState(false);
  const [isAdvProsthesisClicked, setIsAdvProsthesisClicked] = useState(false);
  const [isRemProsthesisClicked, setIsRemProsthesisClicked] = useState(false);
  const [other, setOther] = useState("");

  const [createBy, setCreateBy] = useState("");
  const [updateBy, setUpdateBy] = useState("");

  const [render, setRender] = useState(null);

  //#endregion Variable Section

  //#region Main Event Handlers

  const clear = async (isMsgShow) => {
    if (isMsgShow === true) {
      setClinicNo("");
    }

    if (childRef.current) {
      childRef.current.handleClear(false);
    }

    setId(null);
    setMedications("");
    setExtractionChangeTeeth(examTeethInitCommon);
    setDrainedChangeTeeth(examTeethInitCommon);
    setEsdChangeTeeth(examAdvaceTeethInitCommon);
    setPermenentChangeTeeth(examAdvaceTeethInitCommon);
    setUsedMaterial("");
    setFullMouth("");

    setRct(null);
    setOrthodontic(null);
    setAdvProsthesis(null);
    setRemProsthesis(null);

    setIsRctClicked(false);
    setIsOrthodonticClicked(false);
    setIsAdvProsthesisClicked(false);
    setIsRemProsthesisClicked(false);
    setOther("");
    setIsOrthodonticClicked(false);
    setRender(null);

    resetAButton();
    if (isMsgShow === true) handleClearValues();
    if (isMsgShow === true)
      triggerAlertOn("Clear", "Successfully cleaned!", "success");
  };

  const save = async () => {
    try {
      api
        .post(`/treatment/save`, {
          clinic_no: clinicNo,
          visit_date: new Date(),
          medication: medications,
          extraction:
            extractionChangeTeeth === examTeethInitCommon
              ? null
              : extractionChangeTeeth,
          drained:
            drainedChangeTeeth === examTeethInitCommon
              ? null
              : drainedChangeTeeth,
          esd:
            esdChangeTeeth === examAdvaceTeethInitCommon
              ? null
              : esdChangeTeeth,
          permanent:
            permenentChangeTeeth === examAdvaceTeethInitCommon
              ? null
              : permenentChangeTeeth,
          usedmaterial: usedMaterial,
          fullmouth: fullMouth,
          rct: rct,
          orthodontic: orthodontic,
          advprosthesis: advProsthesis,
          remprosthesis: remProsthesis,
          other: other,
          app_ref_no: null,
          plan_no: null,
          com_code: sessionStorage.getItem("usercom"),
          create_dt: new Date(),
          create_by: createBy,
        })
        .then((response) => {
          // Extract lastId from response data
          const lastId = response.data.data;
          if (childRef.current) {
            childRef.current.save(lastId);
          }
        })
        .then(() => {
          clear(true);
          triggerAlertOn("Save", "Successfully saved!", "success");
        })
        .catch((error) => {
          if (error.response.status === 400) {
            triggerAlertOn("Save", error.response.data.error, "error");
          } else {
            triggerAlertOn(
              "Save",
              "There is an error occured while save the record. The error is " +
                error,
              "error"
            );
          }
        });
    } catch (error) {
      console.error(error);
      triggerAlertOn(
        "Save",
        "There is an error occured while save the record. The error is " +
          error,
        "error"
      );
    }
  };

  const edit = async () => {
    try {
      api
        .put(`/treatment/edit`, {
          id: id,
          medication: medications,
          extraction:
            extractionChangeTeeth === examTeethInitCommon
              ? null
              : extractionChangeTeeth,
          drained:
            drainedChangeTeeth === examTeethInitCommon
              ? null
              : drainedChangeTeeth,
          esd:
            esdChangeTeeth === examAdvaceTeethInitCommon
              ? null
              : esdChangeTeeth,
          permanent:
            permenentChangeTeeth === examAdvaceTeethInitCommon
              ? null
              : permenentChangeTeeth,
          usedmaterial: usedMaterial,
          fullmouth: fullMouth,
          rct: rct,
          orthodontic: orthodontic,
          advprosthesis: advProsthesis,
          remprosthesis: remProsthesis,
          other: other,
          app_ref_no: null,
          plan_no: null,
          update_dt: new Date(),
          update_by: updateBy,
        })
        .then(() => {
          let value = null;

          if (isRctClicked) {
            value = rct;
          }

          if (isOrthodonticClicked) {
            value = orthodontic;
          }

          if (isAdvProsthesisClicked) {
            value = advProsthesis;
          }

          if (isRemProsthesisClicked) {
            value = remProsthesis;
          }

          if (value !== null) {
            if (childRef.current) {
              childRef.current.edit(value, clinicNo);
            }
          } else {
            if (childRef.current) {
              childRef.current.save();
            }
          }
        })
        .then(() => {
          clear(true);
          triggerAlertOn("Edit", "Successfully edited!", "success");
        })
        .catch((error) => {
          console.error(error);
          triggerAlertOn(
            "Edit",
            "There is an error occured while edit the record. The error is " +
              error,
            "error"
          );
        });
    } catch (error) {
      console.error(error);
      triggerAlertOn(
        "Edit",
        "There is an error occured while edit the record. The error is " +
          error,
        "error"
      );
    }
  };

  const validatePage = () => {
    // Check required fields
    if (!clinicNo) {
      return false;
    }
    // All checks passed, return true
    return true;
  };

  const handleSave = () => {
    if (id != null) {
      triggerAlertOn(
        "Save",
        "Process terminated. You cannot save this again, try edit! ",
        "error"
      );
      return;
    }

    if (validatePage()) {
      handleButtonClick("Save");
    } else {
      triggerAlertOn(
        "Save",
        "Process terminated. Please check all the required fields! ",
        "error"
      );
    }
  };

  const handleClear = (isNoMsg) => {
    if (isNoMsg === true) {
      clear(false);
    } else {
      handleButtonClick("Clear");
    }
  };

  const handleEdit = () => {
    if (id == null) {
      triggerAlertOn(
        "Edit",
        "Process terminated. Seems this is a new record!",
        "error"
      );
      return;
    }
    handleButtonClick("Edit");
  };

  const handleSearch = async (_id) => {
    try {
      const response = await api.get(`/treatment/id/${_id}`);
      //console.log(id);
      const data = (await response).data.data;
      // eslint-disable-next-line eqeqeq
      const clinicData = data.find((item) => item.id === _id);
      clear(false);

      if (clinicData) {
        setId(clinicData.id);
        setClinicNo(clinicData.clinic_no);
        setMedications(clinicData.medication);

        setExtractionChangeTeeth(
          clinicData.extraction == null
            ? examTeethInitCommon
            : clinicData.extraction
        );
        setDrainedChangeTeeth(
          clinicData.drained == null ? examTeethInitCommon : clinicData.drained
        );
        setEsdChangeTeeth(
          clinicData.esd == null ? examTeethInitCommon : clinicData.esd
        );
        setPermenentChangeTeeth(
          clinicData.permanent == null
            ? examTeethInitCommon
            : clinicData.permanent
        );

        setUsedMaterial(clinicData.usedmaterial);
        setFullMouth(clinicData.fullmouth);

        setRct(clinicData.rct);
        setOrthodontic(clinicData.orthodontic);
        setAdvProsthesis(clinicData.advprosthesis);
        setRemProsthesis(clinicData.remprosthesis);
        setOther(clinicData.other);
        // if (childRef.current) {
        //   childRef.current.handleSearch(clinicData.orthodontic);
        // }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    // Handle search logic
  };

  const handleHistory = () => {
    // Handle search logic
    // eslint-disable-next-line eqeqeq
    if (clinicNo != null && clinicNo != "") {
      handleOpenModal();
    } else {
      triggerAlertOn(
        "History",
        "There are no history records to show.",
        "error"
      );
      return;
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderListener = useCallback((e) => {
    setRender(null);
  });

  const handleRctClick = async () => {
    setIsRctClicked(isRctClicked === true ? false : true); // Toggle the button color state
  };

  const clickRCT = (e) => {
    setIsOrthodonticClicked(false);
    setIsAdvProsthesisClicked(false);
    setIsRemProsthesisClicked(false);
    let refObj = {
      refModule: "RCT",
      refTreatmentId: id,
      refModuleId: rct,
      refClinicId: clinicNo,
    };

    setIsRctClicked((prevState) => {
      const newState = !prevState;
      if (newState) {
        // When showing, set the render to the Two component
        setRender(
          isRctClicked === true ? null : (
            <Rct
              ref={childRef}
              refModule={refObj}
              updateComponentToRender={renderListener}
            />
          )
        );
      } else {
        // When hiding, clear the render
        setRender(null);
      }
      return newState;
    });

    handleRctClick();
  };

  const handleOrthodonticClick = async () => {
    setIsOrthodonticClicked(isOrthodonticClicked === true ? false : true); // Toggle the button color state
  };

  const clickOrthodontic = (e) => {
    setIsRctClicked(false);
    setIsAdvProsthesisClicked(false);
    setIsRemProsthesisClicked(false);
    let refObj = {
      refModule: "ORT",
      refTreatmentId: id,
      refModuleId: orthodontic,
      refClinicId: clinicNo,
    };

    setIsOrthodonticClicked((prevState) => {
      const newState = !prevState;
      if (newState) {
        // When showing, set the render to the Two component
        setRender(
          isOrthodonticClicked === true ? null : (
            <Orthodontic
              ref={childRef}
              refModule={refObj}
              updateComponentToRender={renderListener}
            />
          )
        );
      } else {
        // When hiding, clear the render
        setRender(null);
      }
      return newState;
    });

    handleOrthodonticClick();
  };

  const handleAdvProsthesisClick = async () => {
    setIsAdvProsthesisClicked(isAdvProsthesisClicked === true ? false : true); // Toggle the button color state
  };

  const clickAdvProsthesis = (e) => {
    setIsOrthodonticClicked(false);
    setIsRctClicked(false);
    setIsRemProsthesisClicked(false);
    let refObj = {
      refModule: "ADP",
      refTreatmentId: id,
      refModuleId: advProsthesis,
      refClinicId: clinicNo,
    };

    setIsAdvProsthesisClicked((prevState) => {
      const newState = !prevState;
      if (newState) {
        // When showing, set the render to the Two component
        setRender(
          isAdvProsthesisClicked === true ? null : (
            <AdvProsthesis
              ref={childRef}
              refModule={refObj}
              updateComponentToRender={renderListener}
            />
          )
        );
      } else {
        // When hiding, clear the render
        setRender(null);
      }
      return newState;
    });

    handleAdvProsthesisClick();
  };

  const handleRemProsthesisClick = async () => {
    setIsRemProsthesisClicked(isRemProsthesisClicked === true ? false : true); // Toggle the button color state
  };

  const clickRemProsthesis = (e) => {
    setIsOrthodonticClicked(false);
    setIsAdvProsthesisClicked(false);
    setIsRctClicked(false);
    let refObj = {
      refModule: "REP",
      refTreatmentId: id,
      refModuleId: remProsthesis,
      refClinicId: clinicNo,
    };

    setIsRemProsthesisClicked((prevState) => {
      const newState = !prevState;
      if (newState) {
        // When showing, set the render to the Two component
        setRender(
          isRemProsthesisClicked === true ? null : (
            <RemProsthesis
              ref={childRef}
              refModule={refObj}
              updateComponentToRender={renderListener}
            />
          )
        );
      } else {
        // When hiding, clear the render
        setRender(null);
      }
      return newState;
    });
    handleRemProsthesisClick();
  };

  //#endregion Main Event Handlers

  //#region Other Events

  const convertDictionaryToString = (dictionary) => {
    return Object.entries(dictionary)
      .map(([key, value]) => `${key}.${value}`)
      .join(",");
  };

  const handleClinicNoChange = (newClinicNo) => {
    setClinicNo(newClinicNo);
    setId(null);
  };

  const handleExtractionChangeTeeth = (myDictionary) => {
    setExtractionChangeTeeth(convertDictionaryToString(myDictionary));
  };

  const handleDrainedChangeTeeth = (myDictionary) => {
    setDrainedChangeTeeth(convertDictionaryToString(myDictionary));
  };

  //#endregion Other Events

  //#region outside controller compartments

  const handleButtonClick = (buttonId) => {
    if (buttonId === "Save") {
      setMessage("Do you really want to save this treatment?");
    } else if (buttonId === "Edit") {
      setMessage("Do you really want to edit this treatment?");
    } else if (buttonId === "Clear") {
      setMessage("Do you really want to clear this treatment?");
    }
    setFunctionality(buttonId);
    setMessageBoxOpen(true);
  };

  const handleMessageBoxClose = (isPrimaryButtonClicked) => {
    if (isPrimaryButtonClicked && functionality === "Save") {
      save();
    }
    if (isPrimaryButtonClicked && functionality === "Clear") {
      clear(true);
    }

    if (isPrimaryButtonClicked && functionality === "Edit") {
      edit();
    }

    // Set isMessageBoxOpen to false to hide the message box
    setMessageBoxOpen(false);
  };

  const triggerAlertOn = async (title, description, severity) => {
    setAlertTitle(title);
    setAlertDescription(description);
    setAlertSeverity(severity);
    setShowAlert(true);

    setTimeout(() => {
      setAlertTitle("");
      setAlertDescription("");
      setAlertSeverity("");
      setShowAlert(false);
    }, 2000);
  };

  useEffect(() => {
    setCreateBy(sessionStorage.getItem("userid"));
    setUpdateBy(sessionStorage.getItem("userid"));
  }, []);

  const resetAButton = () => {
    // Update the key to remount component A and reset its state
    setResetKey((prevKey) => prevKey + 1);
  };

  const handleClearValues = () => {
    // Set clearValues to true to trigger the clearing in CrudController
    setClearValues(true);
  };

  // Callback function to reset clearValues in CrudController
  const handleClearComplete = () => {
    setClearValues(false);
  };

  //#endregion outside controller compartments

  const handleDataFetchStatus = (status) => {
    //console.log(status);
    if (status === false) {
      triggerAlertOn(
        "Search",
        `This is an invalid clinic no. Please check the number and try again.`,
        "error"
      );
      setClinicNo("");
    }
  };

  const searchFields = [
    { label: "Visit #", value: "visit_sequence" },
    { label: "Date", value: "visit_date" },
    { label: "id", value: "id" },
  ];

  const endpointComponent = "treatment";
  const endPointRouter = "visit";

  const handleRowSingleClick = (id) => {
    //console.log("selected id " + id);
    setId(id);
    setOpenModal(false); // Close the modal after selecting a row
    handleSearch(id);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  function color(value, isCondition) {
    if (value != null && isCondition === true) {
      return "#f44336"; // Red
    } else if (value != null && isCondition === false) {
      return "#009688"; // light blue
    } else if (value == null && isCondition === false) {
      return "#2c387e"; // default blue
    } else if (value == null && isCondition === true) {
      return "#f44336"; // red
    }
  }

  const [openModal, setOpenModal] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        marginTop: "16px",
        marginLeft: "16px",
      }}
    >
      <Grid container spacing={2}>
        {/* 1 Row */}
        <CrudController
          onSave={handleSave}
          onClear={handleClear}
          onEdit={handleEdit}
          onSearch={handleSearch}
          onClose={handleClose}
          onHistory={handleHistory}
          clinicNo={clinicNo}
          onClinicNoChange={handleClinicNoChange}
          buttonStates={buttonStates}
          isNameVisible={true}
          clearValues={clearValues}
          onClearComplete={handleClearComplete}
          onDataFetchStatus={handleDataFetchStatus}
          isClinicNoVisible={true}
        />

        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Paper
              elevation={0}
              style={{ padding: 5, textAlign: "left", paddingTop: 20 }}
            >
              <CustomTextField
                label="Medications"
                type="text"
                id="reason"
                width="1030px"
                value={medications}
                onChange={(e) => setMedications(e.target.value)}
              />
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Paper elevation={0} style={{ padding: 5, textAlign: "left" }}>
              <InputLabel
                id="title-label"
                style={{ width: 200, paddingBottom: 10, paddingTop: 10 }}
              >
                Extraction of
              </InputLabel>
              <Tooth
                initialButtonString={extractionChangeTeeth}
                key={resetKey}
                onClickPatternChange={handleExtractionChangeTeeth}
              />
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper
              elevation={0}
              style={{ padding: 5, paddingLeft: 140, textAlign: "left" }}
            >
              <InputLabel
                id="title-label"
                style={{ width: 200, paddingBottom: 10, paddingTop: 10 }}
              >
                Drained abscess related to
              </InputLabel>
              <Tooth
                initialButtonString={drainedChangeTeeth}
                key={resetKey}
                onClickPatternChange={handleDrainedChangeTeeth}
              />
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Paper elevation={0} style={{ padding: 5, textAlign: "left" }}>
              <InputLabel
                id="title-label"
                style={{ width: 200, paddingBottom: 10, paddingTop: 10 }}
              >
                ESD on
              </InputLabel>
              <div className={classes.advtooth}>
                <AdvanceTooth
                  initialButtonString={esdChangeTeeth}
                  key={resetKey}
                  ClickSelectedDictionary={(value) => setEsdChangeTeeth(value)}
                />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper
              elevation={0}
              style={{ padding: 5, paddingLeft: 140, textAlign: "left" }}
            >
              <InputLabel
                id="title-label"
                style={{ width: 200, paddingBottom: 10, paddingTop: 10 }}
              >
                Permanent restoration on
              </InputLabel>
              <div className={classes.advtooth}>
                <AdvanceTooth
                  initialButtonString={permenentChangeTeeth}
                  key={resetKey}
                  ClickSelectedDictionary={(value) =>
                    setPermenentChangeTeeth(value)
                  }
                />
              </div>
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Paper elevation={0} style={{ padding: 5, textAlign: "left" }}>
              &nbsp;
            </Paper>
          </Grid>
          <Grid item xs={3} style={{ padding: 5, paddingLeft: 355 }}>
            <Paper elevation={0} style={{ padding: 5, paddingLeft: 140 }}>
              <InputLabel
                id="usedM-label"
                style={{ width: 200, paddingBottom: 10, paddingTop: 10 }}
              >
                Used Materials
              </InputLabel>
              <Select
                value={usedMaterial}
                onChange={(e) => setUsedMaterial(e.target.value)}
                labelId="usedM-label"
                displayEmpty
                style={{ width: "210px" }}
              >
                <MenuItem value={null} disabled>
                  {" "}
                  --select--{" "}
                </MenuItem>
                <MenuItem value={"GIC LC"}>GIC LC</MenuItem>
                <MenuItem value={"GIC IX"}>GIC IX</MenuItem>
                <MenuItem value={"Zerconomer"}>Zerconomer</MenuItem>
                <MenuItem value={"Composite"}>Composite</MenuItem>
                <MenuItem value={"Composite+GIC"}>Composite+GIC</MenuItem>
                <MenuItem value={"On Post Buildup"}>On Post Buildup</MenuItem>
              </Select>
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Paper elevation={0} style={{ padding: 5, textAlign: "left" }}>
              <InputLabel
                id="fullM-label"
                style={{ width: 200, paddingBottom: 10, paddingTop: 10 }}
              >
                Full mouth scaling
              </InputLabel>
              <Select
                value={fullMouth}
                onChange={(e) => setFullMouth(e.target.value)}
                labelId="fullM-label"
                displayEmpty
                style={{ width: "210px" }}
              >
                <MenuItem value={null} disabled>
                  {" "}
                  --select--{" "}
                </MenuItem>
                <MenuItem value={"Calculus-Normal"}>Calculus-Normal</MenuItem>
                <MenuItem value={"Calculus-Heavy"}>Calculus-Heavy</MenuItem>
                <MenuItem value={"Stains-Normal"}>Stains-Normal</MenuItem>
                <MenuItem value={"Stains-Moderate"}>Stains-Moderate</MenuItem>
                <MenuItem value={"Stains-Heavy"}>Stains-Heavy</MenuItem>
              </Select>
            </Paper>
          </Grid>
          <Grid item xs={3} style={{ padding: 5, paddingLeft: 440 }}>
            <Paper elevation={0} style={{ padding: 5, paddingLeft: 140 }}>
              &nbsp;
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Paper
              elevation={0}
              style={{ padding: 5, textAlign: "left", paddingTop: 10 }}
            >
              <CustomTextField
                label="Other"
                type="text"
                id="reason"
                width="1430px"
                value={other}
                onChange={(e) => setOther(e.target.value)}
              />
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={3}>
            <Paper
              elevation={0}
              style={{ padding: 5, textAlign: "left", paddingTop: 10 }}
            >
              <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: color(rct, isRctClicked),
                  color: "white",
                  width: 220,
                }}
                onClick={clickRCT}
              >
                {" "}
                RCT{" "}
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper
              elevation={0}
              style={{ padding: 5, textAlign: "left", paddingTop: 10 }}
            >
              <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: color(orthodontic, isOrthodonticClicked),
                  color: "white",
                  width: 220,
                }}
                onClick={clickOrthodontic}
              >
                {" "}
                Orthodontic{" "}
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper
              elevation={0}
              style={{ padding: 5, textAlign: "left", paddingTop: 10 }}
            >
              <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: color(advProsthesis, isAdvProsthesisClicked),
                  color: "white",
                  width: 220,
                }}
                onClick={clickAdvProsthesis}
              >
                {" "}
                Advanced Prosthesis{" "}
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper
              elevation={0}
              style={{ padding: 5, textAlign: "left", paddingTop: 10 }}
            >
              <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: color(remProsthesis, isRemProsthesisClicked),
                  color: "white",
                  width: 220,
                }}
                onClick={clickRemProsthesis}
              >
                {" "}
                Removable Prosthesis{" "}
              </Button>
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Paper
              elevation={0}
              style={{ padding: 5, textAlign: "left", paddingTop: 10 }}
            >
              {render}
            </Paper>
          </Grid>
        </Grid>
      </Grid>

      <CustomMessageBox
        open={isMessageBoxOpen}
        onClose={handleMessageBoxClose}
        title="Treatment"
        message={message}
        primaryButtonCaption="OK"
        secondaryButtonCaption="Cancel"
        showPrimaryButton
        showSecondaryButton
      />
      <DynamicSnackBox
        endpointComponent={endpointComponent}
        endPointRouter={endPointRouter}
        columns={searchFields}
        onRowDoubleClick={handleRowSingleClick}
        handleCloseModal={handleCloseModal}
        clinicNo={clinicNo}
        openModal={openModal} // Pass modalOpen state as open prop
        showCloseButton={false}
      />
      {showAlert && (
        <div
          style={{
            position: "fixed",
            top: "155px",
            left: "610px",
            width: 890,
            height: 150,
            transform: "translate(-50%, -50%)",
            zIndex: 1000,
          }}
        >
          <Alert severity={alertSeverity}>
            <AlertTitle style={{ fontSize: "20px" }}>{alertTitle}</AlertTitle>
            <strong style={{ fontSize: "20px" }}>{alertDescription}</strong>
          </Alert>
        </div>
      )}
    </div>
  );
};

export default Treatments;
