import api from "../Api";

const Logout = async () => {
  try {
    const response = await api.post("/logout");
    // Logout successful
    // Optionally, redirect the user to the login page or clear any application state
  } catch (error) {
    console.error("Logout failed", error);
  }
};

export default Logout;
