import React, { useState } from "react";
import { Button, TextField, Paper, makeStyles } from "@material-ui/core";
import backgroundImage from "../../images/PS_LOGO.png";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import api from "../Api.js";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    width: "100vw",
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "contain", // Ensures the image scales to fit within the viewport
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    overflow: "hidden", // Prevents overflow of the image
  },
  loginContainer: {
    background: "linear-gradient(to bottom,#ddb1fa, #addeff)",
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '90%',
    width: 400,
    borderRadius: 8,
    boxShadow: theme.shadows[5],
  },
  loginInput: {
    marginBottom: theme.spacing(2),
    width: '100%',
    backgroundColor: 'transparent', // Set background to transparent
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#770737', // Adjust the border color if needed (optional)
      },
      '&:hover fieldset': {
        borderColor: '#FFFFFF', // Border color on hover (optional)
      },
      '&.Mui-focused fieldset': {
        borderColor: '#DA70D6', // Border color when focused (optional)
      },
    },
    '& input': {
      color: '#000000', // Ensure text color is visible (optional)
    },
  },
  
  alertContainer: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '90%',
    maxWidth: 890,
    zIndex: 1000,
  },
  '@media (min-width:600px)': {
    loginContainer: {
      width: 400,
    },
  },
  '@media (max-width:600px)': {
    loginContainer: {
      width: '90%',
    },
  },
}));

function LoginPage({ setIsAuthenticated, setLoggedUserName }) {
  const classes = useStyles();
  const [uiUsername, setUsername] = useState("");
  const [uiPassword, setPassword] = useState("");
  const [, setUserCompany] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertDescription, setAlertDescription] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");

  const triggerAlertOn = async (title, description, severity) => {
    setAlertTitle(title);
    setAlertDescription(description);
    setAlertSeverity(severity);
    setShowAlert(true);

    setTimeout(() => {
      setAlertTitle("");
      setAlertDescription("");
      setAlertSeverity("");
      setShowAlert(false);
    }, 2000);
  };

  const handleSubmit = async () => {
    if (uiUsername === null || uiPassword === null) {
      triggerAlertOn("Authorization", "Required fields are empty", "error");
      return;
    }

    try {
      let username = "admin",
        password = "admin";
      await api.post("/login", { username, password });

      // Perform authorization
      const response = await api.post(`/user/authorize`, {
        userid: uiUsername,
        password: uiPassword,
      });

      // Successful login
      const data = (await response).data.data;
      if (data.length === 0) {
        triggerAlertOn(
          "Authorization",
          "Unsuccessful Authorization!",
          "error"
        );
        return;
      } else {
        triggerAlertOn("Authorization", "Successfully Authorized!", "success");

        setIsAuthenticated(true);
        setUserCompany(data[0].com_code);
        setLoggedUserName(data[0].username);
        sessionStorage.setItem("username", data[0].username);
        sessionStorage.setItem("usercom", data[0].com_code);
        sessionStorage.setItem("userid", uiUsername);
        sessionStorage.setItem(
          "profilepic",
          data[0].profile_pic == null
            ? null
            : process.env.REACT_APP_PATIENT_PROFILE +
                process.env.REACT_APP_USER_PROFILE +
                data[0].profile_pic.replace("\\", "/")
        );
        localStorage.setItem("isAuthenticated", "true");
        sessionStorage.setItem("isAuthenticated", true);
      }
    } catch (error) {
      console.error(error);
      triggerAlertOn(
        "Authorization",
        "There was an error while saving the record. The error is " +
          error,
        "error"
      );
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.loginContainer}>
        <TextField
          label="Username"
          variant="outlined"
          className={classes.loginInput}
          value={uiUsername}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          label="Password"
          variant="outlined"
          type="password"
          className={classes.loginInput}
          value={uiPassword}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Paper>
      {showAlert && (
        <div className={classes.alertContainer}>
          <Alert severity={alertSeverity}>
            <AlertTitle style={{ fontSize: "20px" }}>{alertTitle}</AlertTitle>
            <strong style={{ fontSize: "20px" }}>{alertDescription}</strong>
          </Alert>
        </div>
      )}
    </div>
  );
}

export default LoginPage;
