// import JsBarcode from 'jsbarcode';
// import { useEffect, useRef } from 'react';

// const BarCodeGenerator = ({ value }) => {
//   const barcodeRef = useRef(null);

//   useEffect(() => {
//     if(value!=null)
//     JsBarcode(barcodeRef.current, value, {
//       format: 'CODE128',
//       lineColor: 'black',
//       width: 2,
//       height: 45,
//       displayValue: false,
//       background : 'white',
//       margin : 5,

//     });
//   }, [value]);

//   return (
//     <svg ref={barcodeRef}></svg>
//   );
// };

// export default BarCodeGenerator;


// import QRCode from 'qrious'; // Importing the QRCode library
// import { useEffect, useRef } from 'react';

// const QRCodeGenerator = ({ value }) => {
//   const qrRef = useRef(null);

//   useEffect(() => {
//     if (qrRef.current && value) {
//       // Clear previous content (if needed)
//       qrRef.current.innerHTML = ''; 

//       // Create a new QRCode instance
//       new QRCode({
//         element: qrRef.current, // Reference to the element to render the QR code
//         value: value, // Value to encode
//         size: 65, // Size of the QR code
//         level: 'H', // Error correction level: L, M, Q, H
//       });
//     }
//   }, [value]);

//   return (
//     <canvas ref={qrRef}></canvas> // Use canvas for rendering the QR code
//   );
// };

// export default QRCodeGenerator;


import { QRCodeSVG } from 'qrcode.react'; // Import QRCodeSVG from 'qrcode.react'

const QRCodeGenerator = ({ value }) => {
  return (
    value!=null ? (
      <QRCodeSVG
        value={String(value)}
        size={65}  // Size of the QR code
        level="H"   // Error correction level
      />
    ) : null
  );
};

export default QRCodeGenerator;

