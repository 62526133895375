import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialogTitle-root': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
    '& .MuiDialogContent-root': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
    },
    '& .MuiDialogActions-root': {
      backgroundColor: theme.palette.primary.dark,
      justifyContent: 'space-around',
      padding: theme.spacing(1),
    },
    '& .MuiButton-root': {
      color: theme.palette.primary.contrastText,
    },
  },
}));

function CustomMessageBox  ({
  open,
  onClose,
  title,
  message,
  primaryButtonCaption,
  secondaryButtonCaption,
  showPrimaryButton,
  showSecondaryButton,
}) {

  const classes = useStyles();
  
  return (
    <Dialog open={open} onClose={onClose} className={classes.root}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        {message}
      </DialogContent>
      <DialogActions >
        {showPrimaryButton && (
          <Button onClick={() => onClose(true)} style={{ color: '#000000', backgroundColor: '#FFD700' }} variant="contained"> 
            {primaryButtonCaption}
          </Button>
        )}
        {showSecondaryButton && (
          <Button onClick={() => onClose(false)} style={{ color: '#000000', backgroundColor: '#32CD32' }} variant="contained">
            {secondaryButtonCaption}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CustomMessageBox;
