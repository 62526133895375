import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import api from "../Api.js";

const useStyles = makeStyles({
  dialogContent: {
    padding: 0,
    width: '200px',  // Set desired width
    position: 'relative', // Ensure the content container can position the button
    backgroundColor: '#1f2c2c',
  },
  tableContainer: {
    width: '100%',
    height: '100%',
    overflowX: 'auto',
  },
  table: {
    borderCollapse: 'collapse',
    width: '100%',
    height: '100%',
    '& thead tr th': {
      backgroundColor: '#1f2c2c',
      color: 'white',
      padding: '.3rem',
      textAlign: 'center',
    },
    '& tbody tr:nth-child(even)': {
      backgroundColor: '#9ee0de',
      padding: '.1rem',
    },
    '& tbody tr:nth-child(odd)': {
      backgroundColor: '#8ec9c7',
      padding: '.1rem',
    },
    '& tbody tr:hover': {
      backgroundColor: '#ffd202',
      color: 'black',
      padding: '.1rem',
    },
    '& td': {
      padding: '.2rem',
      height: '10px',
      textAlign: 'center',
    },
    '& th, & td': {
      width: '100px',
    },
    '& th:first-child, & td:first-child': {
      width: '75px',
    },
    '& th:nth-child(7), & td:nth-child(7)': {
      width: '100px',
    },
  },
  hiddenColumn: {
    display: 'none',
  },
  closeButton: {
    position: 'absolute',
    top: '-10px',
    right: '0px',
    color: 'white',  // Set close button color to white
  },
});

const DynamicSearchSnackBox = ({ endpointComponent, endPointRouter, columns, onRowDoubleClick, clinicNo, openModal, handleCloseModal }) => {
  const classes = useStyles();
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if(clinicNo==="" || clinicNo === "undefined" || clinicNo===null) return;
      try {

        const response = await api.get(`/${endpointComponent}/${endPointRouter}/${clinicNo}`);
        const dbData = response.data.data;
        if (dbData.length === 0) {
          setSearchResults([{ id: 'no-history', message: 'No history available' }]);
        } else {
          setSearchResults(dbData);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, [endpointComponent, endPointRouter, clinicNo]);

  const handleRowDoubleClick = (id) => {
    onRowDoubleClick(id);
    handleCloseModal();
  };

  return (
    <Dialog open={openModal} onClose={handleCloseModal} maxWidth="md">
      <DialogContent className={classes.dialogContent}>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseModal}>
          <CloseIcon />
        </IconButton>
        <div className={classes.tableContainer}>
          <table className={classes.table}>
            <thead>
              <tr>
                {columns.map(col => (
                  <th key={col.value} className={col.value === 'id' ? classes.hiddenColumn : ''}>
                    {col.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {searchResults.length === 1 && searchResults[0].id === 'no-history' ? (
                <tr>
                  <td colSpan={columns.length}>No history available</td>
                </tr>
              ) : (
                searchResults.map(result => (
                  <tr key={result.id} onDoubleClick={() => handleRowDoubleClick(result.id)}>
                    {columns.map(col => (
                      <td key={col.value} className={col.value === 'id' ? classes.hiddenColumn : ''}>
                        {result[col.value]}
                      </td>
                    ))}
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DynamicSearchSnackBox;
