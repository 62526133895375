/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import { TextField, Button, Grid, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import api from "../Api.js";

const useStyles = makeStyles({
  tableContainer: {
    maxWidth: 1000,
    margin: 'auto',
    marginTop: '.5rem',
    marginBottom: '1rem',
    overflowX: 'auto',
    width: '865px', // adjust as needed
    height: '100%', // adjust as needed
  },
  table: {
    borderCollapse: 'collapse',
    width: '100%', // Use 100% width for the table container

    '& thead tr th': {
      backgroundColor: '#8134d7',
      color: 'white',
      padding: '.5rem',
    },
    '& tbody tr:nth-child(even)': {
      backgroundColor: '#9ee0de',
      padding: '.1rem',
    },
    '& tbody tr:hover': {
      backgroundColor: '#ffd202',
      color: 'black',
      padding: '.1rem',
    },
    '& td': {
      padding: '.5rem',
      height: '10px',
    },
    '& th, & td': {
      width: '120px', // Set a fixed width for all columns
    },
    '& th:first-child, & td:first-child': {
      width: '100px', // Adjust the width for the first column
    },
    '& th:nth-child(7), & td:nth-child(7)': {
      width: '100px', // Adjust the width for the seventh column
    },
  },
});

  

const PatientSearch = ({ onRowDoubleClick, onClose  }) => {
  const classes = useStyles();
  const [searchType, setSearchType] = useState('first name');
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState([]);


  const handleSearchTypeChange = (event) => {
    setSearchType(event.target.value);
  };

  const handleSearchValueChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleCloseButtonClick = () => {
    onClose();
  };

  const handleSearchButtonClick = async () => {
    //const response = await fetch(`your_external_api_url/search?type=${searchType}&value=${searchValue}`);
    //const data = await response.json();
     var response;
     const nextval = 1;
     if(searchValue===null || searchValue===''){
      return;
     }
    if(searchType==="first name") {
        response= await api.get(`/patient/byfirstname/${searchValue}/${nextval}`);
    } else if((searchType==="last name")) {
        response= await api.get(`/patient/bylastname/${searchValue}/${nextval}`);
    } else if (searchType==="NIC"){
        response= await api.get(`/patient/bynic/${searchValue}/${nextval}`);
    } else if (searchType==="city") {
        response= await api.get(`/patient/bycity/${searchValue}/${nextval}`);
    }
    
    const dbData = response.data.data;
    setSearchResults(dbData);
  };

  const handleRowDoubleClick = (clinicNo) => {
    onRowDoubleClick(clinicNo);
  };

  return (
    <div  style={{width:"598px"}}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <TextField select label="Search By" value={searchType} onChange={handleSearchTypeChange} style={{ width: '150px' }} defaultValue="first name">
            <MenuItem value="first name">First Name</MenuItem>
            <MenuItem value="last name">Last Name</MenuItem>
            <MenuItem value="NIC">NIC</MenuItem>
            <MenuItem value="city">City</MenuItem>
          </TextField>
        </Grid>
        <Grid item>
          <TextField label={`Enter ${searchType}`} value={searchValue} onChange={handleSearchValueChange} />
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleSearchButtonClick}>Search</Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="secondary" onClick={handleCloseButtonClick}>Close</Button>
        </Grid>
      </Grid>
      <div className={classes.tableContainer}>
        {(searchResults.length == 0 ||searchResults.length > 0   )&& (
          <table className={classes.table} style={{ display: 'table-header-group' }}>
            <thead>
              <tr>
                <th>Clinic No</th>
                <th>First Name</th>
                <th>Middle Name</th>
                <th>Last Name</th>
                <th>NIC</th>
                <th>Birthday</th>
                <th>City</th>
              </tr>
            </thead>
            <tbody>
              {searchResults.map(result => (
                <tr key={result.clinic_no} onClick={() => handleRowDoubleClick(result.clinic_no)}>
                  <td>{result.clinic_no}</td>
                  <td>{result.f_name}</td>
                  <td>{result.m_name}</td>
                  <td>{result.l_name}</td>
                  <td>{result.nic}</td>
                  <td>{result.bday}</td>
                  <td>{result.city}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
  
};


export default PatientSearch;
