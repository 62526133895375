import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Card, CardContent, CardMedia, IconButton, Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useDropzone } from 'react-dropzone';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import AddCircleOutlineSharpIcon from '@material-ui/icons/AddCircleOutlineSharp';

const GalaryImageUploader = forwardRef(({ onImageChange }, ref) => {
  const [images, setImages] = useState([]);
  const [stream, setStream] = useState(null);
  const videoRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [streaming, setStreaming] = useState(false);
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);

  const startCamera = async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true });
      setStream(mediaStream);
      if (videoRef.current) {
        videoRef.current.srcObject = mediaStream;
      }
      mediaRecorderRef.current = new MediaRecorder(mediaStream);
      mediaRecorderRef.current.ondataavailable = handleDataAvailable;
    } catch (error) {
      console.error('Error accessing camera:', error);
    }
  };

  const handleDataAvailable = (event) => {
    if (event.data.size > 0) {
      chunksRef.current.push(event.data);
    }
  };

  const stopCamera = () => {
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach(track => track.stop());
      setStream(null);
    }
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
      mediaRecorderRef.current.stop();
      videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
    }
    setStreaming(false);
  };

  const captureImage = () => {
    setCapturing(true);
    const canvas = document.createElement('canvas');
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    canvas.getContext('2d').drawImage(videoRef.current, 0, 0);
    const imageUrl = canvas.toDataURL('image/png');
    const updatedImages = [...images, imageUrl];
    setImages(updatedImages);
    onImageChange(updatedImages);
    stopCamera();
    setStreaming(false);
  };

  const onDrop = (acceptedFiles) => {
    const newImages = [];
    acceptedFiles.forEach(file => {
      const reader = new FileReader();
      reader.onload = () => {
        newImages.push(reader.result);
        if (newImages.length === acceptedFiles.length) {
          const updatedImages = [...images, ...newImages];
          setImages(updatedImages);
          onImageChange(updatedImages);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const deleteImage = (index) => {
    const updatedImages = images.filter((_, i) => i !== index);
    setImages(updatedImages);
    onImageChange(updatedImages);
  };

  const clearImages = () => {
    setImages([]);
    onImageChange([]);
    setCapturing(false);
  };

  const setDbImages = (images) => {
    setImages(images);
    onImageChange(images);
  };

  useImperativeHandle(ref, () => ({
    clearImages,
    setDbImages
  }));

  const uploadImages = () => {
    onImageChange(images);
    setImages([]); // Clear images after upload
    setCapturing(false);
  };

  const openCamera = () => {
    if (streaming) {
      setStreaming(false);
      setCapturing(true);
      captureImage();
    } else {
      setStreaming(true);
      setCapturing(false);
      startCamera();
    }
  };

  useEffect(() => {
    onImageChange(images);
    setImages(images);
  }, [images]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
    noClick: streaming,
  });

  return (
    <Card style={{ outline: 'none', boxShadow: 'none', display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'grab' }}>
      <div {...getRootProps()} style={{ marginLeft: '0' }}>
        <input {...getInputProps()} />
        {streaming ? (
          <video ref={videoRef} autoPlay style={{ width: '340px' }} />
        ) : (
          images.length > 0 ? (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {images.map((image, index) => (
                <CardMedia
                  key={index}
                  component="img"
                  alt={`Uploaded Image ${index}`}
                  image={image}
                  style={{ width: '150px', height: '150px', margin: '5px' }}
                />
              ))}
            </div>
          ) : (
            <CardContent style={{ display: 'flex', alignItems: 'center', height: '150px', paddingLeft: 75 }}>
              <IconButton id='CloudUploadButton' color="primary" style={{ padding: 0, margin: 0 }} onClick={uploadImages}>
                <CloudUploadIcon fontSize="large" />
              </IconButton>
            </CardContent>
          )
        )}
      </div>
      <CardContent style={{ display: 'flex', alignItems: 'center', height: '150px', paddingLeft: 15 }}>
        <IconButton id='CameraUploadButton' color="primary" style={{ padding: 0, margin: 0 }} onClick={openCamera}>
          {streaming ? (<AddCircleOutlineSharpIcon fontSize="large" />) : (<PhotoCameraIcon fontSize="large" />)}
        </IconButton>
      </CardContent>
      {images.length > 0 && (
        <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: 1 }}>
          {images.map((image, index) => (
            <IconButton key={index} color="secondary" style={{ padding: 0, margin: 0 }} onClick={() => deleteImage(index)}>
              <DeleteIcon fontSize="large" />
            </IconButton>
          ))}
          
        </CardContent>
      )}
    </Card>
    
  );
});

export default GalaryImageUploader;
