//import uuidv4 from 'uuid/v4';
//import { round10 } from 'round10';
import { useEffect, useRef, useState } from "react";
import { render } from "react-dom";

// String to return if points or percents are not defined or null
//const UNDEFINED_OR_NULL = '--';
// Decimal precision to use for rounding points and percents
//const DEFAUlT_PRECISION = 1;

export const capitalizeWords = (str) => {
  return str
    .toLowerCase()
    .replace(/(^|\s)\S/g, (letter) => letter.toUpperCase());
};

export const toLowerCase = (input) => (input ? input.toLowerCase() : input);

export const toInteger = (input) => (input ? parseInt(input, 10) : input);

export const toFloat = (input) => (input ? parseFloat(input) : input);

export const randomizeArray = (a) => {
  // Assign each element of the given array a random number and then sort by that number.
  const rndArray = [];

  for (let i = 0, len = a.length; i < len; i += 1) {
    rndArray[i] = { r: Math.random(), element: a[i] };
  }

  rndArray.sort((first, second) => first.r - second.r);

  for (let i = 0, len = a.length; i < len; i += 1) {
    a[i] = rndArray[i].element;
  }
};

export const isFunction = (value) => typeof value === "function";

export const isDefAndNotNull = (value) =>
  typeof value !== "undefined" && value !== null;

// Method to check if an object has a defined and not-null property,
// which can be several layers deep.
// Example: isPropDefAndNotNull(obj, 'prop1', 'prop2', 'prop3')
// would return true for obj === { prop1: { prop2: { prop3: true }}}
// Note : arrow functions do not have their own 'this' or arguments
export const isPropDefAndNotNull = (...args) => {
  if (!isDefAndNotNull(args[0])) {
    return false;
  }
  let prop = args[0];
  for (let i = 1; i < args.length; i += 1) {
    prop = prop[args[i]];
    if (!isDefAndNotNull(prop)) {
      return false;
    }
  }
  return true;
};

// Method to return the value of a property that is several levels deep
// If the property is not defined or null then the passed in default value
// is returned instead.
// Example:
// For the following object const obj = { settings: { one: 1 } };
// getPropWithDefault(obj, 'settings', 'one', 0) would return 1
// getPropWithDefault(obj, 'settings', 'two', 0) would return 0
export const getPropWithDefault = (...args) => {
  const defValue = args.pop(); // The last argument is always the default value
  if (!isPropDefAndNotNull(...args)) {
    return defValue;
  }

  let prop = args[0];
  for (let idx = 1; idx < args.length; idx += 1) {
    prop = prop[args[idx]];
  }

  return prop;
};

export const getProp = (...args) => {
  if (!isPropDefAndNotNull(...args)) {
    return undefined;
  }

  let prop = args[0];
  for (let idx = 1; idx < args.length; idx += 1) {
    prop = prop[args[idx]];
  }

  return prop;
};

export const hasAttemptsRemaining = (isCorrect, maxAttempts, attemptsMade) => {
  if (isCorrect) {
    return false;
  } else if (maxAttempts && attemptsMade >= maxAttempts) {
    return false;
  }

  return true;
};

// Returns a random integer from min (inclusive) to max (inclusive)
export const getRandomInt = (min, max) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

// NOTE : this function does not take into account that some plurals are not formed
// by merely adding an 's' to the original string (e.g. 'try' => 'tries', 'cactus' => 'cacti')
// TODO - This will not fly once we localize. So we'll need to change it.
export const getPluralString = (stringValue, count) =>
  `${stringValue}${count === 1 ? "" : "s"}`;

// Formats the time in 00:00 format from time in seconds.
export const formatTime = (time) => {
  const minutes = Math.floor(time / 60);
  let seconds = Math.floor(time % 60);
  seconds = seconds >= 10 ? seconds : `0${seconds}`;
  return `${minutes}:${seconds}`;
};

// Validate youtube URLs
export const youtubeUrl = (url) => {
  const domainName =
    /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  const matches = url.match(domainName);
  if (matches) {
    return matches[1];
  }

  return false;
};

// Check the events from youtube player
export const youtubeAnalyticsAction = (eve) => {
  let YouTubeAction;
  switch (eve) {
    case 0:
      YouTubeAction = "Finished";
      break;
    case 1:
      YouTubeAction = "Play";
      break;
    case 2:
      YouTubeAction = "Pause";
      break;
    default:
      break;
  }
  if (YouTubeAction) {
    return YouTubeAction;
  }
  return false;
};

export const jsonClone = (value) => JSON.parse(JSON.stringify(value));

export const parseJson = (value) => {
  try {
    return value ? JSON.parse(value) : null;
  } catch (e) {
    return null;
  }
};

/**
 * Enhances document.querySelector to more gracefully handle fringe conditions.
 * In HTML5, IDs can start with integers.  CSS3 ID selectors, however, do not allow the ID to
 * start with an integer (ex. #123abc -> Error).
 * This method (with its helper function) modifies selectors that contain any such ids and
 * replaces them with the alternate equivalent attribute selector [id="{id}"]
 * (e.g. [id="123abc"] -> No error).
 */
export const enhancedQuerySelectorReplacerFn = (match, p1) => `[id="${p1}"]`;

export const enhancedQuerySelector = (target) => {
  target = target.trim().replace(/#(\d\w*)/g, enhancedQuerySelectorReplacerFn);
  return document.querySelector(target);
};

// Returns the highest zIndex in use within the DOM tree defined by the passed selector,
// or within 'body *' by default.
export const getMaxZIndex = (selector) => {
  selector = selector || "body *";
  let highestZIndex = 0;
  highestZIndex = Math.max(
    highestZIndex,
    ...[...Array.from(document.querySelectorAll(selector))]
      .map((a) => parseFloat(getComputedStyle(a).zIndex))
      .filter((a) => !isNaN(a))
  );
  return highestZIndex;
};

export const isInt = (value) =>
  !isNaN(value) &&
  parseInt(Number(value)) === Number(value) && // eslint-disable-line
  !isNaN(parseInt(value, 10));

export const findParentElement = (element, className) => {
  let parentElement = null;
  while (element) {
    if (element.classList.contains(className)) {
      parentElement = element;
      break;
    }
    element = element.parentElement;
  }
  return parentElement;
};

export const sortArrayByKey = (array, key) => {
  let value = -1;
  return array.sort((a, b) => {
    const x = a[key];
    const y = b[key];

    if (x < y) {
      value = -1;
    } else if (x > y) {
      value = 1;
    } else if (x === y) {
      value = 0;
    }

    return value;
  });
};

export const getTimeZone = () => {
  let timeZone = ""; // Default to empty string as safe guard if for some reason the timezone is indeterminable

  const timeZoneLocale =
    "en-US"; /* Always use the en-US locale to form initialism from long timezones.
                                        This is necessary as other locale's do not always return long
                                        timezone's that allow for initialisms to be derived (ex. fr-FR:
                                        'a heure d’été d’Europe centrale') */
  const dateStringWithLongTimeZone = new Date().toLocaleDateString(
    timeZoneLocale,
    { timeZoneName: "long" }
  );

  const timeZoneMatches = dateStringWithLongTimeZone.match(/([A-Z]+.*)$/);
  if (timeZoneMatches.length >= 2) {
    const timeZoneMatch = timeZoneMatches[1];
    const timeZoneWords = timeZoneMatch.split(" ");
    timeZone = (
      timeZoneWords.length > 1
        ? timeZoneWords.map((word) => word.charAt(0))
        : timeZoneWords
    ).join("");
  }

  return timeZone;
};

// Wrap method to make mocking easier for unit tests.
//export const getUuid = () => (
// uuidv4()
//);

export function isString(object) {
  return typeof object === "string";
}

// Note that the content paramater must be an array, but it can be an array
// containing a single string.
export function saveToFile(content, fileName, contentType) {
  const a = document.createElement("a");
  const file = new Blob(content, { type: contentType });
  a.href = URL.createObjectURL(file);
  a.download = fileName;
  a.click();
}

export function blurAll() {
  if ("activeElement" in document) {
    document.activeElement.blur();
  }
}

// This calculation includes padding but not borders or margins.
export function needsVerticalScrollbar(element) {
  return element.scrollHeight > element.clientHeight;
}

// Returns the width of a vertical scrollbar in px.
export function getVerticalScrollbarWidth() {
  const outer = document.createElement("div");
  outer.style.visibility = "hidden";
  outer.style.width = "100px";
  outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps

  document.body.appendChild(outer);

  const widthNoScroll = outer.offsetWidth;
  // force scrollbars
  outer.style.overflow = "scroll";

  // add innerdiv
  const inner = document.createElement("div");
  inner.style.width = "100%";
  outer.appendChild(inner);

  const widthWithScroll = inner.offsetWidth;

  // remove divs
  outer.parentNode.removeChild(outer);

  return widthNoScroll - widthWithScroll;
}

// DO NOT USE THE METHOD BELOW FOR ANY NEW CODE AS IT CAN CAUSE UNEXPECTED
// SIDE EFFECTS THAT ARE HARD TO DEBUG
export function DEPRECATED_dispatchWindowResizeEvent() {
  // The unit testing framework does not always define Event
  if (window.Event) {
    window.dispatchEvent(new window.Event("resize"));
  }
}

// Used for rounding any number to the specified precision or default precision
//export function round(number, precision) {
//    if (!isDefAndNotNull(number)) return null;
//    const exponent = isDefAndNotNull(precision) ? -precision : -DEFAUlT_PRECISION;
//    return round10(number, exponent);
//}

// Used for rounding points, defaults to -- if points are null or undefined
//export function roundPoints(points, precision) {
//    if (!isDefAndNotNull(points)) return UNDEFINED_OR_NULL;
//    return round(points, precision);
//}

// Appends a % when rounding percents, defaults to -- if percent is null or undefined
//export function roundPercent(percent, precision) {
//    if (!isDefAndNotNull(percent)) return UNDEFINED_OR_NULL;
//    return `${round(percent, precision)}%`;
//}

export const emptyFunction = () => {};

export const isEmptyObject = (obj) =>
  obj && obj.constructor === Object && Object.keys(obj).length === 0;

// Dimensions including scrollable areas
export const getWindowScrollDimensions = () => ({
  scrollWidth: document.body.scrollWidth,
  scrollHeight: document.body.scrollHeight,
});

// Dimensions not including scrollable areas
export const getClientDimensions = (elem) => {
  const dimensionsElem = elem || document.body;
  return {
    width: dimensionsElem.clientWidth,
    height: dimensionsElem.clientHeight,
  };
};

export const getScreenDimensions = () => ({
  width: window.screen.width,
  height: window.screen.height,
});

export const loadResource = (type, url, callback, errorCallback) => {
  // adding the script tag to the head as suggested before
  const head = document.getElementsByTagName("head")[0];
  let resource = null;
  if (type === "script") {
    resource = document.createElement("script");
    resource.type = "text/javascript";
    resource.src = url;
  } else {
    resource = document.createElement("link");
    resource.rel = "stylesheet";
    resource.href = url;
  }

  // then bind the event to the callback function
  // there are several events for cross browser compatibility
  resource.onreadystatechange = callback;
  resource.onload = callback;

  if (errorCallback) resource.onerror = errorCallback;

  // fire the loading
  head.appendChild(resource);
};

export const isIE =
  typeof window !== "undefined" && window.navigator
    ? /MSIE |Trident\/|Edge\//.test(window.navigator.userAgent)
    : false;

export const isFirefox =
  typeof window !== "undefined" && window.navigator
    ? /Firefox/.test(window.navigator.userAgent)
    : false;

// Get a random number from 0 to maxValue and return it as a string
export const getRandomIdentifier = (maxValue) =>
  `${Math.floor(Math.random() * (maxValue || 1000000))}`;

// Used to remove the tabIndex property onBlur, and then remove the onBlur
// handler as well.  Used in placed tabIndex is added so that a <div> can be
// focused programmatically, but which can interfere with keyboard navigation
// once they have been used (see ACP1-5781).
export const removeTabIndexOnBlur = (e) => {
  const { currentTarget } = e;
  if (currentTarget) {
    currentTarget.removeAttribute("tabIndex");
    currentTarget.removeEventListener("onBlur", removeTabIndexOnBlur);
  }
};

// Complicated content (such as an EqLabel) may have an aria-label attribute.  If so, use it.
// Otherwise, just get the innerText of the content.
export const getAriaLabelFromContent = (elem) => {
  if (!elem) {
    return null;
  }
  // The query below looks for a descendant that has an aria-label
  // attribute that is not an empty string.
  const ariaLabelElem = elem.querySelector("[aria-label]:not([aria-label=''])");
  let ariaLabel = null;
  if (ariaLabelElem) {
    ariaLabel = ariaLabelElem.getAttribute("aria-label");
  } else {
    ariaLabel = elem.innerText || "";
  }
  return ariaLabel.trim();
};

export const constructQueryString = (args) => {
  const str = [];
  Object.keys(args).forEach((key) => {
    str.push(`${encodeURIComponent(key)}=${encodeURIComponent(args[key])}`);
  });
  return str.join("&");
};

export const getDraftText = (id) => {
  let draftText = "";

  try {
    draftText = localStorage.getItem(id) ? localStorage.getItem(id) : "";
  } catch (err) {
    //log.debug(`Failed to initialize the local storage. ${err}`);
  }

  return draftText;
};

const templateRegex = /{(.*?)}/g;

export const isTemplate = (str) => templateRegex.test(str);

export const interpolateTemplate = (str, values) =>
  str.replace(templateRegex, (match, offset) => values[offset]);

// useCallbackState provides a way for React functional components to include a callback
// as a second argument to function to update state returned by useState (similar to how
// class components can pass a callback to setState().  The code below is based on:
// https://medium.com/geekculture/usecallbackstate-the-hook-that-let-you-run-code-after-a-setstate-operation-finished-25f40db56661
export const useCallbackState = (initialValue) => {
  const [state, _setState] = useState(initialValue);
  const callbackQueue = useRef([]);
  useEffect(() => {
    callbackQueue.current.forEach((cb) => cb(state));
    callbackQueue.current = [];
  }, [state]);
  const setState = (newValue, callback) => {
    _setState(newValue);
    if (typeof callback === "function") {
      callbackQueue.current.push(callback);
    }
  };
  return [state, setState];
};

// Look for the first header element (whether an <h1>, <h2>, etc or an element
// with role="heading" and arial-level="1", etc) and return the level as an
// integer.  This looks for prior siblings of the itemPlayerRef, and if no
// headers are found, then proceeds up the ancestor DOM, and again, looks for
// prior sibling headers at each level.
const headerTagRegex = /^H(\d)$/i; // Look for node names of H1, H2, etc
export const getStartingHeadingLevel = (itemPlayerRef) => {
  let headingLevel = null;
  let node = itemPlayerRef.current;
  while (node && !headingLevel) {
    while (node.previousSibling) {
      node = node.previousSibling;
      if (node.nodeType === 1) {
        // element nodes only
        const headerTagFound = node.nodeName.match(headerTagRegex);
        if (headerTagFound) {
          headingLevel = headerTagFound[1];
          break;
        } else {
          const role = node.getAttribute("role");
          const ariaLevel = node.getAttribute("aria-level");
          if (role === "heading" && ariaLevel) {
            headingLevel = ariaLevel;
            break;
          }
        }
      }
    }
    node = headingLevel ? null : node.parentElement;
  }
  return headingLevel ? parseInt(headingLevel, 10) : 0;
};

export const isNullishOrWhiteSpace = (str) => !str?.trim();

// If dateTimestamp is not passed, returns now as seconds
export const getDateTimestampAsSeconds = (dateTimestamp) => {
  const date = dateTimestamp ? new Date(dateTimestamp) : new Date();
  return Math.floor(date.getTime() / 1000);
};

export const getAlignment = (align) => {
  switch (align) {
    case 0:
      return "left";
    case 1:
      return "center";
    case 2:
      return "right";
    case 3:
      return "justify";
    default:
      return "left";
  }
};

// The url should point to a js file that will be evaled.
export const fetchDataAndEval = (url, checkForSystemJs) =>
  (async () => {
    // Define a closure to encapsulate the eval function
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Failed to fetch JS file");
      }

      const jsCode = await response.text();
      // Bronte is using SystemJs registration for their parcel. So we pass the url to SystemJS.
      if (checkForSystemJs && jsCode.indexOf("System.register([]") > -1) {
        return await window.System.import(url);
      }
      // Define a function within the closure to encapsulate eval
      // We need this eval in order to process the js files.
      const evalInClosure = (code) => {
        const config = (0, eval)(code); //eslint-disable-line
        // If the config is not returned from the eval, then
        // we assume this is the old format and pass the parcelConfig in the global scope.
        return config || window.parcelConfig || window.parcel;
      };
      // Call the encapsulated eval function
      const result = evalInClosure(jsCode);
      return result;
    } catch (error) {
      // log.debug(`Failed to eval the js code. ${error}`);
      return null;
    }
  })();

export const sleep = (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export const setIntervalImmediately = (func, interval) => {
  func();
  return setInterval(func, interval);
};

export const svgComponentToCssUrl = (svgComponent) => {
  const div = document.createElement("div");
  div.style.display = "none";

  return new Promise((resolve) => {
    render(svgComponent, div, () => {
      resolve(`url("data:image/svg+xml,${encodeURIComponent(div.innerHTML)}")`);
    });
  });
};
