import axios from "axios";

let service_domain = process.env.REACT_APP_SERVER_PORT;

const Api = axios.create({
  baseURL: service_domain,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true, // Ensures cookies are sent with requests
});

// Response interceptor to handle token refreshing
Api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        // Attempt to refresh the token
        await axios.post(
          `${service_domain}/refresh-token`,
          {},
          { withCredentials: true }
        );
        return Api(originalRequest); // Retry the original request
      } catch (refreshError) {
        return Promise.reject(refreshError); // If token refresh fails, reject the promise
      }
    }
    return Promise.reject(error); // For other errors, reject the promise
  }
);

export default Api;
