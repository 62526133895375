import React, {useEffect, useState } from 'react';
import { Button, Grid, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';



const useStyles = makeStyles({
  button: {
    borderRadius: '100%',
    minWidth: '20px',
    minHeight: '20px',
  },
  reduceRowSpacing: {
    '& > *': {
      padding: '5px', // Adjust the padding as needed
      paddingBottom: '0', // Remove bottom padding
    },
  },
  rightAlign: {
    textAlign: 'right',
  },
  leftAlign: {
    textAlign: 'left',
  },
  table: {
    border: 'none',
  },
  cell: {
    border: 'none',
  },
});


function Tooth({ initialButtonString, onClickPatternChange }) {
  const [buttonColors, setButtonColors] = useState('');
  const classes = useStyles();
  const ParentToothCount = 8;
  const ChildToothCount=5;
  const [buttonClicked, setButtonClicked] = useState(false);

  
  const initialButtonValues = initialButtonString.split(',').map((item) => {
    const [key, value] = item.split('.');
    return { key: parseInt(key), value };
  });

  const [myDictionary, setMyDictionary] = useState(
    initialButtonValues.reduce((acc, { key, value }) => {
      acc[key] = value;
      return acc;
    }, {})
  );

  const getValueForKey = (key) => {
    return myDictionary[key];
  };

  const updateValueForKey = (key, value) => {
    setMyDictionary((prevDictionary) => ({
      ...prevDictionary,
      [key]: value,
    }));
  };

  useEffect(() => { 
    onClickPatternChange(myDictionary);
  }, [myDictionary]);


  const handleClick = (index) => {
    const clickedValue = getValueForKey(index);
    if(clickedValue==="1")
    {
      updateValueForKey(index,"0");
      setButtonClicked(false);
    } else {
      updateValueForKey(index,"1");
      setButtonClicked(true);
    }
    //console.log(myDictionary);
  };

  useEffect(() => {
    // Update buttonColors state when myDictionary changes
    setButtonColors(
      Object.keys(myDictionary).reduce((colors, key) => {
        colors[key] = myDictionary[key] === "1" ? "secondary" : "primary";
        return colors;
      }, {})
    );
    //console.log(buttonColors);
  }, [myDictionary]);



  return (
    <div style={{width:"558px"}}>
      <Grid container spacing={1}>
      <TableContainer>
        <Table className={classes.table} >
          <TableHead  className={classes.table} >
            <TableRow className={classes.reduceRowSpacing}>
              <TableCell  align="center" className={`${classes.rightAlign} ${classes.cell}`}>
                  {Array(ChildToothCount)
                    .fill()
                    .map((_, index) => (
                      <Button
                        key={55-index}
                        variant="contained"
                        color={buttonColors[55 - index]}
                        onClick={() => handleClick(55-index)}
                        style={{ width: '20px', height: '30px'}}
                        classes={{ root: classes.button }}
                      >
                    &nbsp;
                      </Button>
                  ))}
              </TableCell>
              <TableCell align="center" className={`${classes.leftAlign}  ${classes.cell}`}>
                  {Array(ChildToothCount)
                    .fill()
                    .map((_, index) => (
                      <Button
                        key={61+index}
                        variant="contained"
                        color={buttonColors[61+index]}
                        onClick={() => handleClick(61+index)}
                        style={{ width: '20px', height: '30px' }}
                        classes={{ root: classes.button }}
                      >
                      &nbsp;
                      </Button>
                  ))}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={classes.reduceRowSpacing}>
              <TableCell align="center" className={`${classes.rightAlign} ${classes.cell}`}>
              {Array(ParentToothCount)
              .fill()
              .map((_, index) => (
                <Button
                  key={18-index}
                  variant="contained"
                  color={buttonColors[18-index]}
                  onClick={() => handleClick(18-index)}
                  style={{ width: '20px', height: '30px' }}
                  classes={{ root: classes.button }}
                >
              &nbsp;
                </Button>
              ))}
              </TableCell>
              <TableCell align="center" className={`${classes.leftAlign}  ${classes.cell}`}>
              {Array(ParentToothCount)
              .fill()
              .map((_, index) => (
                <Button
                  key={21+index}
                  variant="contained"
                  color={buttonColors[21+index]}
                  onClick={() => handleClick(21+index)}
                  style={{ width: '20px', height: '30px' }}
                  classes={{ root: classes.button }}
                >
                &nbsp;
                </Button>
              ))}
              </TableCell>
            </TableRow>
            <TableRow className={classes.reduceRowSpacing}>
              <TableCell align="center" className={`${classes.rightAlign} ${classes.cell}`}>
              {Array(ParentToothCount)
              .fill()
              .map((_, index) => (
                <Button
                  key={48-index}
                  variant="contained"
                  color={buttonColors[48-index]}
                  onClick={() => handleClick(48-index)}
                  style={{ width: '20px', height: '30px' }}
                  classes={{ root: classes.button }}
                >
                  &nbsp;
                </Button>
              ))}
              </TableCell>
              <TableCell align="center" className={`${classes.leftAlign}  ${classes.cell}`}>
              {Array(ParentToothCount)
              .fill()
              .map((_, index) => (
                <Button
                  key={31+index}
                  variant="contained"
                  color={buttonColors[31+index]}
                  onClick={() => handleClick(31+index)}
                  style={{ width: '20px', height: '30px' }}
                  classes={{ root: classes.button }}
                >
                &nbsp;
                </Button>
              ))}
              </TableCell>
            </TableRow>
            <TableRow className={classes.reduceRowSpacing}>
              <TableCell align="center" className={`${classes.rightAlign} ${classes.cell}`}>
              {Array(ChildToothCount)
              .fill()
              .map((_, index) => (
                <Button
                  key={85-index}
                  variant="contained"
                  color={buttonColors[85-index]}
                  onClick={() => handleClick(85-index)}
                  style={{ width: '20px', height: '30px' }}
                  classes={{ root: classes.button }}
                >
              &nbsp;
                </Button>
              ))}
              </TableCell>
              <TableCell align="center" className={`${classes.leftAlign}  ${classes.cell}`}>
              {Array(ChildToothCount)
              .fill()
              .map((_, index) => (
                <Button
                  key={71+index}
                  variant="contained"
                  color={buttonColors[71+index]}
                  onClick={() => handleClick(71+index)}
                  style={{ width: '20px', height: '30px' }}
                  classes={{ root: classes.button }}
                >
                &nbsp;
                </Button>
              ))}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
    </div>
  );
}



export default Tooth;
